import constants from 'util/data/constants';
import Upload from 'views/upload/upload';
import toolboxModel from 'models/toolbox-model';

const ImageEmbed = {
    view: ({attrs: {control}}) =>
        <div class="embed-contents">
            <div class="embed-element-title embed-image">Select an image to display in the Properties Form</div>
            <div class="embed-select-wrap">
                <label class="label-style left-aligned-label">Horizontal rule style</label>
                <div class="select-wrapper has-left-aligned-label">
                    <select
                        class="editable-when-published"
                        defaultSelected={control.attributes.mediaId}
                        onchange={e => {
                            control.attributes.mediaId = e.target.value;
                            m.redraw();
                        }}
                        value={control.attributes.mediaId}>
                        {Object.values(constants.predefinedEmbeds).map(predefinedEmbed =>
                            <option value={predefinedEmbed.mediaId}>{predefinedEmbed.name}</option>
                        )}
                    </select>
                </div>
            </div>
            <div class="upload-btn-wrap">
                <div class="label-style">or Upload a Custom Image</div>
                <Upload accept="image/*" maxWidth={700} done={([_mediaId]) => {
                    control.attributes.mediaId = _mediaId;
                    m.redraw();
                }} />
            </div>
            <div class="embed-preview">
                <div class="label-style">Image Preview</div>
                <div class="preview-inner-wrap">
                    <img src={constants.mediaServerUrl + control.attributes.mediaId} />
                </div>
            </div>
        </div>
};

const TextEmbed = {
    view: ({attrs: {control}}) => {
        const typeText = control.attributes.type === 'notes'
            ? 'a Note'
            : 'a Header';
        return <div className="embed-contents">
            <label>Display as {typeText} in the Properties Form</label>
            <textarea
                className="editable-when-published"
                value={control.attributes.text}
                oninput={e => toolboxModel.updateTextEmbed(control, e.target.value)}
            />
        </div>;
    }

};

const EmbedControl = {
    view: ({attrs: {control}}) => {
        const {type} = control.attributes;
        return <div class="embed-control-upload fieldset-style clear">
            <fieldset class="has-left-aligned-label">
                <label class="left-aligned-label">Form Element Type</label>
                <div className="select-wrapper">
                    <select
                        className="editable-when-published"
                        onchange={e => toolboxModel.changeEmbedControl(control, e.target.value)}
                        value={type}>
                        <option value="image">Image</option>
                        <option value="notes">Note</option>
                        <option value="header">Header</option>
                    </select>
                </div>
            </fieldset>
            {control.attributes.type === 'image'
                ? <ImageEmbed control={control}/>
                : <TextEmbed control={control}/>}
        </div>;
    }
};

export default EmbedControl;
