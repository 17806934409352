import modal from 'views/modal/modal';
import projectModel from 'models/project-model';
import ErrorBox from 'views/error-box/error-box';

const RemoveUserFromProjectDialog = {    
    onremove: () => delete projectModel.removeProjectUserError,
    view: ({attrs: {user}}) => {
        const displayName = user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.emailAddress;
    
        return <div class="create-account">
            <ErrorBox error={projectModel.removeProjectUserError}/>
            <h3 class="account-modal-header">
                Remove {displayName} from {projectModel.project.name}? The user will not be deleted.
            </h3>
            <div class="remove-btn-wrap">
                <button class={`btn btn-red delete-btn ${projectModel.loading ? 'delete-processing spinner spinning' : ''}`} 
                    onclick={() => projectModel.removeProjectUser(user.userId)}>
                    <span class="btn-text">Remove</span>
                </button>
                <button class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </button>
            </div>
        </div>;
    }
};

export default RemoveUserFromProjectDialog;
