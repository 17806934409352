import modal from 'views/modal/modal';
import projectModel from 'models/project-model';
import ErrorBox from 'views/error-box/error-box';

const DeleteProjectDialog = {
    onremove: () => delete projectModel.deleteProjectError,
    view: ({attrs: {project}}) => {
        return <div class="create-account">
            <ErrorBox error={projectModel.deleteProjectError}/>
            <h3 class="account-modal-header">Are you sure you want to delete this project: {project.name}? </h3>
            <p class="modal-paragraph">Once deleted you will no longer have access.</p>
            <div class="remove-btn-wrap">
                <button class={`btn btn-red delete-btn ${projectModel.loading ? 'delete-processing spinner spinning' : null}`} 
                    onclick={() => projectModel.deleteProject(project.projectId)}><i class="spinner"/> <span class="btn-text">Delete</span>
                </button>
                <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </div>
            </div>
        </div>;
    }
};

export default DeleteProjectDialog;
