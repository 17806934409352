import uploadModel from 'models/upload-model';
import modal from 'views/modal';

const MediaID = {
    oninit({state, attrs: {mediaId}}) {
        state.mediaId = mediaId;
    },
    view: ({state, attrs: {done}}) => <div class="media-id-modal">
        <div><label>Media ID:</label></div>
        <p />
        <input type="text" placeholder="20 Characters" value={state.mediaId || ''} oninput={e => {
            state.mediaId = e.target.value;
            if (state.mediaId.length >= 20) {
                done([state.mediaId]);
                modal.close();
            }
        }} />
    </div>
};

const Upload = {
    view: ({attrs: {accept, multiple, capture, mediaId, done, maxWidth}}) => <div class="file-upload-wrap">
        <div class="upload-btn">
            <span class="add-icon-cta" onclick={() =>
                uploadModel.pickFiles({accept, multiple, capture})
                    .then(files => uploadModel.upload(files, accept === 'image/*' && (maxWidth || 256)))
                    .then(media => media && media.length && done(media.map(m => m.mediaId)))
            }>Upload PNG</span><span class="add-icon-cta-or">OR</span>
            <span class="add-icon-cta" onclick={() => modal(MediaID, {mediaId, done})}>Paste mediaID</span>
        </div>
    </div>
};

export default Upload;
