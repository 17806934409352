import accountModel from 'models/account-model';
import modal from 'views/modal/modal';
import ErrorBox from 'views/error-box/error-box';
import constants from 'util/data/constants';

const UpdateUserRoleDialog = {
    onremove: () => delete accountModel.modifyAccountError,

    oninit: ({state}) => {
        state.userList = accountModel.account.users;
        state.processing = {};
        state.valuesToUpdate = {};
        accountModel.account.users.forEach((user) => {
            state.valuesToUpdate[user.userId] = user.role;
        });
    },

    userActionButton: (user, state) => {
        if (state.processing[user.userId]) {
            return <div class="update-role-modal-button btn disabled"><div class="update-role-modal-button-spinner spinning"/></div>;
        } 
        return <div class="btn" onclick={() => accountModel.updateAccountUser(user.userId, state.valuesToUpdate[user.userId], state.processing)}>Update</div>;
    },

    view: ({state}) => {
        const options = Object.values(constants.accountUserRoles).filter(role => role.key !== 'superadmin');
        return <div class="add-user-to-account">
            <ErrorBox error={accountModel.modifyAccountError}/>
            <label>Change User Role</label>
            <div class ="role-change-search">
                <label>Search: </label>
                <input type="text" oninput={(e) => {
                    const query = e.target.value;
                    state.userList = accountModel.accountUserSearch(query);
                }}/>
            </div>
            <table class="add-user-table">
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Email</td>
                    <td>Role</td>
                    <td/>
                </thead>
                <tbody>
                    {state.userList.length === 0 ? 
                        <div class="empty-row">
                            <div class="user-empty-state">
                                    No Results found
                            </div>
                        </div> 
                        :
                        state.userList.map(user => 
                            <tr>
                                <td>{user.givenName} {user.familyName}</td>
                                <td>{user.userId}</td>
                                <td>{user.emailAddress}</td>
                                <td>
                                    <div class="account-user-select select-wrapper">
                                        <select 
                                            value={state.valuesToUpdate[user.userId]}
                                            onchange={(e) => {
                                                state.valuesToUpdate[user.userId] = e.target.value;
                                            }}>
                                            {options.map((role) =>
                                                <option value={role.key}>{role.display || ''}</option>)}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    {UpdateUserRoleDialog.userActionButton(user, state)}
                                </td>
                            </tr>
                        )}
                    <div class="empty-row"></div>
                </tbody>
            </table>
            <div class="add-user-btns">
                <div class="btn" onclick={() => modal.close()}>
                        Done
                </div>
            </div>
        </div>;
    }
};

export default UpdateUserRoleDialog;
