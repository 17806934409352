import usersModel from 'models/users-model';
import MreactComponent from 'util/dom/mreact-component';
import UserTableHeaderElement from 'views/react/UserTableHeader';

const Users = {
    oninit: () => {
        usersModel.fetch();
    },

    view: () => 
        <div class="users">
            <div class="page-title">
                <input type="text" oninput={usersModel.search} placeholder="search" />
            </div>
            <table>
                <thead>
                    <MreactComponent element={UserTableHeaderElement({})} tagName="tr"/> 
                </thead>
                <tbody>
                    {usersModel.users.map(user => {
                        return <tr key={user.userId} style={user.hidden ? 'display:none;' : ''}>
                            <td><strong><a href={`#view=user&userId=${user.userId}`}>
                                {user.givenName ? `${user.givenName} ${user.familyName}` : '-'}
                            </a></strong></td>
                            <td>{user.userId}</td>
                            <td>{user.emailAddress || '-'}</td>
                            <td>{user.phoneNumber || '-'}</td>
                            <td><div class={`status-pill ${user.status}-pill`} >{user.status}</div></td>
                            <td><div>{user.company || '-'}</div></td>
                            <td>{new Date(user.createdDateTime).toLocaleDateString()}</td>
                            <td>{user.lastConnectedDateTime ? new Date(user.lastConnectedDateTime).toLocaleDateString() : '-'}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
};

export default Users;
