import userModel from 'models/user-model';
import {getAppVersion} from 'util/data/get-app-version';
import removeProjectFromUserDialog from 'views/user/remove-project-from-user-dialog';
import modal from 'views/modal/modal';
import UserSettingsDialog from 'views/user/user-settings-dialog';

const UserProjects = {
    oninit: () => {
        userModel.fetchUser()
            .then(() => userModel.fetchAccounts())
            .then(() => userModel.fetchProjects());
    },
    view: () => {
        const projects = userModel.projects;
        return <table>
            <thead>
                <td>Project Name</td>
                <td>Project ID</td>
                <td>Version</td>
                <td>Account Name</td>
                <td>User Settings</td>
            </thead>
            <tbody>
                {userModel.loading && projects.length === 0 ? 
                    <div class ="empty-row">
                        <div class="spinner spinning teal user-project"></div> 
                    </div>
                    :
                    projects.map(project => {
                        return <tr key={project.projectId || project.createdDateTime}>
                            <td><a href={`#view=project&projectId=${project.projectId}`}>{project.name || 'N/A'}</a></td>
                            <td>{project.projectId}</td>
                            <td>{project && project.accountAttributes ? getAppVersion(project.accountAttributes) : '-'}
                            </td>
                            <td><a href={`#view=account&accountId=${project.accountId}`}>{project.accountName}</a></td>
                            <td><span onclick={() => modal({
                                view: () => <UserSettingsDialog project={project}/>})}>{userModel.userProjectSettings[project.projectId] ? <>{userModel.userProjectSettings[project.projectId].projectViews.length} <span class="user-settings-key">project view(s)</span> {userModel.userProjectSettings[project.projectId].toolStyles.length} <span class="user-settings-key">tool style(s)</span></> : '...'}</span></td>
                            <td> {
                                projects.length !== 1 ?
                                    !userModel.user.isSuperAdmin ?
                                        <button class="remove-user-from-account-button" onclick={() => modal(removeProjectFromUserDialog, {project})}><i class="icon-trash"></i></button>
                                        :
                                        null
                                    :
                                    null
                            }
                            </td>
                        </tr>;
                    })}
            </tbody>
        </table>;
    }
};

export default UserProjects;
