import React, { useState } from 'react';
import usersModel from 'models/users-model';
import { COLUMN_ORDER_ICON_ENUM } from 'util/data/constants';

const UserTableHeader = () => {
    const [dateOrder, setDateOrder] = useState(1);
    const [dateOrderIcon, setDateOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[1]);
    const [userNameOrder, setUserNameOrder] = useState(0);
    const [userNameOrderIcon, setUserNameOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[0]);

    const handleUserNameOrderClick = () => {
        if (userNameOrder === 0) {
            setUserNameOrder(1);
            setUserNameOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            usersModel.sortUsers('name', 0); 
        } else if (userNameOrder === 1) {
            setUserNameOrder(2);
            setUserNameOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            usersModel.sortUsers('name', 1);
        } else if (userNameOrder === 2) {
            setUserNameOrder(0);
            setUserNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            usersModel.sortUsers('name', 2);
        }
        setDateOrder(0);
        setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);     
    };
    
    const handleDateOrderClick = () => {
        if (dateOrder === 1) {
            setDateOrder(2);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            usersModel.sortUsers('createdDateTime', 1);
        } else if (dateOrder === 2) {
            setDateOrder(0);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            usersModel.sortUsers('createdDateTime', 2);
        } else if (dateOrder === 0) {
            setDateOrder(1);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            usersModel.sortUsers('createdDateTime', 0);
        }
    };

    return (
        <> 
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Name
                    </div>
                    <button className="order-button" onClick={handleUserNameOrderClick}>
                        {userNameOrderIcon}
                    </button>
                </div> 
            </td>
            <td>User ID</td>
            <td>Email</td>
            <td>Phone</td>
            <td>Status</td>
            <td>Company</td>
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Created
                    </div>

                    <button className="order-button" onClick={handleDateOrderClick}>
                        {dateOrderIcon}
                    </button>
                </div>
            </td>
            <td>Last Activity</td>    
        </>
    );
};
 
const UserTableHeaderElement = (props) => React.createElement(UserTableHeader, props, null);

export default UserTableHeaderElement;
