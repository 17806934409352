import collapsibleModel from 'models/collapsible-model';

const ExpandAllCollapseAll = {

    view: () => {
        return  <div class={`expand-shortcuts${collapsibleModel.isAllCollapsed ? ' all-collapsed' : ''}${collapsibleModel.isAllExpanded ? ' all-expanded' : ''}`}>
            <span onclick={() => collapsibleModel.expandAll()} class="expand-all">Expand All</span>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span onclick={() => collapsibleModel.collapseAll()} class="collapse-all">Collapse All</span>
        </div>;
    }

};

export default ExpandAllCollapseAll;
