
const Collapsible = {
    oninit: ({state}) => {
        state.expanded = false;
    },

    view: ({attrs: {xid, display, view, Footer, cssClass, remove}, state}) =>
        <div id={xid} class={`category-wrap collapsible ${state.expanded ? '' : 'collapsed'} ${cssClass || ''} clear`}>
            <div class="collapsible-name">
                <label>Category</label>
                <i className="icon-sortable left"/>
                <div>
                    {display}
                    <i class="icon-up collapsible-icon right" onclick={() => {
                        state.expanded = !state.expanded;
                    }}/>
                    {remove && <i class="icon-trash right" onclick={remove} />}
                </div>
            </div>
            <div class="collapsible-items category-content">
                <div>{view}</div>
                {Footer || null}
            </div>
        </div>
};

export default Collapsible;
