import onBodyClick from 'util/events/on-body-click';

const AddButton = {
    view: ({attrs: {label, options = [], onclick}, state}) => 
        <div class={state.expanded ? 'expanded' : ''}>
            <div class="add-button" onclick={() => {
                if (onclick) {
                    onclick();
                } else {
                    state.expanded = !state.expanded;
                    if (state.expanded) {
                        onBodyClick.once(() => {
                            state.expanded = false;
                            m.redraw();
                        });
                    }
                }
            }}><i class="icon-plus" /> {label}</div>
            <div class="add-button-opts">
                {options}
            </div>
        </div>
};

export default AddButton;
