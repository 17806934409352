
const QuickDraftsDisclaimer = {
    view: ({attrs: {titleText}}) => {
        return <div class="quick-drafts-disclaimer">
            {titleText ? <h1>{titleText}</h1> : ''}
            <h3>What is a "Quick Draft" (and what is it not)?</h3>
            <ul>
                <li>A quick draft is copy of an in-progress toolbox stored to your current device and browser for the purpose of resuming work on it later.</li>
                <li>It is *not* accessible on your other devices or even from this device within a different browser.</li>
                <li style="color: red">It is *not* backed up to a database or retrievable if deleted/lost.</li>
            </ul>
            <p>So, if you need to ensure you have a secure copy of your work, we recommend you "export" the toolbox instead.</p>
            <p>Quick drafts *will* be lost if your browser history is deleted or the browser storage is otherwise invalidated.</p>
        </div>;
    }
};

export default QuickDraftsDisclaimer;
