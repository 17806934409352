import router from 'uav-router';

const Tabs = {
    oninit({attrs: {tabs}, state}) {
        if (router.params.tab) {
            tabs.forEach(tab => {
                tab.selected = tab.name === router.params.tab;
            });
        }
        state.selectedTab = tabs.find(tab => tab.selected) || tabs[0];
    },
    view: ({attrs: {tabs}, state}) => {
        const View = state.selectedTab.view;
        return <div>
            <div class="tabs">
                {tabs.map(tab =>
                    <div key={tab.name} class={`tab ${tab.name === state.selectedTab.name ? 'active' : ''}`} onclick={() => {
                        UE.clearRequests();
                        state.selectedTab = tab;
                        router.url.merge({tab: tab.name});
                    }}>{tab.name}</div>
                )}
            </div>
            <View />
        </div>;
    }
};

export default Tabs;
