import toolboxModel from 'models/toolbox-model';

const SharedControlTag = {
    view: ({attrs: {tool, field, control}}) => {
        return <fieldset class="editable-when-published">
            <input type="checkbox" class="left editable-when-published"
                checked={control.attributes.sharedColumn} onchange={e => {
                    if (e.target.checked) {
                        toolboxModel.addSharedColumnTag(control, field, tool.toolId);
                        control.attributes.sharedColumn = control.name;
                    } else {
                        toolboxModel.removeFromSharedColumns(control, tool.toolId);
                        delete control.attributes.sharedColumn;
                    }
                }} />
            <label class="editable-when-published">Include in combined column</label>
            <span class="shared-column-tip">Adds a Common column in Table View which displays the control value for this Tool/Type as well as for any other Tools/Types with a form control of the same control type, with the same label, and the same options (if applicable to the control type)</span>
            {toolboxModel.invalidSharedColumns[control.name]
                && <div class="shared-column-tip error">Unable to combine this with the other controls of the same name. The existing combined controls of the same name have conflicting properties.</div>}
        </fieldset>;
    }
};

export default SharedControlTag;
