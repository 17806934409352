import toolboxModel from 'models/toolbox-model';
import accountModel from 'models/account-model';
import sortableModel from 'models/sortable-model';
import AnchorMenu from 'views/toolbox/anchor-menu';
import Icon from 'views/toolbox/icon';
import ToolGroup from 'views/toolbox/toolgroup';
import ExpandAllCollapseAll from 'views/toolbox/expand-all-collapse-all';
import draftsModel from 'models/drafts-model';


const Toolbox = {
    oninit: () => {
        draftsModel.startListenForKeyDown();
        toolboxModel.fetch();
    },

    onremove: () => {
        draftsModel.stopListenForKeyDown();
        toolboxModel.reset();
    },

    view: () => {
        const toolbox = toolboxModel.toolbox;
        toolbox.attributes.icon = toolbox.attributes.icon || {};
        const customExports = toolbox.attributes.customExports = toolbox.attributes.customExports || [{name: '', id: ''}];
        return <div class={`toolbox-container ${toolboxModel.projectToolCount ? 'meta-toolbox' : ''}${sortableModel.isReordering ? ' in-reordering-mode' : ''} ${sortableModel.isReorderingToolId ? 'in-reordering-properties-mode' : ''}`}>
            <AnchorMenu/>
            <div class="toolbox-layout-wrap">
                <form class="toolbox">
                    <div class="page-title">{toolbox.name ? `${toolbox.name}` : 'Enter a Toolbox Name to begin ...'}</div>
                
                    {toolboxModel.loading ? <div class="spinning gray" /> :
                        <div>
                            <div class="toolbox-info clear">
                                <div class="card-section clear">
                                    <div class="toolbox-type">Toolbox Type: {toolboxModel.projectToolCount ? 'META (Tool = Project Type)' : 'NORMAL (Tool = Data Type)'}</div>
                                    <div class="publish-status">
                                Status: {toolbox.createdDateTime && !toolboxModel.isUnlocked ? 'PUBLISHED' : 'NOT PUBLISHED'}
                                        {!!toolbox.createdDateTime && !toolboxModel.isUnlocked && <a class="unlock-notice" onclick={() => toolboxModel.unlock()}>Unlock ⚠️</a>}
                                    </div>
                                </div>
                                <fieldset class="toolbox-settings clear">
                                    <div class="toolbox-settings-basics">
                                        <div class="card-section clear">
                                            <label>Toolbox Name</label>
                                            <input type="text" value={toolbox.name} class="editable-when-published toolbox-name" oninput={e => {
                                                toolbox.name = e.target.value;
                                                document.title = `${toolbox.name || 'Unnamed'} | id: ${toolbox.toolboxId} | Unearth Toolbox`;
                                            }} />
                                            <div class="tip-text">If this Toolbox is attached to a Project Type Tool, match the Names.</div>
                                        </div>
                                        <div class="card-section clear">
                                            <label>Project Type Icon</label>
                                            <Icon icon={toolbox.attributes.icon} />
                                            <div class="tip-text">If this Toolbox is attached to a Project Type Tool, match the Icons.</div>
                                        </div>
                                    </div>
                                    <div class="toolbox-settings-extras">
                                        <div class="toolbox-config card-section clear">
                                            <div class="clear">
                                                <div class="left half">
                                                    <label>Singular Term for "Site"</label>
                                                    <input type="text" value={toolbox.attributes.projectSingular} placeholder="site" oninput={e => toolboxModel.editProjectTerm(e.target.value, 'Singular')} />
                                                </div>
                                                <div class="left half">
                                                    <label>Plural Term for "Sites"</label>
                                                    <input type="text" value={toolbox.attributes.projectPlural} placeholder="sites" oninput={e => toolboxModel.editProjectTerm(e.target.value, 'Plural')} />
                                                </div>
                                            </div>
                                            <div class="tip-text">Match these terms in all Meta and Normal Toolboxes.</div>
                                        </div>
                                        <div class="toolbox-config card-section clear">
                                            <label>Custom Reports</label>
                                            <div class="multitext">
                                                {customExports.map((exp, i) => <>
                                                    <div class="text-control left half">
                                                        <input type="text" value={exp.name} placeholder="Report Name" class="editable-when-published" oninput={e => {
                                                            exp.name = e.target.value;
                                                        }}/>
                                                    </div>
                                                    <div class="text-control left half">
                                                        <input type="text" value={exp.id} placeholder="Report ID" class="editable-when-published" oninput={e => {
                                                            exp.id = e.target.value;
                                                        }}/>
                                                        <i class="icon-close" onclick={() => {
                                                            customExports.splice(i, 1);
                                                        }} />
                                                    </div>
                                                </>)}
                                                <div class="cta-link editable-when-published" onclick={() => customExports.push({name: '', id: ''})}>
                                                    <i class="icon-plus" /> Add a Custom Report
                                                </div>
                                            </div>
                                        </div>
                                        <div class="toolbox-features card-section clear">
                                            <label>Advanced Feature Options</label>
                                            {toolboxModel.toolboxFeatures.map(feature =>
                                                <>
                                                    <div class="advanced-option clear">
                                                        <input type="checkbox" class="editable-when-published"
                                                            checked={toolbox.attributes.featureFlags && toolbox.attributes.featureFlags[feature]}
                                                            onclick={() => toolboxModel.toggleFeatureFlag(feature)}
                                                            disabled={!!toolboxModel.featureFlagDisabledMessage(feature)} />
                                                        <label class="editable-when-published">{feature}</label>
                                                        {toolboxModel.featureFlagDisabledMessage(feature) ? <span class="footnote">{toolboxModel.featureFlagDisabledMessage(feature)}</span> : ''}
                                                    </div>

                                                    {toolbox.attributes.featureFlags && feature === 'Progress Dashboard' && toolbox.attributes.featureFlags['Progress Dashboard'] === true &&
                                        <div class="advanced-option clear"><input type="text" value={toolbox.attributes.progressDashID} placeholder="ID" className="editable-when-published" oninput={e => {
                                            toolbox.attributes.progressDashID = e.target.value;
                                        }}/></div>}
                                                </>)}
                                        </div>
                                    </div>    
                                </fieldset>
                            </div>

                            <div class="toolbox-structure">
                                <div class="toolbox-structure-header">
                                    <div class="toolbox-structure-title">Toolbox Data Types</div>
                                    {toolbox.group.groups.length ? <ExpandAllCollapseAll/> : ''}
                                </div>
                                {toolbox.group.groups.map((toolGroup, i) => <ToolGroup index={i} toolGroup={toolGroup}/> )}
                            </div>
                            <div class="add-toolgroup">
                                <div class="add-new-toolgroup-title"><label>Add New Tool Group</label></div>
                                <div class="add-button-opt add-button" onclick={() => toolboxModel.createToolGroup()}><i class="icon-plus"/> Create New</div>
                                <div class="add-button-opt add-button" onclick={() => toolboxModel.clone('toolgroup', toolbox.group.groups)}><i class="icon-duplicate"/>Copy Existing</div>
                            </div>
                            <div class="toolbox-footer">
                                <div class={`saved-draft-message ${draftsModel.showSavedSuccessMessage ? 'show' : 'hide'}`}><span>Quick draft {draftsModel.showSavedSuccessMessage}! 👍 </span></div>
                                <div class="btn done-btn save-locally left" onclick={() => draftsModel.saveDraft(toolbox.toolboxId, toolbox)}>Store Quick Draft</div>                            
                                <div class="btn done-btn secondary left" onclick={() => toolboxModel.preview()}>Preview</div>
                                <div class="btn done-btn secondary left" onclick={() => accountModel.cloneToolbox(toolbox)}>Clone</div>
                                <div class="btn done-btn secondary left" onclick={() => toolboxModel.export()}>Export</div>
                                <div class={`btn done-btn left ${toolboxModel.saving ? 'is-saving' : ''}`} onclick={() => {
                                    toolboxModel.publish();
                                }}>Publish<i class={toolboxModel.saving ? 'spinner spinning' : ''}/></div>
                                <div class="scroll-to-top right" onclick={() => window.scrollTo({top: 0, behavior: 'smooth', block: 'start'})}><i class="icon-arrow-up"/></div>
                            </div>
                        </div>}
                </form>
            </div>
        </div>;
    }
};

export default Toolbox;

