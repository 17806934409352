import projectModel from 'models/project-model';
import removeProjectUserDialog from 'views/project/remove-user-from-project-dialog';
import projectRoleInfoPopup from 'views/project/project-role-info-popup';
import modal from 'views/modal/modal';
import constants from 'util/data/constants';

const ProjectUsers = {
    view: () => <table>
        <thead>
            <td>Name</td>
            <td>User ID</td>
            <td>Email</td>
            <td>Phone</td>
            <td><span class="info-link" onclick={() => modal(projectRoleInfoPopup)}>Project Role (Deprecated*)</span></td>
            <td></td>
        </thead>
        <tbody>
            {projectModel.loading ?
                <div class ="empty-row">
                    <div class="no-users">
                        <div class="spinner spinning teal account-users"></div>
                    </div> 
                </div>
                :
                projectModel.project.users.map(user => {
                    const name = user.givenName ? `${user.givenName} ${user.familyName}` : '-';
                    return <tr key={user.userId || user.createdDateTime}>
                        <td>{user.userId
                            ? <a href={`#view=user&userId=${user.userId}`}>{name}</a>
                            : name}
                        </td>
                        <td>{user.userId || '-'}</td>
                        <td>{user.emailAddress || '-'}</td>
                        <td>{user.phoneNumber || '-'}</td>
                        <td>{constants.accountUserRoles[user.role] ? constants.accountUserRoles[user.role].display : '-'}</td>
                        <td>{constants.accountUserRoles[user.role] && constants.accountUserRoles[user.role].key !== 'superadmin' ? <button class="remove-user-from-account-button" onclick={() => modal(removeProjectUserDialog, {user})}><i class="icon-trash"></i></button>
                            : ''}
                        </td>
                    </tr>;
                })}
        </tbody>
    </table>
};

export default ProjectUsers;
