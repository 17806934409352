import sortableModel from 'models/sortable-model';

const Arrows = {

    view: ({attrs: {index, collection}}) => <span class="sorting-arrows">
        {index > 0 ? <i onclick={() => sortableModel.moveItem(collection, index, index - 1)} class="icon-arrow-up"/> : ''}
        {index < collection.length - 1 ? <i onclick={() => sortableModel.moveItem(collection, index, index + 1)} class="icon-arrow-down"/> : ''}
    </span>

};

const SortableOption = {
    view: ({attrs: {index, collection, display, remove}}) =>
        <div class="option">
            <div class="option-name">
                <div>
                    {display}
                    <Arrows index={index} collection={collection}/>
                    {remove && <i class="icon-trash right editable-when-published" onclick={remove} />}
                </div>
            </div>
        </div>
    
};

export default SortableOption;
