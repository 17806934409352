import toolboxModel from 'models/toolbox-model';

const URLControl = {
    view: ({attrs: {control, field}}) => {
        const defaultValues = control.attributes.default;
        let display;
        let URL;
        if (defaultValues) {
            display = defaultValues.display;
            URL = defaultValues.URL;
        } else {
            display = URL = '';
        }
        return <fieldset class="url-control">
            <label>URL Display Name</label>
            <input
                type="text"
                class="editable-when-published"
                value={display}
                oninput={e => {
                    toolboxModel.setDefault(control, field, {URL, display: e.target.value});
                }}/>
            <label>URL</label>
            <input
                type="text"
                className="editable-when-published"
                value={URL}
                oninput={e => {
                    toolboxModel.setDefault(control, field, {display, URL: e.target.value});
                }}/>
        </fieldset>;
    }
};

export default URLControl;
