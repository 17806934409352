import toolboxModel from 'models/toolbox-model';
import StyleProperty from 'views/toolbox/style-configs/style-property';

const LabelConfig = {
    view: ({attrs: {tool}}) => {
        const hasLabel = tool.featureStyles.find(featureStyle =>
            featureStyle.style.layout && featureStyle.style.layout.hasOwnProperty('text-field')
        );
        return <div>
            <fieldset class="text-label-checkbox">
                <input type="checkbox"
                    class="left"
                    checked={hasLabel}
                    onchange={() => toolboxModel.toggleLabel(tool)}/>
                <label>Include a text label</label>
            </fieldset>
            <div class={`label-styles ${hasLabel ? '' : 'hidden'}`}>
                <StyleProperty tool={tool} styleKey="text-field" />
                <StyleProperty tool={tool} styleKey="text-size" />
                <StyleProperty tool={tool} styleKey="text-color" />
                <StyleProperty tool={tool} styleKey="text-halo-width" />
                <StyleProperty tool={tool} styleKey="text-halo-color" />
                <StyleProperty tool={tool} styleKey="text-offset" />
            </div>
        </div>;
    }
};

const FillConfig = {
    view: ({attrs: {tool}}) => <div>
        <StyleProperty tool={tool} styleKey="fill-opacity" />
        <StyleProperty tool={tool} styleKey="fill-color" />
        <LabelConfig tool={tool} />
    </div>
};

const BoundaryConfig = {
    view: ({attrs: {tool}}) => <div>
        <StyleProperty tool={tool} styleKey="line-opacity" />
        <StyleProperty tool={tool} styleKey="line-color" />
        <StyleProperty tool={tool} styleKey="line-width" />
        <StyleProperty tool={tool} styleKey="line-dasharray" />
        <LabelConfig tool={tool} />
    </div>
};

const PolygonConfig = {
    view: ({attrs: {tool}}) => tool.featureStyles.find(s => s.style.type === 'line')
        ? <BoundaryConfig tool={tool} />
        : <FillConfig tool={tool} />
};

const PointConfig = {
    view: ({attrs: {tool}}) => <div>
        <StyleProperty tool={tool} styleKey="icon-image" />
        <StyleProperty tool={tool} styleKey="icon-size" />
        <StyleProperty tool={tool} styleKey="icon-rotate" />
        <LabelConfig tool={tool} />
    </div>
};

const LineConfig = {
    view: ({attrs: {tool}}) => <div>
        <StyleProperty tool={tool} styleKey="line-opacity" />
        <StyleProperty tool={tool} styleKey="line-color" />
        <StyleProperty tool={tool} styleKey="line-width" />
        <StyleProperty tool={tool} styleKey="line-dasharray" />
        <LabelConfig tool={tool} />
    </div>
};

const TextConfig = {
    view: ({attrs: {tool}}) => <div>
        <StyleProperty tool={tool} styleKey="text-size" />
        <StyleProperty tool={tool} styleKey="text-color" />
        <StyleProperty tool={tool} styleKey="text-halo-width" />
        <StyleProperty tool={tool} styleKey="text-halo-color" />
        <StyleProperty tool={tool} styleKey="text-rotate" />
        <StyleProperty tool={tool} styleKey="text-field" />
    </div>
};

const styleConfigs = {
    polygon: PolygonConfig,
    boundary: BoundaryConfig,
    fill: FillConfig,
    filepicker: PointConfig,
    symbol: PointConfig,
    polyline: LineConfig,
    text: TextConfig,
    project: PolygonConfig,
    imodel: PointConfig
};

export default styleConfigs;
