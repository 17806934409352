import modal from 'views/modal/modal';
import accountModel from 'models/account-model';
import ErrorBox from 'views/error-box/error-box';

const RemoveUserFromAccountDialog = {    
    onremove: () => delete accountModel.removeUserError,
    view: ({attrs: {user}}) => {
        const displayName = user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.emailAddress;

        return <div class="create-account">
            <ErrorBox error={accountModel.removeUserError}/>
            <h3 class="account-modal-header">
                Remove {displayName} from {accountModel.account.name}? The user {displayName} will not be deleted permanently.
            </h3>
            <div class="remove-btn-wrap">
                <button class={`btn btn-red delete-btn ${accountModel.loading ? 'delete-processing spinner spinning' : ''}`} onclick={() => accountModel.removeUserFromAccount(user.userId)}>
                    <span class="btn-text">Remove</span>
                </button>
                <button class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </button>
            </div>
        </div>;
    }
};

export default RemoveUserFromAccountDialog;
