import userModel from 'models/user-model';

const UserSettingsDialog = {

    view: ({attrs: {project}}) => {
        const projectViews = userModel.userProjectSettings[project.projectId].projectViews;
        const toolStyles = userModel.userProjectSettings[project.projectId].toolStyles;

        return <div class="user-settings-dialog">
            <div class="dialog-title">User settings</div>
            <div class="dialog-subtitle">Project: {project.name} | projectId: {project.projectId}</div>
            <hr></hr>
            {projectViews.length ? <div class="project-views-section">
                <h3>Project Views</h3>
                {projectViews.map(projectView => 
                    <div class="project-view" style={!projectView.isVisible ? 'color: #888; margin-bottom: 10px;' : 'margin-bottom: 10px;'}>Name: {projectView.name} {projectView.type === 'last-viewed' ? '(last viewed settings)' : ''} Id: {projectView.projectViewId} {!projectView.isVisible ? '(deleted)' : ''}<div></div></div>
                )}
            </div> : ''}
            <hr></hr>
            {toolStyles.length ? <div class="project-views-section">
                <h3>Tool Styles</h3>
                {toolStyles.map(toolStyle =>
                    <div class="tool-style" style={!toolStyle.isVisible ? 'color: #888; margin-bottom: 10px;' : 'margin-bottom: 10px;'}>Name: {toolStyle.name} Id: {toolStyle.toolStyleId} ToolId: {toolStyle.attributes.toolId} {!toolStyle.isVisible ? '(deleted)' : ''}
                        <div>{Object.keys(toolStyle.attributes.properties).map(key => <div class="property"><span style="color: #888">{key}: </span>{toolStyle.attributes.properties[key]}</div>)}</div>
                    </div>
                )}
            </div> : ''}
        </div>;
    }
}; 

export default UserSettingsDialog;
