import modal from 'views/modal/modal';
import userModel from 'models/user-model';
import ErrorBox from 'views/error-box/error-box';

const RemoveUserFromAccountDialog = {    
    onremove: () => delete userModel.removeUserError,
    view: ({attrs: {account}}) => {
        const displayName = userModel.user.givenName && userModel.user.familyName ? `${userModel.user.givenName} ${userModel.user.familyName}` : userModel.user.emailAddress;

        return <div class="create-account">
            <ErrorBox error={userModel.removeUserError}/>
            <h3 class="account-modal-header">
                Remove {account.name} from {displayName}'s account list? Neither {account.name} or {displayName} will be deleted permanently.
            </h3>
            <div class="remove-btn-wrap">
                <button class={`btn btn-red delete-btn ${userModel.loadingRemove ? 'delete-processing spinner spinning' : ''}`} onclick={() => userModel.removeAccountFromUser(account.accountId)}>
                    <span class="btn-text">Remove</span>
                </button>
                <button class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </button>
            </div>
        </div>;
    }
};

export default RemoveUserFromAccountDialog;
