import projectModel from 'models/project-model';
import constants from 'util/data/constants';
import Tabs from 'views/tabs';
import ProjectUsers from 'views/project/project-users';
import ProjectToolboxes from 'views/project/project-toolboxes';
import UpdateProjectNameDialog from 'views/project/update-project-name-dialog';
import modal from 'views/modal/modal';
import addNewUserToProjectDialog from 'views/project/add-new-user-to-project-dialog';
import AddExistingUserDialog from  'views/project/add-existing-user-to-project-dialog';
import accountModel from 'models/account-model';
import deleteProjectDialog from 'views/project/delete-project-dialog';

const Project = {
    
    oninit: () => {
        projectModel.fetch().then(() => {
            accountModel.fetch(projectModel.project.accountId);
        });
    },
    
    view: () => {
        const project = projectModel.project;
        project.accountAttributes = project.accountAttributes || {};
        return <div class="project">
            <div class="page-title">
            Project Details
            </div>
            <div class="header-buttons">
                {project.sites
                    ? <div class="delete-btn btn btn-small btn-red" onclick={() => modal(deleteProjectDialog, {project})}>Delete Project</div> 
                    : null}
                {project.sites 
                    ? <a class="btn btn-small" href={accountModel.account.planName === 'promapper' ? `${constants.promapperLaunchUrl}#unearthProjectId=${project.projectId}` : `${constants.launchUrl}#projectId=${project.projectId}&siteId=${project.sites[0].siteId}`} target="_blank" rel="noopener noreferrer">Open Project</a>
                    : null}
                <div class="btn btn-small" onclick={() => modal(addNewUserToProjectDialog)}> Add New User</div>
                <div class="btn btn-small" onclick={() => modal(AddExistingUserDialog, {cssClass: 'add-user-modal'})}> Add Existing User</div>
            </div>
            <table>
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Assets</td>
                    <td>Last Activity</td>
                    <td>Last User</td>
                    <td>Account</td>
                    <td>Version</td>
                    <td>Created</td>
                </thead>
                <tbody>
                    <td>{project.name}<button class="edit-button" onclick={() => modal(UpdateProjectNameDialog)}><i class="icon-pencil"></i></button></td>
                    <td>{project.projectId}</td>
                    <td>{project.assetCount || 0}</td>
                    <td>{project.lastActivityDateTime 
                        ? new Date(project.lastActivityDateTime).toLocaleString()
                        : '-'}</td>
                    <td>{project.lastActiveUserId || '-'}</td>
                    <td><a href={`#view=account&accountId=${project.accountId}`}>{project.accountName}</a></td>
                    <td>{project.accountAttributes.appVersion === 1
                        ? project.accountAttributes.theme === 'unearth-sewer'
                            ? 'Sewer'
                            : 'Construction'
                        : 'OPV2'}</td>
                    <td>{new Date(project.createdDateTime).toLocaleString()}</td>
                </tbody>
            </table>
            <Tabs tabs={[{
                name: 'Users',
                view: ProjectUsers,
                selected: true
            }, {
                name: 'Toolboxes',
                view: ProjectToolboxes
            }]} />
        </div>;
    }
};
export default Project;
