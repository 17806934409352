const projectRoleInfoPopup = {
    view: () => <div class="project-role-popup">
        <h3 class="title">Project roles have been deprecated.</h3>
        <p>User project roles cannot be edited directly, rather, user project access can only be granted or revoked. If granted access to a project, the user's account level role will be applied automatically to that project.</p>
        <h5 class="title">A Note on Superadmin Project Users</h5>
        <p>This is a special distinction for internal Unearth users who have access to all projects on an account. They are invisible on the project (ie, they will not display in the project access list on the web app or as options in comment @mentions or user control assignment).</p>
    </div>
};

export default projectRoleInfoPopup;
