import Radio from 'views/radio/radio';

const OptionalLimit = {
    oninit: ({state, attrs: {defaultValue, defaultChecked}}) => {
        state.value = defaultValue;
        state.defaultIndex = defaultChecked === true ? 0 : 1;
        state.radioIndex = state.defaultIndex;
    },
    view: ({state, attrs: {
        onChange,
        onInput,
        numberLabel}}) => {
        return <fieldset class="optional-limit">
            <Radio
                defaultIndex={state.defaultIndex}
                onchange={(value, radioIndex) => {
                    state.radioIndex = radioIndex;
                    onChange(radioIndex === 0);
                }}
                options={[
                    <div class={`title-limit ${state.radioIndex === 1 && 'disabled'}`} key="limit">
                        <label>{numberLabel}</label>
                        <input
                            value={state.value}
                            type="number"
                            min={1}
                            step={1}
                            oninput={e => {
                                state.value = e.target.value;
                                if (onInput) {
                                    onInput(state.value);
                                }
                            }}/>
                    </div>,
                    <div class={`${state.radioIndex === 0 && 'disabled'}`} key="no-limit"><label>no limit</label></div>
                ]}
            />
        </fieldset>;
    }
};

export default OptionalLimit;
