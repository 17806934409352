
class PermissionsModel {

    constructor() {
        this.defaultPermissions = {
            'owner': {READ: true, CREATE: true, EDIT: true, DELETE: true},
            'admin': {READ: true, CREATE: true, EDIT: true, DELETE: true},
            'manager': {READ: true, CREATE: true, EDIT: true, DELETE: false},
            'general': {READ: true, CREATE: true, EDIT: true, DELETE: false},
            'limited': {READ: true, CREATE: true, EDIT: false, DELETE: false},
            'viewer': {READ: true, CREATE: false, EDIT: false, DELETE: false}
        };
        this.savedCustomPermissions = {};
        this.usingPlatformDefault = {};
    }

    removeIfSetToDefault(tool) {
        if (tool && tool.attributes && tool.attributes.permissions) {
            Object.keys(this.defaultPermissions).forEach(role => {
                this.usingPlatformDefault[tool.toolId] = this.usingPlatformDefault[tool.toolId] || {};
                // If designated as using platform default, delete anything custom: 
                if (this.usingPlatformDefault[tool.toolId][role]) {
                    delete tool.attributes.permissions[role];
                }  else {
                    const permissionsByRole = tool.attributes.permissions[role] || {};
                    Object.keys(permissionsByRole).forEach(action => {
                        if (this.defaultPermissions[role][action] === permissionsByRole[action]) {
                            delete tool.attributes.permissions[role][action];
                        }
                    });
                }
                if (tool.attributes.permissions[role] && !Object.keys(tool.attributes.permissions[role]).length) {
                    delete tool.attributes.permissions[role];
                }
            });
        }
    }
    
    arePermissionsDefault(role, access, toolAttributes) {
        const permissionsByRole = toolAttributes.permissions[role] || {};
        return Object.keys(toolAttributes.permissions[role]).every(permission => this.defaultPermissions[role][permission] === permissionsByRole[permission]);
    }

    initToolAttributes(toolId, toolAttributes) {
        this.usingPlatformDefault[toolId] = {};
        Object.keys(this.defaultPermissions).forEach(role => {
            this.usingPlatformDefault[toolId][role] = true;
        });
        if (toolAttributes && toolAttributes.permissions) {
            Object.keys(toolAttributes.permissions).forEach(role => {
                const permissions = toolAttributes.permissions[role] || {};
                this.usingPlatformDefault[toolId][role] = !Object.keys(permissions);
            });
        }
    }
    
    resetPermissions(toolId, role, toolAttributes) {
        if (toolAttributes.permissions && toolAttributes.permissions[role]) {
            this.savedCustomPermissions[toolId] = this.savedCustomPermissions[toolId] || {};
            this.savedCustomPermissions[toolId][role] = {...toolAttributes.permissions[role]};
            toolAttributes.permissions[role] = {};
        }
        this.usingPlatformDefault[toolId][role] = true;
        m.redraw();
    }

    showCustomPermissions(toolId, role, toolAttributes) {
        if (this.savedCustomPermissions[toolId] && this.savedCustomPermissions[toolId][role]) {
            toolAttributes.permissions[role] = {...this.savedCustomPermissions[toolId][role]};
        }
        this.usingPlatformDefault[toolId][role] = false;
        m.redraw();
    }
    
    toggleAllPermissionsOff(role, toolAttributes) {
        toolAttributes.permissions[role] = {READ: false, CREATE: false, EDIT: false, DELETE: false};
    }

    isRoleNoView(role, toolAttributes) {
        if (toolAttributes && toolAttributes.permissions) {
            return toolAttributes.permissions[role] && toolAttributes.permissions[role].READ === false;
        }
        return this.defaultPermissions[role].READ === false;
    }

    isRoleViewOnly(role, access, toolAttributes) {
        if (toolAttributes && toolAttributes.permissions) {
            return toolAttributes.permissions[role] && toolAttributes.permissions[role].READ === true && toolAttributes.permissions[role].CREATE === false && toolAttributes.permissions[role].EDIT === false && toolAttributes.permissions[role].DELETE === false;
        }
        return this.defaultPermissions[role].READ === true && this.defaultPermissions[role].CREATE === false && this.defaultPermissions[role].EDIT === false && this.defaultPermissions[role].DELETE === false;
    }

    permissionDisabledMessage(access) {
        if (access === 'DELETE') {
            return 'not supported for this Role when Add New Permission = no';
        } 
        return 'not supported';
    }

    isPermissionDisabled(role, access, toolAttributes) {
        const permissions = toolAttributes. permissions && toolAttributes.permissions[role] ? toolAttributes.permissions[role] : {};
        if (this.isRoleNoView(role, access, toolAttributes)) {
            return access !== 'READ';
        }
        switch (role) {
        case 'manager':
        case 'general':
        case 'limited':
            return permissions.CREATE === false && access === 'DELETE';
        default:
            return false;
        }
    }

    isPermissionCheckShown(role, access) {
        if (role === 'limited' && (access === 'EDIT' || access === 'DELETE')) {
            return false;
        }
        if ((role === 'manager' || role === 'general') && access === 'DELETE' ) {
            return false;
        }
        return !(role === 'viewer' && access !== 'READ');
    }

    isPermissionShown(toolAttributes, role, access) {
        const permissions = toolAttributes. permissions && toolAttributes.permissions[role] ? toolAttributes.permissions[role] : {};
        switch (role) {
        case 'viewer':
            return access === 'READ';
        default:
            return access === 'READ' || permissions.READ !== false;
        }
    }

    isPermissionOffShown(role, access) {
        if ((role === 'admin' || role === 'owner') && access === 'READ') {
            return false;
        }
        if (role === 'owner' && (access === 'EDIT' || access === 'DELETE')) {
            return false;
        }
        return true;
    }

    toggleRolePermission(toolId, role, access, toolAttributes) {
        if (this.usingPlatformDefault[toolId][role]) {
            this.usingPlatformDefault[toolId][role] = false;
        }
        if (toolAttributes && toolAttributes.permissions) {
            if (toolAttributes.permissions[role]) {
                if (toolAttributes.permissions[role][access] === true) {
                    toolAttributes.permissions[role][access] = false;
                } else if (toolAttributes.permissions[role][access] === false) {
                    toolAttributes.permissions[role][access] = true;
                } else if (!toolAttributes.permissions[role][access]) {
                    toolAttributes.permissions[role][access] = !this.defaultPermissions[role][access];
                }
            } else {
                toolAttributes.permissions[role] = {[access]: !this.defaultPermissions[role][access]};
            }
        } else {
            toolAttributes.permissions = {[role]: {[access]: !this.defaultPermissions[role][access]}};
        }
        
        if (this.isRoleNoView(role, toolAttributes)) {
            this.toggleAllPermissionsOff(role, toolAttributes);
        }

        if (this.arePermissionsDefault(role, access, toolAttributes)) {
            this.usingPlatformDefault[toolId][role] = true;
        }
    }

    isOptionChecked(role, access, toolAttributes) {
        if (toolAttributes && toolAttributes.permissions) {
            if (toolAttributes.permissions[role]) {
                if (toolAttributes.permissions[role][access] === false ) {
                    return false;
                } else if (toolAttributes.permissions[role][access]) {
                    return true;
                }
                return this.defaultPermissions[role][access];
            }
        }
        return this.defaultPermissions[role][access];
    }
}

export default new PermissionsModel();
