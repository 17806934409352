import * as colorPicker from 'a-color-picker';
import onBodyClick from 'util/events/on-body-click';

const ColorPicker = {
    oninit({attrs: {render, propKey, properties}}) {
        render && render(properties[propKey]);
    },
    oncreate({dom, state, attrs: {propKey}}) {
        state.toggle = () => {
            if (state.open) {
                state.picker.remove();
            } else {
                const properties = state.properties;
                const render = state.render;
                state.picker = document.createElement('div');
                state.picker.classList.add('color-picker-wrap');
                state.picker.onclick = e => e.stopPropagation();
                dom.appendChild(state.picker);
                colorPicker.createPicker(state.picker, {
                    color: properties[propKey]
                }).on('change', (picker, color) => {
                    properties[propKey] = colorPicker.parseColor(color, 'hex');
                    render && render(properties[propKey]);
                    m.redraw();
                });
                onBodyClick.once(state.toggle);
            }
            state.open = !state.open;
        };
    },
    view: ({state, attrs: {propKey, properties, render}}) => {
        state.properties = properties;
        state.render = render;
        return <div class="control-group color-group">
            <div class="color-picker" onclick={() => state.toggle()}>
                <div class="color-picker-select" style={`background-color:${properties[propKey]};`} />
            </div>
        </div>;
    }
};

export default ColorPicker;
