import accountModel from 'models/account-model';
import UpdateUserRoleDialog from 'views/account/update-user-role-dialog';
import removeUserFromAccountDialog from 'views/account/remove-user-from-account-dialog';
import modal from 'views/modal/modal';
import constants from 'util/data/constants';


const AccountUsers = {
    
    view: () => 
        <table>
            <thead>
                <td>Name</td>
                <td>User ID</td>
                <td>Email</td>
                <td>Phone</td> 
                <td>Role 
                    {accountModel.account.users.length === 0 ?
                        ''
                        :
                        <button class="role-edit edit-button" onclick={() => modal(UpdateUserRoleDialog, {cssClass: 'add-user-modal'})}> <i class= "icon-pencil"></i></button>
                    }
                </td> 
                <td>Company</td>
                <td>Added</td>
                <td>Is Visible</td>
                <td></td> 
            </thead>
            <tbody>
                {accountModel.account.users.length === 0 &&
                    accountModel.loading ?
                    <div class ="empty-row">
                        <div class="no-users">
                            <div class="spinner spinning teal account-users"></div>
                        </div> 
                    </div>
                    :
                    accountModel.account.users.map(user => {
                        const name = user.givenName ? `${user.givenName} ${user.familyName}` : '-';
                        return <tr key={user.userId || user.createdDateTime}>
                            <td>{user.userId
                                ? <a href={`#view=user&userId=${user.userId}`}>{name}</a>
                                : name}
                            </td>
                            <td>{user.userId || '-'}</td>
                            <td>{user.emailAddress || '-'}</td>
                            <td>{user.phoneNumber || '-'}</td>
                            <td>{constants.accountUserRoles[user.role] && constants.accountUserRoles[user.role].display || '-'}</td>
                            <td>{user.company || '-'}</td>
                            <td>{user.createdDateTime ? new Date(user.createdDateTime).toLocaleDateString() : '-'}</td>
                            <td>{`${user.isVisible}`}</td>
                            <td><button class="remove-user-from-account-button" onclick={() => modal(removeUserFromAccountDialog, {user})}><i class="icon-trash"></i></button></td>
                        </tr>;
                    })}
            </tbody>
        </table>
};

export default AccountUsers;
