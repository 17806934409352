import toolboxModel from 'models/toolbox-model';
import collapsibleModel from 'models/collapsible-model';
import sortableModel from 'models/sortable-model';
import ToolPreview from 'views/toolbox/tool-preview';
import ToolProperties from 'views/toolbox/tool-properties';
import ToolPermissions from 'views/toolbox/tool-permissions';
import ToolRepresentation from 'views/toolbox/tool-representation';

const Tool = {
    view: ({attrs: {toolGroup, tool}}) => {
        const featureType = tool.featureTypes[0],
            icons = [tool.assetForm.assetType.attributes.icon],
            disabledClass = toolboxModel.isProtectedTool[tool.name] ? 'locked-tool disabled' : '',
            publishedClass = tool.updatedDateTime && !toolboxModel.isUnlocked ? 'published-tool' : '';
        if (featureType) {
            icons.push(featureType.attributes.icon);
        }
        return <div id={tool.toolId} class={`data-type-wrap collapsible ${collapsibleModel.isCollapsed(tool.toolId) ? 'collapsed' : ''}  ${disabledClass} ${publishedClass} ${tool.updatedDateTime && !toolboxModel.isUnlocked ? 'published-group' : ''} ${sortableModel.isReorderingProperties(tool.toolId) ? 'reordering-properties' : ''} clear`}>
            <div class={`collapsible ${collapsibleModel.isCollapsed(tool.toolId) ? 'collapsed' : ''} ${disabledClass} ${publishedClass} ${`data-type-header ${tool.name === 'Comment' ? 'hidden' : ''} ${toolboxModel.sharedAssetTypeClass(tool)}`} clear`}>
                <div class="collapsible-name data-type-name">
                    <label>Data Type</label>
                    <ToolPreview name={tool.name} mediaId={tool.assetForm.assetType.attributes.icon.mediaId}/>
                    <span class="tool-config"><input type="text" class="editable-when-published" oninput={e => {
                        const value = e.target.value;
                        tool.featureStyles.forEach(featureStyle => {
                            featureStyle.name = featureStyle.name.replace(tool.name, value);
                        });
                        tool.name = value;
                        tool.assetForm.name = value;
                        tool.assetForm.assetType.name = value;
                        tool.featureTypes[0].name = value;
                    }} value={tool.name}/></span>
                    <i class="icon-up collapsible-icon right" onclick={() => {
                        collapsibleModel.handleClick(tool.toolId);
                    }}/>
                    <i class="icon-trash right" onclick={() => toolboxModel.removeTool(toolGroup, tool.toolId)}/>
                </div>  
            </div>
            {collapsibleModel.isCollapsed(tool.toolId) ? <></> 
                : <div class="collapsible-items data-type-content">
                    <div class="tool">
                        <div class="tool-config">
                            <fieldset class="hidden-toggle">
                                <input type="checkbox" class="left editable-when-published" checked={tool.attributes.hidden} onchange={e => {
                                    if (e.target.checked) {
                                        tool.attributes.hidden = true;
                                    } else {
                                        delete tool.attributes.hidden;
                                    }
                                }} />
                                <label class="editable-when-published">Hide Tool and all instances of this Type</label>
                            </fieldset>
                            <fieldset className="min-zoom-toggle">
                                <input type="checkbox" className="left editable-when-published" checked={tool.attributes.minzoom} onchange={e => {
                                    if (!e.target.checked) {
                                        delete tool.attributes.minzoom;
                                    }
                                }}/>
                                <span><label class="editable-when-published">Hide map features of this Type at a zoom level of <span class={'min-zoom-input'}><input type="text" value={tool.attributes.minzoom}placeholder="0-25" oninput={e => {
                                    if (e.target.value) {
                                        tool.attributes.minzoom = e.target.value;
                                    } else {
                                        delete tool.attributes.minzoom;
                                    }
                                }}/></span> and below</label></span>
                            </fieldset>
                            <div class="column-layout">
                                <ToolRepresentation tool={tool}/>
                                <ToolProperties tool={tool}/>
                                <ToolPermissions toolId={tool.toolId} toolAttributes={tool.attributes}/>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>;
    }
};

export default Tool;
