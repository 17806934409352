import uploadModel from 'models/upload-model';

const Uploader = {
    view: () => 
        <div class="modal">
            <div class="uploader modal-content">
                {uploadModel.files.map(file => 
                    <div class={`uploader-file ${file.failed ? 'failed' : ''}`} key={file.name}>
                        <div>
                            {file.name}<br/>
                            {file.failed && 'Failed to upload file. Modal will close.'}
                        </div>
                        <div class="progress-wrap">
                            <div class="progress-bar" style={`width:${file.percent || 0}%;`} />
                        </div>
                    </div>
                )}
            </div>
        </div>
};

export default Uploader;
