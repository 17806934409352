// "DD MMM YYYY"

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function formatMonth(date) {

    return months[date.getMonth()];

}

function formatDay(date, withYear) {

    if (!(date instanceof Date)) {

        date = new Date(date);

    }

    let dateString = `${date.getDate()} ${formatMonth(date)}`;

    if (withYear) {
        dateString += ", '" + date.getFullYear().toString().substring(2);
    }

    return dateString;

}

// "X seconds|minutes|hours|days ago"

const multipliers = {
    sec: 1000,
    min: 60000,
    hours: 3600000,
    days: 86400000
};

function convert(date, interval, now = new Date()) {

    return Math.round(Math.abs(now - date) / multipliers[interval]);

}

function readableInterval(dateA, dateB = new Date()) {

    const seconds = convert(dateA, 'sec', dateB);

    if (seconds < 60) {

        return {
            num: seconds,
            unit: 'sec'
        };

    }

    const minutes = convert(dateA, 'min', dateB);

    if (minutes < 60) {

        return {
            num: minutes,
            unit: 'min'
        };

    }

    const hours = convert(dateA, 'hours', dateB);

    if (hours < 24) {

        return {
            num: hours,
            unit: hours === 1 ? 'hour' : 'hours'
        };

    }

    const days = convert(dateA, 'days', dateB);

    return {
        num: days,
        unit: days === 1 ? 'day' : 'days'
    };

}

function formatDate(date) {

    if (!(date instanceof Date)) {

        date = new Date(date);

    }

    const readable = readableInterval(date, new Date());

    return `${readable.num} ${readable.unit} ago`;

}

formatDate.forPython = date => date.toISOString().replace(/\..*Z$/, 'Z');

formatDate.getDaysAgo = days => {

    if (days === -1) {

        return;

    }

    const date = new Date();

    if (days === 1) { // filter for "today", not "last 24 hours"

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

    } else {

        date.setDate(date.getDate() - days);

    }

    return formatDate.forPython(date);

};

function formatAMPM(date) {

    if (!(date instanceof Date)) {

        date = new Date(date);

    }

    let hours = date.getHours();

    const ampm = hours >= 12 ? 'pm' : 'am';
    
    hours = hours % 12 || 12;
    
    let minutes = date.getMinutes();

    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes}${ampm}`;
}

formatDate.timestamp = date => {

    if (!date) {
        return 'N/A';
    }

    if (!(date instanceof Date)) {

        date = new Date(date);

    }

    const now = new Date();

    if (now - date < multipliers.days && now.getDate() === date.getDate()) {

        return formatAMPM(date);

    }

    if (now.getFullYear() === date.getFullYear()) {

        return `${date.getDate()} ${formatMonth(date)}`;

    }

    const year = date.getFullYear().toString().substring(2);

    return `${date.getDate()} ${formatMonth(date)}, '${year}`;

};

formatDate.ddmmmyy = date => {

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear().toString().substring(2)}`;

};

formatDate.ddmmyyyy = date => {
    
    if (!date) {
        return 'N/A';
    }

    if (!(date instanceof Date)) {

        date = new Date(date);

    }

    return date && `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear().toString()}` || 'N/A';
};

formatDate.setTimeToZero = date => {

    date.setHours(0);

    date.setMinutes(0);

    date.setSeconds(0);

    date.setMilliseconds(0);

    return date;

};

formatDate.setTimeToMidnight = date => {

    date.setHours(23);

    date.setMinutes(59);

    date.setSeconds(59);

    date.setMilliseconds(999);

    return date;

};

formatDate.formatAMPM = formatAMPM;

formatDate.convert = convert;

formatDate.formatDay = formatDay;

formatDate.months = months;

formatDate.formatMonth = formatMonth;

formatDate.readableInterval = readableInterval;

export default formatDate;
