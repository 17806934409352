import findAndRemove from 'util/data/find-and-remove';
import toolboxModel from 'models/toolbox-model';

class AssetTypeTreeModel {

    static addAssetTypeId(control, assetTypeId) {
        if (!control.attributes.acceptedAssetTypeIds) {
            control.attributes.acceptedAssetTypeIds = [];
        }

        const index = control.attributes.acceptedAssetTypeIds.findIndex((accepted) => accepted === assetTypeId);
        if (index === -1) {
            control.attributes.acceptedAssetTypeIds.push(assetTypeId);
        }
    }

    static removeAssetTypeId(control, assetTypeId) {
        findAndRemove(control.attributes.acceptedAssetTypeIds, (accepted) => accepted === assetTypeId);
    }

    static removeAllAssetTypeIds(control) {
        control.attributes.acceptedAssetTypeIds = [];
    }

    static addAllAssetTypeIds(control) {
        const acceptedAssetTypeIds = [];
        const toolGroups = toolboxModel.toolbox.group.groups;
        toolGroups.forEach((toolGroup) => {
            toolGroup.tools.forEach((tool) => {
                const assetType = tool.assetForm.assetType;
                const assetTypeId = assetType.assetTypeId;
                if (tool.assetForm.assetType.name !== 'Comment') {
                    acceptedAssetTypeIds.push(assetTypeId);
                }
            });
        });
        control.attributes.acceptedAssetTypeIds = acceptedAssetTypeIds;
    }

    static addGroup(control, group) {
        if (!control.attributes.acceptedAssetTypeIds) {
            control.attributes.acceptedAssetTypeIds = [];
        }

        const acceptedMap = {};

        control.attributes.acceptedAssetTypeIds.forEach((acceptedId) => {
            acceptedMap[acceptedId] = true;
        });

        group.tools.forEach((tool) => {
            const assetType = tool.assetForm.assetType;
            const assetTypeId = assetType.assetTypeId;
            if (!acceptedMap[assetTypeId] && assetType.name !== 'Comment') {
                control.attributes.acceptedAssetTypeIds.push(assetTypeId);
            }
        });
    }

    static removeGroup(control, group) {

        const acceptedMap = {};

        control.attributes.acceptedAssetTypeIds.forEach((acceptedId) => {
            acceptedMap[acceptedId] = true;
        });

        group.tools.forEach( (tool) => {
            const assetTypeId = tool.assetForm.assetType.assetTypeId;
            if (acceptedMap[assetTypeId]) {
                findAndRemove(control.attributes.acceptedAssetTypeIds, (accepted) => accepted === assetTypeId);
            }
        });
    }
}

export default AssetTypeTreeModel;
