import appModel from 'models/app-model';
import constants from 'util/data/constants';
import toolboxModel from 'models/toolbox-model';
import MreactComponent from 'util/dom/mreact-component.js';
import UnearthIconReact from 'views/react/UnearthIcon/UnearthIcon.jsx';

const App = {
    oninit({state}) {
        state.View = appModel.view;
        appModel.showWelcomeMessage();
    },

    view: ({state: {View}}) => {
        const Modal = appModel.modal;
        return <div class="app">
            <header>
                <a class="nav-btn" href="#">
                    <MreactComponent element={UnearthIconReact()}/>
                </a>
                <a class="nav-btn first" href={`#view=account&accountId=${constants.unearthAccountId}&tab=Toolboxes`}>
                    <i className="icon-ss-list"/>
                    <div class="button-label">Manage Toolboxes</div>
                </a>
                <div class="nav-btn" onclick={() => toolboxModel.import()}>
                    <i className="icon-upload" />
                    <div class="button-label">Import Toolbox</div>
                </div>
                <div class="nav-btn" onclick={() => toolboxModel.create()}>
                    <i className="icon-plus" />
                    <div class="button-label">Create Toolbox</div>
                </div>
                <a class="nav-btn" href="#view=drafts">
                    <i className="icon-files" />
                    <div class="button-label">Quick Drafts</div>
                </a>
                <div class="nav-btn bottom">
                    <img className="log-out" src="/images/power-off-solid-white.svg" onclick={() => UE.logout()}/>
                </div>
            </header>
            <View />
            {Modal && <Modal />}
        </div>;
    }
};

export default App;
