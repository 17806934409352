import React from 'react';

const buttonSizeList = {
    default: '',
    small: 'small'
};

const buttonColorList = {
    default: '',
    white: 'color-white'
};

const Button = ({ handleButtonClick, buttonText, buttonSize, buttonStyle, buttonColor, isLoading }) => {
    return (
        <div
            className={`button ${buttonSizeList[buttonSize]} ${buttonStyle || ''} ${buttonColorList[buttonColor]}`}
            onClick={handleButtonClick || null}
        >
            {isLoading ? <div className="loading"></div> : buttonText}
        </div>
    );
};
 
const ButtonElement = (props) => React.createElement(Button, props, null);

export default ButtonElement;
