const feetPerMeter = 3.280839895,
    squareFeetPerSquareMeter = 10.76391041671,
    cubicYardsPerCubicMeter = 1.3079506193,
    yardsPerMeter = 1.0936132983;


function yardsToMeters(yards) {

    return yards / yardsPerMeter;

}

function metersToYards(meters) {

    return meters * yardsPerMeter;

}

function metersToFeet(meters) {

    return meters * feetPerMeter;

}

function feetToMeters(feet) {

    return feet / feetPerMeter;

}

function squareMetersToSquareFeet(squareMeters) {

    return squareMeters * squareFeetPerSquareMeter;

}

function squareFeetToSquareMeters(squareFeet) {

    return squareFeet / squareFeetPerSquareMeter;

}

function cubicMetersToCubicYards(cubicMeters) {

    return cubicMeters * cubicYardsPerCubicMeter;

}

function cubicYardsToCubicMeters(cubicYards) {

    return cubicYards / cubicYardsPerCubicMeter;

}

export default {
    yardsToMeters,
    metersToYards,
    metersToFeet,
    feetToMeters,
    squareMetersToSquareFeet,
    squareFeetToSquareMeters,
    cubicMetersToCubicYards,
    cubicYardsToCubicMeters
};
