const ErrorLog = {
    view: ({attrs: {errors, onClose}}) => 
        <div class="error-log" onclick={e => e.stopPropagation()}>
            {onClose && <div class="btn btn-small right" onclick={onClose}>Dismiss</div>}
            <div>
                {errors.map(error => <p>{error}</p>)}
            </div>
        </div>
};

export default ErrorLog;
