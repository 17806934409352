// When returning a list of records, our API
// sometimes wraps that list an an envelope ({items: [...]})
// A couple times this behavior has changed without notice,
// so the safe bet is to always handle both forms.
function list(data) {

    return data && data.items ? data.items : data || [];

}

export default list;
