import prune from 'util/data/prune';
import debounce from 'util/events/debounce';
import {authManager} from '@procore/sdt-core-js';


/**
 * paginateComponent is a HOC that adds pagination to any Mithril component.
 *
 * Component view functions are now passed an object dataModel: {
 *     data: [<Results from paginated request or search>],
 *     loading: <Boolean set to true if a page is loading or false when request is completed>
 *     search: A utility function for searching
 * }
 *
 * Example usage: export default paginateComponent(wrappedComponent, {dataModel: fooModel, dataKey:'foo'});
 *
 * search: Once the component is wrapped a function, search(e:event) is attached to the dataModel.
 *      dataModel.search can be called by events on input elements
 *      Example <input oninput={dataModel.search} />
 *
 * View signature: view(vnode:<Mithril vnode object>, dataModel: <dataModel object>)
 *
 */

const paginateComponent = (component, opts) => {
    const {oninit, view} = component;
    const {dataModel, dataKey = 'data'} = opts;

    dataModel.search = debounce((e) => {
        const value = e.target.value;
        dataModel[dataKey] = [];
        if (!value || value.length === 0)  {
            delete dataModel._query;
        } else {
            dataModel._query = value;
        }
        paginateComponent._sendRequest(opts);
    });

    const hoc = Object.assign({}, component, {
        oninit: (vnode) => {
            dataModel[dataKey] = [];
            paginateComponent._sendRequest(opts);
            return oninit(vnode, dataModel);
        },

        view: (vnode) => {
            const _view = view(vnode, dataModel);
            _view.attrs.onscroll = (e) => {
                const el = e.target;
                if ( el.scrollTop >= el.scrollHeight - el.offsetHeight - 200 &&
                    dataModel._hasMoreResults &&
                    !dataModel._gettingNextPage
                ) {
                    dataModel._gettingNextPage = true;
                    paginateComponent._sendRequest(opts).then(() => {
                        requestAnimationFrame(() => {
                            _view.attrs.onscroll(e);
                        });
                    });
                }
            };
            return _view;
        }
    });
    return hoc;
};

paginateComponent._sendRequest = ({
    type,
    limit = 20,
    dataModel,
    onResults,
    filter,
    dataKey = 'data'
}) => {
    const query = dataModel._query;
    const data = dataModel[dataKey];
    dataModel.loading = true;

    const handleResults = (results) => {
        if (!dataModel._cancelled) {

            if (prune[type]) {
                results = results.map(prune[type]);
            }

            data.push(...results);

            m.redraw();

            if (onResults) {
                onResults();
            }

            dataModel._hasMoreResults = results.length === limit;
            dataModel._gettingNextPage = false;
            dataModel.loading = false;
        }
    };

    if (query) {
        return UE.socket.send({
            type: 'search',
            payload: {
                type,
                query,
                offset: data.length,
                userId: authManager.userId,
                platform: 'web',
                version: '1.6',
                searchType: 'search',
                limit,
                filter
            }
        }).then(handleResults);
    }

    return UE.rpc([['list' + type[0].toUpperCase() + type.slice(1) + 's', Object.assign({
        limit,
        offset: dataModel[dataKey].length
    }, filter || {})]])
        .then(responses => responses[0]).then(handleResults);
};

export default paginateComponent;
