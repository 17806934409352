import 'ue';
import m from 'mithril';
import Login from 'views/login';
import constants from 'util/data/constants';
import userModel from 'models/user-model';
import router from 'uav-router';
import Account from 'views/account';
import App from 'views/app';
import appModel from 'models/app-model';
import Project from 'views/project';
import Toolbox from 'views/toolbox';
import Drafts from 'views/drafts';
import Home from 'views/home';
import User from 'views/user';
import onBodyClick from 'util/events/on-body-click';
import paginate from 'util/data/paginate';
import {authManager, env} from '@procore/sdt-core-js';

window.m = m;

const $app = document.getElementById('app');

env.init(constants.awsProfile);


const views = {
    home: Home,
    accounts: Home,
    projects: Home,
    users: Home,
    account: Account,
    project: Project,
    toolbox: Toolbox,
    drafts: Drafts,
    user: User
};

function render(view) {

    paginate.stop();

    UE.clearRequests();

    onBodyClick.reset();

    appModel.view = views[view] || Home;

    m.mount($app, null);

    m.mount($app, App);

}

UE.onConnect = () => UE.rpc([['getUser', {userId: authManager.userId}]])
    .then(([user]) => userModel.authenticate(user))
    .then(() => router.init(params => render(params.view)));

UE.onDisconnect = badCredentials => m.mount($app, {
    view: () =>
        <div class="login-parent">
            <div class="login-header"><img src="/images/unearth-logo-mini.png" />Admin Dashboard</div>
            <Login badCredentials={badCredentials} />
        </div>
});

UE.onError = error => console.error(error);

UE.init(constants.awsProfile);
