import modal from 'views/modal/modal';
import accountModel from 'models/account-model';
import validate from 'util/data/validate';
import constants from 'util/data/constants';
import ErrorBox from 'views/error-box/error-box';

const AddNewUserDialog = {
    oninit: ({state}) => {
        state.errors = {
            givenName: null,
            familyName: null,
            emailAddress: null,
            phoneNumber: null
        };
        state.roles = Object.keys(constants.accountUserRoles);
        // default to general user.
        state.role = state.roles[1];
        state.addProjects = false;
    },

    view: ({state}) => {
        const {errors} = state;
        const isDisabled = Object.keys(errors).length !== 0;
        const options = Object.values(constants.accountUserRoles).filter(role => role.key !== 'superadmin');
        return <div class="create-user">
            <h2>Add New User to {accountModel.account.name}</h2>
            <ErrorBox error={state.createUserError}/>
            <div>
                <fieldset>
                    <label>First Name</label>
                    {errors.givenName && <label class="error">{errors.givenName}</label>}
                    <input type="text" oninput={(e) => {
                        state.givenName = e.target.value;
                        validate.errorCheck.givenName(state);
                    }} onblur={() => validate.errorCheck.givenName(state)}/>
                </fieldset>
                <fieldset>
                    <label>Last name</label>
                    {errors.familyName && <label class="error">{errors.familyName}</label>}
                    <input type="text"oninput={(e) => {
                        state.familyName = e.target.value;
                        validate.errorCheck.familyName(state);
                    }} onblur={() => validate.errorCheck.familyName(state)}/>
                </fieldset>
                <fieldset>
                    <label>Email address</label>
                    {errors.emailAddress && <label class="error">{errors.emailAddress}</label>}
                    <input type="text" oninput={(e) => {
                        state.emailAddress = e.target.value;
                        validate.errorCheck.emailAddress(state);
                    }} onblur={() => validate.errorCheck.emailAddress(state, true)}/>
                </fieldset>
                <fieldset>
                    <label>Phone number</label>
                    {errors.phoneNumber && <label class="error">{errors.phoneNumber}</label>}
                    <input type="text" oninput={(e) => {
                        state.phoneNumber = e.target.value;
                        validate.errorCheck.phoneNumber(state);
                    }} onblur={() => validate.errorCheck.phoneNumber(state, true)}/>
                </fieldset>
                <fieldset>
                    <label>Company</label>
                    <input type="text" oninput={(e) => {
                        state.company = e.target.value;
                    }}/>
                </fieldset>
                <fieldset>
                    <label>Role</label>
                    <div class="select-wrapper">
                        <select onchange={(e) => {
                            state.role = e.target.value;
                        }}>
                            {options.map((role) =>
                                <option
                                    selected={role.key === state.role}
                                    value={role.key}
                                >
                                    {role.display}
                                </option>
                            )}
                        </select>
                    </div>
                </fieldset>
                <fieldset>
                    <label>Projects</label>
                    <div class="checkbox-wrap">
                        <input onclick={ () => {
                            state.addProjects = !state.addProjects;
                        }} class="editable-when-published" type="checkbox" checked={state.addProjects}/>
                        <div class="projects-check-desc">Add all projects to new user?</div>
                    </div>
                </fieldset>
                <div class="create-account-btn-wrap">
                    <div class={`btn ${isDisabled || accountModel.loading ? 'disabled' : ''}`} onclick={() => {
                        const {
                            givenName,
                            familyName,
                            emailAddress,
                            phoneNumber
                        } = errors;
                        if (givenName || familyName || emailAddress || phoneNumber) {
                            return;
                        }
                        accountModel.addNewUser(state);
                    }}>
                        {state.loading ? <><div class="spinner spinning"/><span>Processing</span></> : 'Add New User'}
                    </div>
                    <div class="btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>;
    }

};

export default AddNewUserDialog;
