import accountModel from 'models/account-model';
import constants from 'util/data/constants';
import ToolboxSearchBox from 'views/toolbox-picker/toolbox-search-box';
import MreactComponent from 'util/dom/mreact-component';
import ButtonElement from 'views/react/Button';
import ToolboxTableHeaderElement from 'views/react/ToolboxTableHeader';

const AccountToolboxes = {
    oninit: ({state}) => {
        state.model = accountModel.toolboxPicker;
        state.model.fetchToolboxes(true);
    },

    view: ({state: {model}}) => <div>
        <div class="table-meta">
            <ToolboxSearchBox model={model}/>
            <div class="provision-toolbox">
                <MreactComponent element={ButtonElement({
                    handleButtonClick: () => accountModel.provisionToolbox(),
                    buttonText: 'Provision a toolbox for this account',
                    buttonSize: 'small',
                    buttonColor: 'white'
                })} />
            </div>
        </div>
        <table>
            <thead>
                <MreactComponent element={ToolboxTableHeaderElement({})} tagName="tr"/>  
            </thead>
            <tbody>
                {model.isFetchingToolboxes ?
                    <div class="empty-row">
                        <div class="loading-accounts" style="padding: 20px; text-align: center;">
                            <span>Fetching toolboxes...</span>
                        </div>
                    </div>
                    :
                    model.toolboxes.length ? model.toolboxes.map(toolbox => {
                        const isMeta = toolbox.categories.find(g =>
                            g.tools.find(t =>
                                t.assetForm && t.assetForm.controls && t.assetForm.controls.find(c =>
                                    c.controlTypeId === constants.controlTypeNameToId.project
                                )
                            )
                        );
                        return <tr key={toolbox.id}>
                            <td>
                                <a class="toolbox-name" href={`#view=toolbox&accountId=${accountModel.account.id}&toolboxId=${toolbox.id}`} target="_blank" rel="noopener noreferrer">
                                    <img src={constants.mediaServerUrl + toolbox.attributes.icon.mediaId}/>
                                    {toolbox.name}
                                </a>
                            </td>
                            <td>{toolbox.id}</td>
                            <td>{new Date(toolbox.createdAt).toLocaleString()}</td>
                            <td>{new Date(toolbox.updatedAt).toLocaleString()}</td>
                            <td>{(isMeta ? <div class="is-meta-pill">Meta</div> : 'Normal')}</td>
                            <td class="toolbox-button-wrap">
                                <div class="btn btn-small" onclick={() => accountModel.cloneToolbox(toolbox)}>Clone</div>
                                <div class="btn btn-small btn-red btn-secondary" onclick={() => accountModel.deleteToolbox(toolbox)}>Delete</div>
                            </td>
                        </tr>;
                    }) : <div class="no-results-message"><span>No toolboxes found.</span></div>}
            </tbody>
        </table>
        {!model.fetchedAll && !model.isFetchingToolboxes ? <div class="toolbox-list-footer"> {model.isFetchingPage ? <i class="spinner spinning teal"/> : <span class="btn btn-primary btn-small" onclick={() => model.getNextPage()}>Load next page</span>}</div> : ''}
    </div>
};

export default AccountToolboxes;
