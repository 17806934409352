import userModel from 'models/user-model';
import accountModel from 'models/account-model';
import modal from 'views/modal/modal';
import ErrorBox from 'views/error-box/add-remove-error-box';
import list from 'util/data/list';
import paginateComponent from 'util/data/paginate-component';
import constants from 'util/data/constants';

const AddUserToAccountDialog = {
    onremove: () => delete userModel.addUserError,

    oninit: ({state}) => {
        state.processing = {};
        state.userRoles = {};
        state.accountsMap = {};
        userModel.accounts.forEach(account => {
            state.accountsMap[account.accountId] = account;
        });
        userModel.accounts.forEach((account) => {
            if (account.role === undefined) {
                state.userRoles[account.accountId] = list(account.users).find(user => user.userId === userModel.user.userId).role;
            } else {
                state.userRoles[account.accountId] = account.role;
            }
        });
    },
    userActionButton: (account, state) => {
        if (state.processing[account.accountId]) {
            return <div class="btn disabled add-remove-processing"><div class="add-remove-spinner spinner spinning"/></div>;
        } else if (state.accountsMap[account.accountId]) {
            return <div class="project-modal-button btn disabled"><span>Added</span></div>;
        }
        return <div class="btn" onclick={() => userModel.addUserToAccount(account, state)}> Add</div>;
    },
    view: ({state}, dataModel) => {
        const options = Object.values(constants.accountUserRoles).filter(role => role.key !== 'superadmin');
        return <div class="add-user-to-account">
            <h3>Add User To Account</h3>
            <fieldset>
                <label>Search</label>
                <input type="text" oninput={(e) => {
                    dataModel.search(e);
                }}/>
            </fieldset>
            <table class="add-user-table">
                <thead>
                    <td>Account Name</td>
                    <td>Account ID</td>
                    <td>User Role</td>
                    <td></td>
                </thead>
                <tbody>
                    {dataModel.data.length === 0 &&
                        userModel.loading ? 
                        <div class ="empty-row">
                            <div class="no-users">
                                <div class="spinner spinning teal add-user"></div>
                            </div> 
                        </div>
                        :
                        dataModel.data.map((account) => {
                            return <tr key={account.userId || account.createdDateTime}>
                                <td>{account.accountId
                                    ? <a href={`#view=account&accountId=${account.accountId}`} 
                                        onclick={() => modal.close()}>{account.name}</a>
                                    : account.name}
                                </td>
                                <td>{account.accountId}</td>
                                <td>
                                    {state.accountsMap[account.accountId] ?
                                        <div class="account-user-select select-wrapper-disabled">
                                            <select disabled>
                                                <option>
                                                    {state.userRoles[account.accountId]}
                                                </option>
                                            </select>
                                        </div>
                                        :
                                        <div class="account-user-select select-wrapper">
                                            <select onchange={(e) => {
                                                state.userRoles[account.accountId] = e.target.value;
                                            }}> 
                                                <option>select...</option>
                                                {options.map((role) =>
                                                    <option value={role.key}>{role.display || ''}</option>)}
                                            </select>
                                        </div> 
                                    }
                                </td>
                                <td>
                                    <div class="add-remove-modal-button-container">
                                        {AddUserToAccountDialog.userActionButton(account, state)}
                                    </div>
                                </td>
                            </tr>;
                        })}
                    {<div class="empty-row">
                    </div>}
                </tbody>
            </table>
            <div class="add-user-btns">
                <ErrorBox error={userModel.addUserError} class="add-remove-error"/>
                <div class={'btn'} onclick={() => modal.close()}>
                        Done
                </div>
            </div>
        </div>;
    }
};
export default paginateComponent(AddUserToAccountDialog, accountModel.paginateOpts);
