import constants from 'util/data/constants';
import {query, GET_USER, MODIFY_ACCOUNT_STATUS, MODIFY_ACCOUNT_BRAND_NAME, LIST_ACCOUNT_TOOLBOXES, SEARCH_ACCOUNT_TOOLBOXES} from 'util/api/queries';
import {authManager} from '@procore/sdt-core-js';

// api v2

const FETCH_URL = `${constants.webAppUrl}/api/v2/v2.0`;

const getHeaders = () => {
    const token = authManager.getToken();
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    return headers;
};

const send = (_query) => {
    const headers = getHeaders();
    return fetch(FETCH_URL, {
        method: 'post',
        headers,
        body: JSON.stringify(_query)
    }).then((response) => {
        return response.text();
    }).then((responseBody) => {
        try {
            return JSON.parse(responseBody);
        } catch (error) {
            return responseBody;
        }
    });
};

const uploadMedia = ({signedUrl, file}) => {
    const init = {
        method: 'PUT',
        headers: {
            'Content-Type': file.type
        },
        body: file
    };
    return fetch(signedUrl, init)
        .then((response) => {
            return response.text();
        }).then((responseBody) => {
            try {
                return JSON.parse(responseBody);
            } catch (e) {
                if (responseBody.errors) {
                    return e;
                }
                return responseBody;
                
            }
        });
};

const api = {
    getUser: (userId) => send(query(GET_USER, {userId})),
    modifyAccountBrandName: (accountId, newBrandName) => send(query(MODIFY_ACCOUNT_BRAND_NAME, {accountId, newBrandName})),
    modifyAccountStatus: (accountId, newStatus) => send(query(MODIFY_ACCOUNT_STATUS, {accountId, newStatus})),
    listAccountToolboxes: (afterId, accountId, order) => send(query(LIST_ACCOUNT_TOOLBOXES, null, [afterId, accountId, order])),
    searchAccountToolboxes: (afterId, accountId, searchTerm) => send(query(SEARCH_ACCOUNT_TOOLBOXES, null, [afterId, accountId, searchTerm])),
    uploadMedia
};

export default api;
