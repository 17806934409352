import projectModel from 'models/project-model';
import accountModel from 'models/account-model';

const ProjectToolboxes = {
    oninit: () => projectModel.fetchToolboxes(),
    view: () =>
        <table>
            <thead>
                <td>Name</td>
                <td>ID</td>
                <td>Added</td>
                <td>Type</td>
                <td></td>
            </thead>
            <tbody>
                {projectModel.loading ?
                    <div class ="empty-row">
                        <div class="no-users">
                            <div class="spinner spinning teal account-users"></div>
                        </div> 
                    </div>
                    :
                    projectModel.toolboxes.map(toolbox => {
                        const isMeta = toolbox.group.groups.find(g =>
                            g.tools.find(t =>
                                t.featureTypes && t.featureTypes.find(ft =>
                                    ft.attributes && ft.attributes.interface === 'project'
                                )
                            )
                        );
                        return <tr key={toolbox.toolboxId}>
                            <td><a href={`#view=toolbox&accountId=${projectModel.project.accountId}&toolboxId=${toolbox.toolboxId}`} target="_blank" rel="noopener noreferrer">{toolbox.name}</a></td>
                            <td>{toolbox.toolboxId}</td>
                            <td>{new Date(toolbox.group.createdDateTime).toLocaleString()}</td>
                            <td>{isMeta ? 'Meta' : 'Normal'}</td>
                            <td>
                                <div class="btn btn-small btn-secondary" onclick={() => accountModel.cloneToolbox(toolbox)}>Clone</div>
                            </td>
                        </tr>;
                    })}
            </tbody>
        </table>
};

export default ProjectToolboxes;
