import React from 'react';

// This code is for dynamiclly rendering the row component
const RowThree = ({cellValues}) => {
    return (
        <>
            {cellValues.map((el, i) => {
                return <td key={i}>{el}</td>;
            })}
        </>
    );
};

const RowElement = (props) => React.createElement(RowThree, props, null);

export default RowElement;
