
const ToolboxSearchBox = {
    view: ({attrs: {model}}) => <div class="search-toolboxes">
        <input type="text" value={model.toolboxSearchTerm} onkeydown={(e) => {
            if (e.key === 'Enter' && model.toolboxSearchTerm) {
                e.preventDefault();
                e.stopPropagation();
                model.searchToolboxes();
            }
        }} 
        oninput={(e) => {
            model.toolboxSearchTerm = e.target.value;
        }} placeholder="Search toolboxes..." />
        <span class="btn btn-small btn-primary btn-go" onclick={() => model.searchToolboxes()}>Go</span>
        {model.toolboxSearchTerm ? <span class="btn btn-small btn-pill btn-secondary btn-clear" onclick={() => model.listToolboxes()}>Clear</span> : ''}
    </div>
};

export default ToolboxSearchBox;
