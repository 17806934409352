import React, { useState } from 'react';
import accountModel from 'models/account-model';
import { COLUMN_ORDER_ICON_ENUM } from 'util/data/constants';

const ToolboxTableHeader = () => {
    const {toolboxPicker} = accountModel;

    const [addDateOrder, setAddDateOrder] = useState(1);
    const [addDateOrderIcon, setAddDateOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[1]);
    const [updateDateOrder, setUpdateDateOrder] = useState(0);
    const [updateDateOrderIcon, setUpdateDateOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[0]);
    const [toolboxNameOrder, setToolboxNameOrder] = useState(0);
    const [toolboxNameOrderIcon, setToolboxNameOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[0]);

    const handleToolboxNameOrderClick = () => {
        if (toolboxNameOrder === 0) {
            setToolboxNameOrder(1);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            toolboxPicker.sortToolbox('name', 0);

        } else if (toolboxNameOrder === 1) {
            setToolboxNameOrder(2);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            toolboxPicker.sortToolbox('name', 1);
           
        } else if (toolboxNameOrder === 2) {
            setToolboxNameOrder(0);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            
            toolboxPicker.sortToolbox('name', 2); 
            
        }
        setAddDateOrder(0);
        setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
        setUpdateDateOrder(0);
        setUpdateDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);    
    };
    
    const handleAddDateOrderClick = () => {
        if (addDateOrder === 1) {
            setAddDateOrder(2);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            toolboxPicker.sortToolbox('createdDateTime', 1);
         
        } else if (addDateOrder === 2) {
            setAddDateOrder(0);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            toolboxPicker.sortToolbox('createdDateTime', 2);
            
        } else if (addDateOrder === 0) {
            setAddDateOrder(1);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            toolboxPicker.sortToolbox('createdDateTime', 0);
        }
        setToolboxNameOrder(0);
        setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
        setUpdateDateOrder(0);
        setUpdateDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);     
    };

    const handleUpdateDateOrderClick = () => {
        if (updateDateOrder === 1) {
            setUpdateDateOrder(2);
            setUpdateDateOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            toolboxPicker.sortToolbox('updatedDateTime', 1);
            
        } else if (updateDateOrder === 2) {
            setUpdateDateOrder(0);
            setUpdateDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            toolboxPicker.sortToolbox('updatedDateTime', 2);
            
        } else if (updateDateOrder === 0) {
            setUpdateDateOrder(1);
            setUpdateDateOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            toolboxPicker.sortToolbox('createdDateTime', 0);  
        }
        setToolboxNameOrder(0);
        setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
        setAddDateOrder(0);
        setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
    };

    return (
        <> 
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Name
                    </div>
                    <button className="order-button" onClick={handleToolboxNameOrderClick}>
                        {toolboxNameOrderIcon}
                    </button>
                </div> 
            </td>
            <td>ID</td>
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Added
                    </div>

                    <button className="order-button" onClick={handleAddDateOrderClick}>
                        {addDateOrderIcon}
                    </button>
                </div>
            </td> 

            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Updated
                    </div>

                    <button className="order-button" onClick={handleUpdateDateOrderClick}>
                        {updateDateOrderIcon}
                    </button>
                </div>
            </td> 
            <td>Type</td>   
        </>
    );
};
 
const ToolboxTableHeaderElement = (props) => React.createElement(ToolboxTableHeader, props, null);

export default ToolboxTableHeaderElement;
