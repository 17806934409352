import router from 'uav-router';
import modal from 'views/modal';
import list from 'util/data/list';
import paginate from 'util/data/paginate';
import {initDatadog, addRumUserContext} from 'util/tracking/datadog';
import projectModel from 'models/project-model';
import accountModel from 'models/account-model';


class UserModel {
    constructor() {
        this.reset();
        this.user = {};
        this.paginateOpts = {
            type: 'user',
            dataModel: this
        };
        this.userProjectSettings = {};
    }

    reset() {
        this.user = {};
        this.accounts = [];
        this.projects = [];
        this.userProjectSettings = {};
    }

    fetchUser() {
        this.accountLoading = true;
        this.loading = true;
        if (this.user && this.user.userId === router.params.userId) {
            return Promise.resolve();
        }
        this.reset();
        return UE.rpc([['listUsers', {
            userId: router.params.userId,
            limit: 1
        }]]).then(([[user]]) => {
            this.user = user;
            this.loading = false;
            m.redraw();
        });
    }

    fetchAccounts() {
        if (this.accounts.length !== 0) {
            return Promise.resolve();
        }
        return UE.rpc([['listAccounts', {
            include: ['users'],
            order: 'createdDateTime desc',
            filters: [{accountIdIn: list(this.user.accountIds)}]
        }]]).then(([accounts]) => {
            const filteredAccounts = accounts.filter(account => list(account.users).find(user => user.userId === this.user.userId));
            this.accounts = filteredAccounts;
            m.redraw();
            this.accountLoading = false;
        });
    }


    fetchProjects() {
        this.loading = true;
        m.redraw();
        if (this.projects.length !== 0) {
            return Promise.resolve();
        }
        
        const accountIds = this.accounts.map(account => account.accountId);
        this.paginateUserProjectsForAccounts(accountIds);
    }

    addSettingsToProjectInfo(project) {
        return UE.rpc([['listProjectViews', {creatorId: this.user.userId, projectId: project.projectId, isVisible: {ne: null}}],
            ['listToolStyles', {creatorId: this.user.userId, projectId: project.projectId, isVisible: {ne: null}}]]).then(results => {
            this.userProjectSettings[project.projectId] = {
                projectViews: results[0],
                toolStyles: results[1]
            };
            m.redraw();
        });


    }

    paginateUserProjectsForAccounts(accountIds) {
        const handleResultsPage = (newPage) => {
            this.loading = false;
            this.projects.push(...newPage);
            newPage.forEach(project => this.addSettingsToProjectInfo(project));
            m.redraw();
        };
        const opts = {
            type: 'project',
            limit: 50,
            data: [],
            onResults: handleResultsPage,
            filter: {accountIdIn: accountIds, usersContains: this.user.userId}
        };
        return paginate(opts);
    }

    authenticate(user) {
        return new Promise(resolve => {
            if (user.isSuperAdmin) {
                this.user = user;
                this.trackUser(user);
                resolve(user);
            } else {
                UE.logout();
            }
        });
    }

    updateUser(state) {
        this.loading = true;
        const {userId} = this.user;
        const {status, givenName, familyName, company} = state;
        UE.rpc([['modifyUser', {
            userId,
            status,
            givenName,
            familyName,
            company
        }]]).then(([userInfo]) => {
            this.user.status = userInfo.status;
            this.user.givenName = userInfo.givenName;
            this.user.familyName = userInfo.familyName;
            this.user.company = userInfo.company;
            modal.close();
        }).catch(() => {
            this.showError();
        }).finally(() => {
            this.loading = false;
            m.redraw();
        });
    }

    trackUser(user) {
        initDatadog();
        addRumUserContext(user);
    }

    resetPassword() {
        UE.socket.send({
            type: 'resetpassword',
            payload: {
                userId: this.user.emailAddress
            }
        });
    }

    showError() {
        this.modifyUserError = {
            message: 'Error',
            details: "There was a problem updating this user's information. Please try again."
        };
    }


    updateUserAccountRole(accountId, role, processing) {
        const {userId} = this.user;
        processing[accountId] = true;
        m.redraw();
        UE.rpc([['modifyAccountUser', {
            accountId,
            userId,
            role
        }]]).then(([userAccountPayload]) => {
            const currentAccount = this.accounts.find(account => account.accountId === userAccountPayload.accountId);
            const accountUsers = list(currentAccount.users);
            const currentUser = accountUsers.find(user => user.userId === userAccountPayload.userId);
            currentUser.role = userAccountPayload.role;
            processing[accountId] = false;
            m.redraw();
        }).catch(() => {
            this.modifyUserAccountError = {
                message: 'Error',
                details: 'There was a problem updating the users role. Please try again.'
            };
            processing[accountId] = false;
            m.redraw();
        });
    }

    removeProjectFromUser(projectId) {
        this.removeLoading = true;
        UE.rpc([['removeProjectUser', {
            projectId,
            userId: this.user.userId
        }]]).then(([modifiedProject]) => {
            const projectIds = this.projects.map((project) => project.projectId);
            const currentProjectId = modifiedProject.projectId;
            if (projectIds.includes(currentProjectId)) {
                const projectsList = this.projects;
                const index = projectsList.findIndex((project) => project.projectId === currentProjectId);
                if (index !== -1) {
                    projectsList.splice(index, 1);
                    modal.close();
                }
            }
        }).catch(() => {
            this.removeFromProjectError = {
                message: 'Error',
                details: 'There was a problem removing this project. Please try again.'
            };
        }).finally(() => {
            this.removeLoading = false;
            m.redraw();
        });
    }


    removeAccountFromUser(accountId) {
        this.loadingRemove = true;
        UE.rpc([['removeAccountUser', {
            accountId,
            userId: this.user.userId
        }]]).then(([modifiedAccount]) => {
            const selectedAccount = this.accounts.find(account => account.accountId === modifiedAccount.accountId);
            if (accountId === selectedAccount.accountId) {
                const accounts = this.accounts;
                const index = accounts.findIndex((account) => account.accountId === accountId);
                if (index !== -1) {
                    accounts.splice(index, 1);
                }
            }
            modal.close();
        }).catch(() => {
            this.removeUserError = {
                message: 'Error',
                details: 'There was a problem removing this account. Please try again.'
            };
        }).finally(() => {
            this.loadingRemove = false;
            m.redraw();
        });
    }

    addUserToAccount(account, state) {
        const {accountId} = account;
        state.processing[accountId] = true;
        UE.rpc([['addAccountUser', {
            accountId,
            userId: this.user.userId,
            role: state.userRoles[accountId]
        }
        ]]).then(([accountUser]) => {
            const userId = accountUser.userId;
            const selectedAccount = accountModel.data.find((a) => a.accountId === accountId);
            selectedAccount.users.push(accountUser);
            if (userId === this.user.userId) {
                this.accounts.push(selectedAccount);
                state.accountsMap[accountId] = accountUser;
            }
        }).catch(() => {
            this.addUserToAccountError = {
                message: 'Error',
                details: 'There was a problem adding this user to the account. Please try again.'
            };
        }).finally(() => {
            delete state.processing[accountId];
            m.redraw();
        });
    }

    addExistingUserFromUserView(project, accountId, state) {
        const {projectId} = project;
        const userId = this.user.userId;
        const associatedAccounts = list(this.user.accountIds);
        const metaProjectId = project.accountAttributes.metaProjectId;
        const selectedMetaProject = projectModel.data.find((p) => p.projectId === metaProjectId);
        if (!associatedAccounts.includes(accountId)) {
            return accountModel.addUserToAccountIfNotOnProject(accountId, userId, state, projectId).then(() => {
                //adds user to meta project automatically if general project is selected 
                if (projectId !== project.accountAttributes.metaProjectId) {
                    if (selectedMetaProject) {
                        this.projects.push(selectedMetaProject);
                        state.projectsMap[metaProjectId] = selectedMetaProject;
                    }
                    this.addUserToProjectFromUserView(projectId, state);
                } else {
                    this.projects.push(selectedMetaProject);
                    state.projectsMap[metaProjectId] = selectedMetaProject;
                }
            });
        } 
        return this.addUserToProjectFromUserView(projectId, state); 
    }

    addUserToProjectFromUserView(projectId, state ) {
        state.processing[projectId] = true;
        UE.rpc([['addProjectUser', {
            projectId,
            userId: this.user.userId,
            role: 'general'
        }
        ]]).then(([project]) => {
            const userId = this.user.userId;
            const projectUsers = list(project.users);
            const selectedUser = projectUsers.find((user) => user.userId === userId);
            if (selectedUser.userId === this.user.userId) {
                this.projects.push(project);
                state.projectsMap[project.projectId] = project;
            }
        }).catch(() => {
            this.addUserToProjecError = {
                message: 'Error',
                details: 'There was a problem adding this user to the project. Please try again.'
            };
        }).finally(() => {
            state.processing[projectId] = false;
            m.redraw();
        });
    }

    userAccountSearch(query) {
        query = query.toLowerCase().trim();
        if (query === '') {
            return this.accounts;
        }
        const splitTerms = query.split(/\s+/g);
        const doesMatch = (str, term) => str && str.toLowerCase().startsWith(term);
        const userRelevance = [];
        this.accounts.forEach(account => {
            const relevance = splitTerms.filter(term =>
                doesMatch(account.name, term)
                || doesMatch(account.accountId, term)
            ).length;
            if (relevance) {
                userRelevance[relevance] = userRelevance[relevance] || [];
                userRelevance[relevance].push(account);
            }
        });
        return userRelevance.reverse().flat();
    }
    
}

export default new UserModel();
