/* eslint-disable object-curly-spacing */
import Uploader from 'views/uploader';
import api from 'util/api/api';
import userModel from './user-model';

const config = {
    uploadConfig: {
        intelligent: false,
        timeout: 480000
    }
};

const kilobytesPerMillisecond = 1.75,
    millisecondsPerInterval = 250;

// eslint-disable-next-line no-unused-vars
let counter = 0;
class UploadModel {
    constructor() {
        this.files = [];
        this.canceller = {};
        this.newMediaRecords = {};
    }

    cancel() {
        if (this.modal) {
            m.mount(this.modal, null);
            this.modal.remove();
        }

        this.canceller.cancel && this.canceller.cancel();
    }

    resizeImage(mediaId, done, aspectRatio) {
        fetch('https://static.unearthlabs.com/' + mediaId)
            .then(response => response.blob())
            .then((responseBlob) => {

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
            
                canvas.width = 256;
                canvas.height = 256 / aspectRatio;
            
                const img = new Image();
                
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);


                    canvas.toBlob(blob => {
                        blob.name = mediaId;

                        this.upload([blob]).then(([result]) => done([result.mediaId]));

                    }, 'image/png', 1);
                    
                };
                
                img.src = URL.createObjectURL(responseBlob);
            });
    
    }

    upload(files, maxWidth) {
        counter++;
        this.files = files;
        this.modal = document.createElement('div');
        document.body.appendChild(this.modal);
        m.mount(this.modal, Uploader);

        const mediaRecords = [];

        return (
            maxWidth
                ? Promise.all(files.map((file, i) => new Promise((resolve, reject) => {
                    const img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.onerror = reject;
                    img.onload = () => {
                        if (img.width > maxWidth) {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            const aspectRatio = img.width / img.height;

                            canvas.width = maxWidth;
                            canvas.height = maxWidth / aspectRatio;

                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                            canvas.toBlob(blob => {
                                blob.name = file.name;
                                files[i] = blob;
                                resolve();
                            }, 'image/png', 1);
                        } else {
                            resolve();
                        }
                    };
                    const reader = new FileReader();
                    reader.onload = e => {
                        img.src = e.target.result;
                    };
                    reader.readAsDataURL(file);

                }))) 
                : Promise.resolve()
        )
            .then(() => new Promise(_resolve => {
                if (!files.length) {
                    return _resolve(mediaRecords);
                }

                const resolve = () => {
                    m.mount(this.modal, null);
                    this.modal.remove();
                    _resolve(mediaRecords);
                };

                files.forEach(file => {
                    const { name: label, type: mimeType } = file;
                    const estimatedMilliseconds = Math.max(500, file.size * 0.75 / 1000 / kilobytesPerMillisecond);
                    let millisecondsElapsed = 0,
                        percentComplete = 0,
                        interval;

                    const setProgressBarLength = (percent) => {
                        file.percent = Math.max(percent, 0);
                        if (percent === -1) {
                            file.failed = true;
                        }
                        m.redraw();
                    };

                    const updateProgress = () => {
                        millisecondsElapsed += millisecondsPerInterval;
                        percentComplete = millisecondsElapsed / estimatedMilliseconds * 50;
                        if (percentComplete > 45) {
                            clearInterval(interval);
                        } else {
                            setProgressBarLength(percentComplete + 50, file);
                        }
                    };

                    config.uploadConfig.onProgress = info => setProgressBarLength(info.totalPercent / 2);

                    const fail = () => {
                        setProgressBarLength(-1, file);
                        clearInterval(interval);
                        setTimeout(() => resolve(), 4000);
                    };

                    // add listener for any new media publish and store it for later.
                    // We have to do this because the createSignedUrl response might come back after the media record publishes, 
                    // but without the signedUrl response we don't know the new mediaId. 
                    // (Specifically an issue with static media uploads that aren't processed the same way)
                    const newMediaPublish = UE.on('new', 'media')
                        .if(updatedMedia => updatedMedia.providerId === userModel.user.userId && updatedMedia.status === 'active')
                        .then(mediaRecord => {
                            this.newMediaRecords[mediaRecord.mediaId] = mediaRecord;
                        });


                    // create a new signed url with the file name and mime type.
                    UE.rpc([['createSignedUrl', {
                        projectId: null,
                        isStatic: true,
                        label,
                        mimeType
                    }]]).then(([createResponse]) => {
                        // new signedUrl is ready to be used. POST a file to the url.
                        const { signedUrl, mediaId } = createResponse;
                        updateProgress();
                        interval = setInterval(updateProgress, millisecondsPerInterval);

                        
                        // add listeners for updated (POST) signedUrl. One for success and one for failed.
                        const successfulUpdate = UE.once('new', 'media')
                            .if(updatedMedia => updatedMedia.mediaId === mediaId && updatedMedia.status === 'active')
                            .then(mediaRecord => handleSuccess(mediaRecord));
                        
                        
                        const failedUpdate = UE.once('modified', 'media')
                            .if(updatedMedia => updatedMedia.mediaId === mediaId && updatedMedia.status === 'failed')
                            .then(() => {
                                successfulUpdate.abort();
                                newMediaPublish.abort();
                                fail();
                            });
                        
                        const handleSuccess = (newMediaRecord) => {
                            failedUpdate.abort();
                            newMediaPublish.abort();
                            setProgressBarLength(200, file);
                            mediaRecords.push(newMediaRecord);
                            if (mediaRecords.length >= files.length) {
                                // Short timeout helps ensure image will load (this is a lazy workaround rather than refactoring to include retries)
                                setTimeout(() => resolve(), 300); 
                            }
                            delete this.newMediaRecords[newMediaRecord.mediaId];
                            clearInterval(interval);
                        };
                         
                        if (this.newMediaRecords[mediaId]) {
                            handleSuccess(this.newMediaRecords[mediaId]);
                        }

                        // post file to signed url and listeners above will handle success/failed uploads
                        api.uploadMedia({ signedUrl, file })
                            .catch(fail);
                    }).catch(fail);
                });
            }));
    }

    pickFiles(opts) {
        return new Promise(resolve => {
            const {accept, multiple, capture} = opts;
            const input = document.createElement('input');
            input.setAttribute('type', 'file');

            if (accept) {
                input.setAttribute('accept', Array.isArray(accept) ? accept.join(',') : accept);
            }

            if (multiple) {
                input.setAttribute('multiple', 'true');
            }

            if (capture) {
                input.setAttribute('capture', capture);
            }

            input.style.display = 'none';
            document.body.appendChild(input);
            input.onchange = e => {
                input.remove();
                resolve(Array.from(e.target.files));
            };
            input.click();
        });
    }
}

export default new UploadModel();
