export default (fn, delay = 250) => {

    let timeout;

    return (...args) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => fn.apply(fn, args), delay);

    };

};
