import modal from 'views/modal/modal';
import projectModel from 'models/project-model';
import ErrorBox from 'views/error-box/error-box';

const UpdateProjectNameDialog = {
    oninit: ({state}) => {
        state.name = projectModel.project.name;

    }, 
    onremove: () => delete projectModel.modifyAccountError,
    view: ({state}) => {
        return <div class="create-account">
            <ErrorBox error={projectModel.modifyProjectError}/>
            <h3 class="account-modal-header">Change Project Name</h3>
            <div >
                <fieldset>
                    <input value={state.name} type="text" required
                        oninput={(e) => {
                            state.name = e.target.value;
                        }}
                    />
                </fieldset>
                <div class="create-account-btn-wrap">
                    <button class="btn account-submit-button"
                        onclick ={() => {
                            projectModel.updateProject(state);
                        }}
                    >
                        {projectModel.loading ? <div class="update-account-information-button spinner spinning"/> : 'Update'}
                    </button>
                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default UpdateProjectNameDialog;
