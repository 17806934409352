import constants from 'util/data/constants';

const ToolPreview = {
    view: ({attrs: {name, mediaId}}) => <div class="tool-preview">
        <img className="tool-icon" src={constants.mediaServerUrl + mediaId}/>
        <div class="tool-name-wrap">
            <span class="tool-name">{name}</span>
        </div>
    </div>
};

export default ToolPreview;
