import accountModel from 'models/account-model';
import Tabs from 'views/tabs';
import AccountProjects from 'views/account/account-projects';
import AccountUsers from 'views/account/account-users';
import AccountToolboxes from 'views/account/account-toolboxes';
import AddExistingUserDialog from 'views/account/add-existing-user-dialog';
import AddNewUserDialog from 'views/account/add-new-user-dialog';
import UpdateNameDialog from 'views/account/update-name-dialog';
import UpdateStatusDialog from 'views/account/update-status-dialog';
import UpdateBrandNameDialog from 'views/account/update-brand-name-dialog';
import ErrorBox from 'views/error-box/error-box';
import modal from 'views/modal/modal';
import {getAppVersion} from 'util/data/get-app-version';
import MreactComponent from 'util/dom/mreact-component';
import ButtonElement from 'views/react/Button';

const Account = {
    
    oninit: () => {
        accountModel.fetch(); 
    },        
    
    view: () => {
        const account = accountModel.account;
        account.attributes = account.attributes || {};
        return <div class="account" >
            <div class="page-title">Account Details</div>
            <div class="account-button-wrap horizontal-align">
                <ErrorBox error={accountModel.chargeBeeError}/>
                <MreactComponent element={ButtonElement({
                    handleButtonClick: () => accountModel.refreshChargebeeDetails(),
                    buttonText: 'Refresh Account Info',
                    buttonSize: 'small',
                    buttonColor: 'white',
                    isLoading: accountModel.processing
                })} />
                <MreactComponent element={ButtonElement({
                    handleButtonClick: () => modal(AddNewUserDialog),
                    buttonText: 'Add New User',
                    buttonSize: 'small',
                    buttonColor: 'default'
                })} />
                <MreactComponent element={ButtonElement({
                    handleButtonClick: () => modal(AddExistingUserDialog, {cssClass: 'add-user-modal'}),
                    buttonText: 'Add Existing User',
                    buttonSize: 'small',
                    buttonColor: 'default'
                })} />
            </div>
            <table>
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Status</td>
                    <td>Usage (GB)</td>
                    <td>Seats</td>
                    <td>Version</td>
                    <td>Created</td>
                    <td>Brand</td>
                </thead>
                <tbody>
                    <td>{account.name}<button class="edit-button" onclick={() => modal(UpdateNameDialog)}><i class="icon-pencil"></i></button></td>
                    <td>{account.accountId}</td>
                    <td>{account.status}
                        <button class="edit-button" onclick={() => modal(UpdateStatusDialog)}><i class="icon-pencil"></i></button>
                    </td>
                    <td>{Math.round((account.usageMB || 0) / 1000)} / {account.storageQuotaGB || 0}</td>
                    <td>{account.seats}</td>
                    <td>{getAppVersion(account.attributes)}</td>
                    <td>{account.createdDateTime ? new Date(account.createdDateTime).toLocaleString() : null}</td>
                    <td>{account.brandName || '-'}<button class="edit-button" onclick={() => modal(UpdateBrandNameDialog)}><i class="icon-pencil"></i></button></td>
                </tbody>
            </table> 
            <Tabs tabs={[{
                name: 'Users',
                view: AccountUsers,
                selected: true
            }, {
                name: 'Projects',
                view: AccountProjects
            }, {
                name: 'Toolboxes',
                view: AccountToolboxes
            }]} />
        </div>;
    }
};

export default Account;
