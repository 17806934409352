import Collapsible from 'views/collapsible';
import ToolboxPickerModel from 'models/toolbox-picker-model';
import toolboxModel from 'models/toolbox-model';
import ToolboxSearchBox from 'views/toolbox-picker/toolbox-search-box';

const ToolGroup = {
    view: ({state, attrs: {model, select, onSelect, toolbox, toolGroup}}) => 
        <div class="toolbox-tools">{toolGroup.tools.map(tool => 
            tool.name === 'Comment' ? null : <div class="clear picker-row">{
                select === 'tool' 
                    ? <div class={`btn btn-xsmall toolbox-picker-btn  ${state.isCopying === tool.id ? 'saving' : ''}`} onclick={async () => {
                        state.isCopying = tool.id;
                        return model.fetchTool(toolbox.id, tool.id).then(_tool => {
                            return onSelect(toolboxModel.cloneTool(_tool));
                        });
                    }}>Select<i class="spinner"/></div> 
                    : null}
            <div class="toolbox-picker-tool">
                {/* TODO Need to get icon from api v2 now */}
                {/* <img className="tool-icon" src={'https://static.unearthlabs.com/' + tool.assetForm.assetType.attributes.icon.mediaId}/> */}
                <div class="tool-name-wrap"><span class="tool-name">{tool.name}</span></div>
            </div>
            </div>
        )}</div>
};

const Toolbox = {
    view: ({state, attrs: {model, select, onSelect, toolbox}}) => 
        <div>{toolbox.categories.map(_toolGroup => 
            <Collapsible 
                display={
                    <div>{
                        select === 'toolgroup' 
                            ? <div class={`btn btn-xsmall toolbox-picker-btn ${state.isCopying === _toolGroup.id ? 'saving' : ''}`} onclick={async () => {
                                state.isCopying = _toolGroup.id;
                                const toolGroupToClone = await model.fetchToolGroup(toolbox.id, _toolGroup.id);
                                onSelect(toolboxModel.cloneToolGroup(toolGroupToClone));
                            }}>Select<i class="spinner"/></div> 
                            : null}
                    <div class="toolbox-picker-opt toolbox-group-name">{_toolGroup.name}</div>
                    </div>
                }
                view={<ToolGroup model={model} toolGroup={_toolGroup} toolbox={toolbox} select={select} onSelect={onSelect} />} />
        )}</div>
};

const ToolboxPicker = {
    oninit({state}) {
        state.model = new ToolboxPickerModel({noAccountFilter: true});
        state.model.fetchToolboxes(true);
    },
    view: ({state: {model}, attrs: {select, onSelect}}) => {
        return <div class="toolbox-picker">
            <ToolboxSearchBox model={model}/>
            {model.toolboxes.length
                ? model.toolboxes.map(toolbox => 
                    <Collapsible 
                        display={
                            <div>{
                                select === 'toolbox' 
                                    ? <div class="btn btn-xsmall toolbox-picker-btn" onclick={() => onSelect(toolbox)}>Select Toolbox</div> 
                                    : null}
                            <div class="toolbox-picker-opt">{toolbox.name}</div>
                            </div>}
                        view={<Toolbox model={model} select={select} onSelect={onSelect} toolbox={toolbox} />} />) 
                : <div class="spinner spinning gray" />
            }
            {!model.fetchedAll && !model.isFetchingToolboxes ? <div class="toolbox-list-footer"> {model.isFetchingPage ? <i class="spinner spinning teal"/> : <span class="btn btn-primary btn-small" onclick={() => model.getNextPage()}>Load next page</span>}</div> : ''}
        </div>;
    }
};

export default ToolboxPicker;
