import modal from 'views/modal/modal';
import accountModel from 'models/account-model';
import ErrorBox from 'views/error-box/error-box';

const UpdateStatusDialog = {
    oninit: ({state}) => {
        state.status = accountModel.account.status;
    },

    onremove: () => delete accountModel.modifyAccountError,

    view: ({state}) => {
        return <div class="create-account">
            <ErrorBox error={accountModel.modifyAccountError}/>
            <h3 class="account-modal-header">Change Status</h3>
            <div>
                <fieldset>
                    <div class="select-wrapper">
                        <select value={state.status}
                            onchange={(e) => {
                                state.status = e.target.value;
                            }}
                        >
                            <option>trial</option>
                            <option>active</option>
                            <option>expired</option>
                        </select>
                    </div>
                </fieldset>
                <div class="create-account-btn-wrap">
                    <button class="btn account-submit-button" 
                        onclick={() => {
                            accountModel.updateAccountStatus(state);
                        }}
                    >
                        {accountModel.loading ? <div class="update-account-information-button spinner spinning"/> : 'Update'}
                    </button>
                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default UpdateStatusDialog;
