import search from 'util/data/search';
import router from 'uav-router';
import modal from 'views/modal';

class UsersModel {
    
    fetch() {
        router.url.merge({view: 'users'});
        this.users = [];
        this.search = search('user', this.users);
    }

    reset() {
        this.users = [];
    }

    addNewUser(state) {
        const {
            givenName,
            familyName,
            phoneNumber,
            emailAddress,
            company
        } = state;

        delete state.createUserError;
        state.loading = true;
        return UE.rpc([['createUser',
            {userBody: {
                givenName,
                familyName,
                phoneNumber,
                emailAddress,
                company
            }}
        ]]).then(([user]) => {
            if (user.givenName !== givenName ||
                user.familyName !== familyName ||
                user.preferences !== undefined
            ) {
                state.createUserError = {
                    message: 'A user with this information already exists.',
                    details: 'Please try again.'
                };
                state.loading = false;
                m.redraw();
                throw new Error('User Exists');
            } 
            return user;
        }).then((user) => {
            this.users.unshift(user);
            m.redraw();
            modal.close();
        });
    }

    sortUsers(column, order) {
        if (column === 'name' && order === 1) { 
            this.search = search('user', this.users, {order: 'givenName asc'});
        } else if (column === 'name' && order === 0) { 
            this.search = search('user', this.users, {order: 'givenName desc'});
        } else if (column === 'name' && order === 2) {
            this.search = search('user', this.users, {order: 'createdDateTime desc'});
        } else if (column === 'createdDateTime' && order === 1) {
            this.search = search('user', this.users, {order: 'createdDateTime asc'});
        } else if (column === 'createdDateTime' && order === 2) {
            this.search = search('user', this.users, {order: 'createdDateTime desc'});
        } else if (column === 'createdDateTime' && order === 0) {
            this.search = search('user', this.users, {order: 'createdDateTime desc'});
        }

        m.redraw();
    }

}

export default new UsersModel();
