import router from 'uav-router';
import search from 'util/data/search';
import modal from 'views/modal/modal';
import CreateAccountDialog from 'views/accounts/create-account-dialog';

class AccountsModel {

    fetch() {

        router.url.merge({view: 'accounts'});

        this.accounts = [];

        this.search = search('account', this.accounts);

    }

    openCreateAccount() {
        modal(CreateAccountDialog);
        delete this.createAccountError;
        this.loading = false;
    }

    createUser(state) {
        const {givenName, familyName, phoneNumber, emailAddress, toolboxId, accountName} = state;
        this.loading = true;
        delete this.createAccountError;
        UE.rpc([['createUser',
            {userBody: {
                givenName,
                familyName,
                phoneNumber,
                accountName,
                emailAddress,
                baseToolboxId: toolboxId
            }}
        ]]).then(([user]) => {
            this.loading = false;
            if (user.givenName !== givenName ||
                user.familyName !== familyName ||
                user.emailAddress !== emailAddress ||
                user.preferences !== undefined
            ) {
                this.createAccountError = {
                    message: 'A user with this information already exists.',
                    details: `User: ${JSON.stringify(user, '', 4)}`};
                m.redraw();
                return;
            }
            const accountIds = user.accountIds.items;
            router.merge({view: 'account', accountId: accountIds[0]});
            modal.close();
        }).catch(e => {
            this.loading = false;
            this.createAccountError = {
                message: 'Failed to create user and account.',
                details: `User: ${JSON.stringify(e, '', 4)}`};
            m.redraw();
        });
    }

    sortAccounts(column, order) {
        if (column === 'name' && order === 1) { 
            this.search = search('account', this.accounts, {order: 'name asc'});
        } else if (column === 'name' && order === 0) { 
            this.search = search('account', this.accounts, {order: 'name desc'});
        } else if (column === 'name' && order === 2) {
            this.search = search('account', this.accounts, {order: 'createdDateTime desc'});
        } else if (column === 'createdDateTime' && order === 1) {
            this.search = search('account', this.accounts, {order: 'createdDateTime asc'});
        } else if (column === 'createdDateTime' && order === 2) {
            this.search = search('account', this.accounts, {order: 'createdDateTime desc'});
        } else if (column === 'createdDateTime' && order === 0) {
            this.search = search('account', this.accounts, {order: 'createdDateTime desc'});
        }

        m.redraw();
    }

}

export default new AccountsModel();
