function closestNumber(target, numbers) {

    let minDifference = Infinity,
        closest;

    numbers.forEach(number => {

        const difference = Math.abs(number - target);

        if (difference < minDifference) {

            minDifference = difference;

            closest = number;

        }

    });

    return closest;

}

export default closestNumber;
