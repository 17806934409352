import AddButton from 'views/add-button';
import Control from 'views/toolbox/control';
import EmbedControl from 'views/embed-control/embed-control';
import constants from 'util/data/constants';
import toolboxModel from 'models/toolbox-model';
import sortableModel from 'models/sortable-model';
import collapsibleModel from 'models/collapsible-model';
import SortingDropZone from 'views/toolbox/sorting-drop-zone';

const ZONE_0 = -1;

const Property = {

    view: ({attrs: {tool, control, i}}) => {
        const assetType = tool.assetForm.assetType,
            controls = tool.assetForm.controls,
            featureType = tool.featureTypes[0];

        if (toolboxModel.isEmbedControl(control.controlTypeId)) {
            return <>
                <div class="form-embed-control editable-when-published collapsible-name">
                    <div>
                        <label>Property Label</label> 
                        <input type="text" value={control.label} oninput={e => {
                            toolboxModel.changeEmbedControlLabel(e.target.value, control);
                        }} onblur={e => toolboxModel.changeEmbedControlLabel(e.target.value.trim(), control)} /> 

                        <i class="icon-trash" onclick={() => {
                            controls.splice(i, 1);
                            toolboxModel.removeEmbedControl(control, tool);
                        }}/>
                        <i class="icon-up collapsible-icon right" onclick={() => collapsibleModel.handleClick(tool.toolId + i)}/>
                    </div>
                </div>
                {collapsibleModel.isCollapsed(tool.toolId + i) ? <></> : <div class="form-embed-control editable-when-published collapsible-items">
                    <EmbedControl control={control} />
                </div>}
            </>;
        }
        const fieldIndex = toolboxModel.findFieldIndex(control, i, assetType.fields),
            field = assetType.fields[fieldIndex] || {};
        const title = control.controlTypeId === constants.controlTypeNameToId.project ?
            <div class="project-control-label" title="Project controls don't use labels">Project Control</div> :
            <input type="text" value={control.label} oninput={e => {
                toolboxModel.changeControlLabel(e.target.value, control, field, featureType);
            }} onblur={e => toolboxModel.changeControlLabel(e.target.value.trim(), control, field, featureType)} />;

        return <div id={control.fieldName + i}>
            <div class="collapsible-name">
                <div>
                    <label>Property Label</label>
                    {title}
                    {<i class="icon-trash" onclick={() => {
                        controls.splice(i, 1);
                        assetType.fields.splice(fieldIndex, 1);
                        toolboxModel.removeControl('linked', featureType, control.fieldName);
                        toolboxModel.removeControl('styled', featureType, control.fieldName);
                        toolboxModel.removeFromSharedColumns(control, tool.toolId);
                    }} />}
                </div>
                <i class="icon-up collapsible-icon right" onclick={() => collapsibleModel.handleClick(tool.toolId + i)}/>
            </div>
            {collapsibleModel.isCollapsed(tool.toolId + i) ? <></> : <div class="collapsible-items">
                <div><Control control={control} tool={tool} field={field}/></div>
            </div> }
        </div>;
    }
};

const ToolProperties = {

    view: ({attrs: {tool}}) =>  {
        const controls = tool.assetForm.controls;
        let firstIndex = 0;
        return <div class="data-type-section data-type-properties">
            <div class="section-header">Properties</div>
            <div class="reordering-properties-tip">
                    To reorder:
                <ol>
                    <li>Tap to select a Property.</li>
                    <li>Hover and tap on an available drop zone.</li>
                    <li>Tap Done when order is set.</li>
                </ol>
            </div>
            <div onclick={() => sortableModel.togglePropertiesReorderingMode(tool.toolId, controls)} class={sortableModel.isReorderingProperties(tool.toolId) ? 'active reorder-button' : 'reorder-button'}>{sortableModel.isReorderingProperties(tool.toolId) ? 'Done' : 'Reorder'}</div>
            <div>
                {controls.map((control, i) => {
                    const doNotRender = toolboxModel.isCommentsOrLinks[control.controlTypeId];
                    if (doNotRender) {
                        firstIndex++;
                        return <div class="hidden" />;
                    }
                    return <div class={`card property-card collapsible clear${toolboxModel.isSavedControl(tool, control) ? ' published-control' : ''}${collapsibleModel.isCollapsed(tool.toolId + i) ? ' collapsed' : ''}${sortableModel.isReorderingProperties(tool.toolId) && sortableModel.isReorderingItem(control.fieldName + i) ? ' is-reordering-item' : ''}`} onclick={sortableModel.isReorderingProperties(tool.toolId) ? (e) => sortableModel.handleClick(e, i, control.label + i) : null}>
                        {firstIndex === i ? <SortingDropZone index={ZONE_0} xid={control.fieldName + i} toolId={tool.toolId} cssWrapper={'first-zone'}/> : ''}
                        <Property control={control} tool={tool} i={i}/>
                        <SortingDropZone index={i} xid={control.fieldName + i} toolId={tool.toolId}/>
                    </div>;
                }) 
                }
            </div>
            <AddButton label="Add Property" onclick={() => toolboxModel.addControl(tool)} />
            <AddButton label="Add Form Element" onclick={() => toolboxModel.addFormEmbedControl(tool)} />
        </div>;
    }
};

export default ToolProperties;
