import anchorMenuModel from 'models/anchor-menu-model';
import constants from 'util/data/constants';
import sortableModel from 'models/sortable-model';
import SortingDropZone from 'views/toolbox/sorting-drop-zone';
import toolboxModel from 'models/toolbox-model';

const ZONE_0 = -1;

const noToolsInToolGroup = (toolGroup) => {
    const visibleTools =  toolGroup.tools.filter(t => !t.attributes || !t.attributes.hidden);
    return !toolGroup.tools || !visibleTools.length;
};

// Can add a message here if you want - "if no toolgroups exist"
const EmptyToolboxMessage = {
    view: () => <div></div>
};

const AnchorMenu = {
    oninit: ({state}) => {
        state.model = anchorMenuModel;
        state.isDismissed = false;
    },

    toggleDismissed: (state) => {
        state.isDismissed = !state.isDismissed;
    },

    view: ({state}) => {
        const dismissedClass = state.isDismissed ? 'dismissed' : 'menu-visible';
        const toolGroups = toolboxModel.toolbox.group.groups.filter(group => !group.attributes || !group.attributes.hidden);
        if (!toolGroups || !toolGroups.length) {
            return <EmptyToolboxMessage/>;
        }
        return <div class={`anchor-menu-layout-wrap ${dismissedClass}`}>
            <div onclick={() => AnchorMenu.toggleDismissed(state)} class="anchor-menu-tool-button dismiss-button">{state.isDismissed ? <div><i class="icon-arrow-right"/> <span class="preview-toggle-text">Show Toolbox Preview</span></div> : <div><i class="icon-arrow-left"/> <span class="preview-toggle-text">Hide Toolbox Preview</span></div> }</div>
            <div onclick={() => sortableModel.toggleReorderingMode()} class={`${sortableModel.isReordering ? 'active reorder-button' : 'reorder-button'} anchor-menu-tool-button`}>{sortableModel.isReordering ? 'Done' : 'Reorder'}</div>
            <div class="reordering-tip">
                To reorder:
                <ol>
                    <li>Tap a Toolgroup name or a Tool to select.</li>
                    <li>Hover and tap on an available drop zone.</li>
                    <li>Tap Done when order is set.</li>
                </ol>
                <p>Note that you cannot move a Tool from one Toolgroup to another.</p>
            </div>
            <div className="anchor-menu" onclick={state.isDismissed ? () => AnchorMenu.toggleDismissed(state) : null}>
                {toolGroups.map((toolGroup, i) =>
                    <>
                        {!noToolsInToolGroup(toolGroup) &&
                        <div id={`sortable-${toolGroup.toolGroupId}`} class={`anchor-toolgroup ${sortableModel.isReorderingItem(toolGroup.toolGroupId) ? 'reordering-item' : ''}`}>
                            {i === 0 ? <SortingDropZone index={ZONE_0} xid={toolGroups.length ? toolGroups[0].toolGroupId : undefined} cssWrapper={'first-zone'}/> : ''} <div 
                                class="anchor-item-toolgroup"
                                onclick={(e) => sortableModel.isReordering ? sortableModel.handleClick(e, i, toolGroup.toolGroupId, undefined) : anchorMenuModel.scrollToToolGroup(e, toolGroup.toolGroupId)}>
                                {toolGroup.name}
                            </div>
                            <div class="anchor-tools">
                                {toolGroup.tools.filter(t => !t.attributes || !t.attributes.hidden).map((tool, index) => {
                                    if (tool.attributes && tool.attributes.hidden) {
                                        return <></>;
                                    }
                                    return <>{index === 0 
                                        ? <SortingDropZone index={ZONE_0}  xid={toolGroup.tools.length ? toolGroup.tools[0].toolId : undefined} toolGroupId={toolGroup.toolGroupId}/>
                                        : ''} <div id={`sortable-${tool.toolId}`} 
                                        onclick={(e) => sortableModel.isReordering ? sortableModel.handleClick(e, index, tool.toolId, toolGroup.toolGroupId) : anchorMenuModel.scrollToTool(e, tool.toolId, toolGroup.toolGroupId)} 
                                        class={`anchor-item anchor-item-tool ${sortableModel.isReorderingItem(tool.toolId) ? 'reordering-item' : ''}`}>
                                        <img className="tool-icon" src={constants.mediaServerUrl + tool.assetForm.assetType.attributes.icon.mediaId}/><div class="tool-name-wrap"><span class="tool-name">{tool.name}</span></div>
                                        <SortingDropZone index={index}  xid={tool.toolId} toolGroupId={toolGroup.toolGroupId}/></div>  
                                    </>; 
                                }
                                )}
                            </div>
                            <SortingDropZone index={i} xid={toolGroup.toolGroupId}/>
                        </div>
                        }
                    </>
                )}
            </div> 
        </div>
        ;
    }
};

export default AnchorMenu;
