import toolboxModel from 'models/toolbox-model';
import Icon from 'views/toolbox/icon';
import styleConfigs from 'views/toolbox/style-configs/style-configs';
import modal from 'views/modal';

export const ToolboxId = {
    oninit({state, attrs: {toolboxId}}) {
        state.toolboxId = toolboxId;
    },
    view: ({state, attrs: {done}}) => <div class="media-id-modal">
        <div><label>Toolbox ID:</label></div>
        <p />
        <input type="text" placeholder="20 Characters" value={state.toolboxId || ''} oninput={e => {
            state.toolboxId = e.target.value;
            if (state.toolboxId.length >= 20) {
                done([state.toolboxId]);
                modal.close();
            }
        }} />
    </div>
};

const ToolRepresentation = {

    view: ({attrs: {tool}}) => {
        const featureType = tool.featureTypes[0],
            toolInterface = toolboxModel.getInterface(tool),
            StyleConfig = styleConfigs[toolInterface],
            icon = tool.assetForm.assetType.attributes.icon;

        return <div class="tool-meta data-type-section data-type-representation">
            <div class="section-header">Representation</div>
            <div class="card">
                <div class="card-section">
                    <label class="bold-label">Type Icon</label>
                    <Icon icon={icon} mediaId={icon.mediaId}/>
                </div>
                <div class="card-section">
                    <label class="bold-label">Geometry / Platform Concept</label>
                    <div class={`select-wrapper ${tool.updatedDateTime && !toolboxModel.isUnlocked ? 'disabled' : ''}`}>
                        <select value={toolInterface} onchange={e => toolboxModel.setToolInterface(tool, e.target.value)}>
                            <option value="action">Work Item</option>
                            <option value="fill">Fill</option>
                            <option value="boundary">Boundary</option>
                            <option value="polyline">Line</option>
                            <option value="symbol">Point</option>
                            <option value="text">Text</option>
                            <option value="filepicker">File</option>
                            <option value="plan">Plan</option>
                            <option value="survey">Survey</option>
                            <option value="project">Project</option>
                            <option value="imodel">iModel</option>
                        </select>
                    </div>
                </div>


                {featureType && featureType.attributes.interface === 'project' && <fieldset class="toolbox-preselection">
                    {!!featureType.attributes.toolbox && <div class="card-section"><label>Attached Toolbox ID:</label><div class="attached-toolbox-id">{featureType.attributes.toolbox}</div></div>}
                                    
                    <div class="clear">
                        <div class="tool-selector-project">
                            <div><label>Attach a Toolbox ID for projects created from this tool:</label></div>
                            <div class="left upload-btn">
                                <div class="left btn btn-xsmall" onclick={() => toolboxModel.selectToolbox(featureType)}>Select from list</div>
                                <div class="left btn btn-xsmall" onclick={() => toolboxModel.enterToolboxId(featureType)}>Paste toolboxId</div>
                            </div> 
                        </div>

                        {!!featureType.attributes.toolbox &&
                                            <div class="btn btn-xsmall btn-secondary left" onclick={() => toolboxModel.clearToolbox(featureType)}>Clear selection</div>}
                    </div>
                    <div class="tip-text">If no Toolbox is attached to this Project Type (i.e. Meta Tool), a Toolbox selector will appear in the Project creation flow.</div>
                </fieldset>}

                {featureType && StyleConfig
                    ? <StyleConfig tool={tool} />
                    : null}
            </div>
        </div>;
    }
};

export default ToolRepresentation;
