import constants from 'util/data/constants';
import toolboxModel from 'models/toolbox-model';
import AllOnAllOff from 'views/all-on-all-off/all-on-all-off';
import AssetTypeTreeModel from 'models/asset-type-tree-model';


const AssetTypeTree = {

    oninit: ({state, attrs: {excludeFiles}}) => {
        const allGroups =  toolboxModel.toolbox.group.groups;
        state.groups = excludeFiles ? allGroups.filter(group => group.name !== 'Files') : allGroups;
    },

    toolGroupDisplay: (toolGroup, control, onMap) => {

        let isAllChecked = true;
        let isAllUnchecked = true;

        const assetTypeRows = toolGroup.tools.map((tool) => {
            const assetType = tool.assetForm.assetType;
            if (assetType.name !== 'Comment') {
                const assetTypeId = assetType.assetTypeId;
                const mediaId = tool.assetForm.assetType.attributes.icon.mediaId;
                if (!onMap[assetTypeId]) {
                    isAllChecked = false;
                } else {
                    isAllUnchecked = false;
                }

                return <div class="tree-option-row">
                    <div class="filter-option" onclick={() => {
                        if (onMap[assetTypeId]) {
                            AssetTypeTreeModel.removeAssetTypeId(control, assetTypeId);
                        } else {
                            AssetTypeTreeModel.addAssetTypeId(control, assetTypeId);
                        }
                    }}>
                        <i class={onMap[assetTypeId] ? 'icon-checkbox-on' : 'icon-checkbox-off'}/>
                        <img class="thumbnail" src={constants.mediaServerUrl + mediaId}/>
                        <span class="filter-option-label">{tool.name}</span>
                    </div>
                </div>;
            }
            return <></>;
        });

        let checkCss;
        if (isAllUnchecked === false && isAllChecked === false) {
            checkCss = 'icon-checkbox-mixed';
        } else if (isAllChecked) {
            checkCss = 'icon-checkbox-on';
        } else  {
            checkCss = 'icon-checkbox-off';
        }

        return {
            display:
                <div class="tree-section">
                    <div onclick={() => {
                        if (isAllUnchecked || !isAllUnchecked && !isAllChecked) {
                            AssetTypeTreeModel.addGroup(control, toolGroup);
                        } else {
                            AssetTypeTreeModel.removeGroup(control, toolGroup);
                        }
                    }}>
                        <i class={checkCss}/>
                        <span class="asset-type-section-title">{toolGroup.name}</span>
                    </div>
                    {assetTypeRows}
                </div>,
            allOn: isAllChecked,
            allOff: isAllUnchecked
        };

    },

    view: ({state, attrs: {control}}) => {
        const groups = state.groups;
        const acceptedAssetTypeIds = control.attributes.acceptedAssetTypeIds || [];
        const onMap = {};
        let isAllOn = true;
        let isAllOff = true;

        acceptedAssetTypeIds.forEach((acceptedAssetTypeId) => {
            onMap[acceptedAssetTypeId] = true;
        });

        const groupDisplays = groups.map((toolGroup) => {
            const {allOn, allOff, display} = AssetTypeTree.toolGroupDisplay(toolGroup, control, onMap);
            if (!allOn) {
                isAllOn = false;
            }
            if (!allOff) {
                isAllOff = false;
            }
            return display;
        });

        return <div class="asset-type-tree">
            <AllOnAllOff
                allOff={isAllOff}
                allOn={isAllOn}
                onAllOff={() => AssetTypeTreeModel.removeAllAssetTypeIds(control)}
                onAllOn={() => AssetTypeTreeModel.addAllAssetTypeIds(control)}>
                <div class="right"> {acceptedAssetTypeIds.length} Type{acceptedAssetTypeIds.length === 1 ? '' : 's'} Selected</div>
            </AllOnAllOff>
            {groupDisplays}
        </div>;
    }

};

export default AssetTypeTree;
