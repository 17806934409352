
import modal from 'views/modal/modal';
import accountModel from 'models/account-model';
import ErrorBox from 'views/error-box/error-box';

const UpdateBrandNameDialog = {
    oninit: ({state}) => {
        state.brandName = accountModel.account.brandName;
    },

    onremove: () => delete accountModel.modifyAccountError,

    view: ({state}) => {
        return <div class="create-account">
            <ErrorBox error={accountModel.modifyAccountError}/>
            <h3 class="account-modal-header">Change Brand Name</h3>
            <div>
                <fieldset>
                    <input type="text" value={state.brandName} onchange={(e) => {
                        state.brandName = e.target.value;
                    }}/>
                </fieldset>
                <div class="create-account-btn-wrap">
                    <button class="btn account-submit-button" 
                        onclick={() => accountModel.updateBrandName(state)}
                    >
                        {accountModel.loading ? <div class="update-account-information-button spinner spinning"/> : 'Update'}
                    </button>
                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default UpdateBrandNameDialog;
