class CollapsibleModel {

    constructor() {
        this.expanded = {};
        this.isAllExpanded = false;
        this.isAllCollapsed = true;
    }

    initItem(xid, initialValue) {
        this.expanded[xid] = initialValue ? initialValue : false;
    }
    
    initToolGroup(toolGroupId, tools) {
        this.expanded[toolGroupId] = false;
        tools.forEach(tool => {
            this.initItem(tool.toolId);
        });
    }

    isCollapsed(xid) {
        return !this.expanded[xid];
    }

    expandAll() {
        Object.keys(this.expanded).forEach(xid => this.expandItem(xid, false));
        this.isAllExpanded = true;
        m.redraw();
    }

    collapseAll() {
        Object.keys(this.expanded).forEach(xid => this.collapseItem(xid, false));
        this.isAllCollapsed = true;
        m.redraw();
    }

    expandItem(xid, withRedraw = true) {
        this.expanded[xid] = true;
        this.isAllCollapsed = false;
        if (withRedraw) {
            m.redraw();
        }
    }

    collapseItem(xid, withRedraw = true) {
        this.expanded[xid] = false;
        this.isAllExpanded = false;
        if (withRedraw) {
            m.redraw();
        }
    }

    handleClick(xid) {
        // Manual check in case the item wasn't initted properly:
        if (this.expanded[xid]) {
            this.expanded[xid] = false;
        } else {
            this.expanded[xid] = true;
        }
        m.redraw();
    }

}

export default new CollapsibleModel();
