import formatDate from 'util/numbers/format-date';
import DateModel from 'models/date-model';

function getDayOptions(date, min, max, required) {

    const selectedDay = date && date.getDate();

    const options = required ? [] : [<option value="" selected={!selectedDay}></option>];

    for (let i = 1; i <= 31; i++) {

        options.push(<option value={i} selected={i === selectedDay}>{i}</option>);

    }

    return options;

}

function getMonthOptions(date, min, max, required) {

    const selectedMonth = date && date.getMonth();

    const options = required ? [] : [<option value="" selected={!selectedMonth && selectedMonth !== 0}></option>];

    for (let i = 0; i <= 11; i++) {

        options.push(<option value={i} selected={i === selectedMonth}>{formatDate.months[i]}</option>);

    }

    return options;

}

function getYearOptions(date, min, max, required) {

    const minYear = min.getFullYear(),
        maxYear = max.getFullYear(),
        selectedYear = date && date.getFullYear(),
        options = required ? [] : [<option value="" selected={!selectedYear}></option>];

    for (let i = minYear; i <= maxYear; i++) {

        options.push(<option value={i} selected={i === selectedYear}>{i}</option>);

    }

    return options;

}

const DatePicker = {
    oninit({state, attrs: {date, onchange, min, max}}) {
        state.model = new DateModel(date, min, max, onchange);
    },
    view: ({state: {model}, attrs: {required, date, min, max}}) => {
        model.date = date;
        if (min) {
            model.min = min;
        }
        if (max) {
            model.max = max;
        }
        return <div class="date-selector">
            <div class="date-input date-input-day">
                <span>Day</span>
                <i class="icon-circle-down dropdown">
                    <select onchange={e => model.updateDay(e)} class="editable-when-published datepicker">{getDayOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
            <div class="date-input date-input-month">
                <span>Month</span>
                <i class="icon-circle-down dropdown">
                    <select onchange={e => model.updateMonth(e)} class="editable-when-published datepicker">{getMonthOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
            <div class="date-input date-input-year">
                <span>Year</span>
                <i class="icon-circle-down dropdown">
                    <select onchange={e => model.updateYear(e)} class="editable-when-published datepicker">{getYearOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
        </div>;
    }
};

export default DatePicker;
