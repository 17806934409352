export default fn => e => {

    e.preventDefault();

    if (fn) {

        fn(e);

    }

};

