import modal from 'views/modal/modal';
import userModel from 'models/user-model';
import ErrorBox from 'views/error-box/error-box';
import validate from 'util/data/validate';

const UpdateUserNameDialog = {

    oninit: ({state}) => {
        state.errors = {
            givenName: null,
            familyName: null,
            company: null
        };
        state.givenName = userModel.user.givenName;
        state.familyName = userModel.user.familyName;
        state.company = userModel.user.company;
    },
    onremove: () => delete userModel.updateUserError,
    view: ({state}) => {
        const {errors} = state;
        return <div class="create-account">
            <ErrorBox error={userModel.modifyUserError}/>
            <h3 class="account-modal-header">Change User Information</h3>
            <fieldset>
                <label>First Name:  </label>
                {errors.givenName && <label class="error">{errors.givenName}</label>}
                <input type="text" value={state.givenName}  required
                    oninput={(e) => {
                        state.givenName = e.target.value;
                        validate.errorCheck.givenName(state);
                    }} onblur={() => validate.errorCheck.givenName(state)}
                />
            </fieldset>
            <fieldset>
                <label>Last Name: </label>
                {errors.familyName && <label class="error">{errors.familyName}</label>}
                <input type="text" value={state.familyName} required
                    oninput={(e) => {
                        state.familyName = e.target.value;
                        validate.errorCheck.familyName(state);
                    }} onblur={() => validate.errorCheck.familyName(state)}
                />
            </fieldset>
            <fieldset>
                <label>Company: </label>
                <input type="text" value={state.company}
                    oninput={(e) => {
                        state.company = e.target.value;
                    }}
                />
            </fieldset>

            <div class="create-account-btn-wrap">
                <div class={`btn ${userModel.loading ? 'disabled' : ''}`} onclick={() => {
                    const {
                        givenName,
                        familyName
                    } = errors;
                    if (givenName || familyName) {
                        return;
                    }
                    userModel.updateUser(state);
                }}>
                    {userModel.loading ? <div class="update-name spinner spinning"/> : 'Update'}
                </div>
                <div className="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </div>
            </div>
        </div>;
    }

};

export default UpdateUserNameDialog;
