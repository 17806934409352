import preventDefault from 'util/events/prevent-default';
import {authManager} from '@procore/sdt-core-js';


const Login = {
    view: ({attrs, state}) => {
        return <div class="login">
            <div class={`form-msg ${state.notice && state.notice.type}`}><h1>Sign In</h1>{ state.notice && state.notice.text}</div>
            {attrs.badCredentials ? <div class="error">Invalid email or password.</div> : null}
            <form onsubmit={preventDefault(e => {
                attrs.badCredentials = false;
                state.spinning = true;
                authManager.signIn({email: e.target.email.value, password: e.target.password.value}).then(() => {
                    window.location.reload();
                });
            })}>
                <p style={{fontSize: '14px'}}>Email Address</p>
                <input id="email" type="email" />
                <div style={{height: '18px'}} />
                <p style={{fontSize: '14px'}}>Password</p>
                <input id="password" type="password" />
                <div style={{paddingTop: '30px'}}>
                    <button class={`btn ${state.spinning ? 'spinning' : ''}`} type="submit">Sign In</button>
                </div>
            </form>
        </div>;
    }
};

export default Login;
