import OptionalLimit from 'views/optional-limit/optional-limit';
import AssetTypeTree from 'views/asset-type-tree/asset-type-tree';
import toolboxModel from 'models/toolbox-model';

const AssetControl = {
    oninit: ({state, attrs: {control}}) => {
        state.selectValue = control.attributes.acceptedAssetTypeIds ? 1 : 0;
    },

    view: ({state, attrs: {control}}) => {
        const isMeta = !!toolboxModel.projectToolCount;

        return <>
            <fieldset class="asset-control">
                <div class="select-wrapper" onselect={(e) => {
                    const value = e.target.value;
                    if (value === 0) {
                        control.attributes.acceptedAssetTypeIds = state.acceptedAssetTypeIds || {};
                        state.acceptedAssetTypeIds = control.attributes.acceptedAssetTypeIds;
                    } else {
                        delete control.attributes.acceptedAssetTypeIds;
                    }
                }}>
                    <select
                        onchange={(e) => {
                            state.selectValue = Number(e.target.value);
                            if (state.selectValue === 0) {
                                state.acceptedAssetTypeIds = control.attributes.acceptedAssetTypeIds;
                                delete control.attributes.acceptedAssetTypeIds;
                            } else {
                                control.attributes.acceptedAssetTypeIds = state.acceptedAssetTypeIds || [];
                            }
                        }}
                        value={state.selectValue}>
                        <option value={0}>Allow Any Data Type</option>
                        <option value={1}>Allow Only Selected Data Type(s)</option>
                    </select>
                </div>
                {state.selectValue === 1 && <AssetTypeTree control={control} excludeFiles={isMeta} />}
            </fieldset>
            <OptionalLimit
                numberLabel={'max number of values'}
                defaultChecked={control.attributes.limit !== undefined}
                defaultValue={control.attributes.limit || 1}
                onChange={(isOptionLimitSelected) => {
                    if (isOptionLimitSelected) {
                        control.attributes.limit = state.limit || 1;
                    } else {
                        delete control.attributes.limit;
                    }
                }}
                onInput={(newValue) => {
                    state.limit = newValue;
                    control.attributes.limit = newValue;
                }}
            />
        </>;
    }
};

export default AssetControl;
