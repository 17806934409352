import constants from 'util/data/constants';
import measure from 'util/numbers/measure';
import isMetric from 'util/numbers/is-metric';
import round from 'util/numbers/round';
import Toggle from 'views/toggle';
import Radio from 'views/radio';
import toolboxModel from 'models/toolbox-model';
import Multiselect from 'views/multiselect';
import DatePicker from 'views/date-picker';
import Upload from 'views/upload';
import MultiText from 'views/multitext';
import AssetControl from 'views/asset-control/asset-control';
import URLControl from 'views/url-control/url-control';

const formControls = {

    file(control, field) {
        const mediaId = control.attributes.default && control.attributes.default[0];
        return <div>
            {mediaId
                ? <img class="thumbnail left" src={constants.mediaServerUrl + mediaId} />
                : null}
            <Upload accept={control.attributes.accept} mediaId={mediaId} done={mediaIds => {
                toolboxModel.setDefault(control, field, mediaIds);
            }} />
        </div>;
    },

    name(control, field) {
        return <div class="name-control">
            <input type="text" class="editable-when-published" value={control.attributes.default || ''} oninput={e => {
                toolboxModel.setDefault(control, field, e.target.value);
            }}/>
        </div>;
    },

    text(control, field) {
        return <div class="text-control">
            <input type="text" class="editable-when-published" value={control.attributes.default || ''} oninput={e => {
                toolboxModel.setDefault(control, field, e.target.value);
            }}/>
        </div>;
    },

    paragraph(control, field) {
        return <div class="paragraph-control">
            <textarea class="editable-when-published" oninput={e => {
                toolboxModel.setDefault(control, field, e.target.value);
            }}>{control.attributes.default || ''}</textarea>
        </div>;
    },

    coordinates(control, field) {
        const point = control.attributes.default || {
            type: 'Point',
            coordinates: []
        };
        return <div class="coordinates-control">
            <div class="latitude-row">
                <label class="editable-when-published sublabel">Latitude</label>
                <input class="editable-when-published" type="text" value={point.coordinates[1]} oninput={e => {
                    toolboxModel.handleCoordinate({e, index: 1, point, control, field});
                }}/>
            </div>
            <div class="longitude-row">
                <label class="editable-when-published sublabel">Longitude</label>
                <input class="editable-when-published" type="text" value={point.coordinates[0]} oninput={e => {
                    toolboxModel.handleCoordinate({e, index: 0, point, control, field});
                }}/>
            </div>
        </div>;
    },

    dropdown(control, field) {
        return <div class="dropdown-control select-wrapper">
            <select class="editable-when-published" value={control.attributes.default} oninput={e => toolboxModel.setDefault(control, field, e.target.value)}>
                <option value="">Select an option</option>
                {field.type.values.map(value =>
                    <option value={value}>{value}</option>
                )}
            </select>
        </div>;
    },

    radio(control, field) {
        return <Radio defaultOption={control.attributes.default} options={field.type.values} onchange={value =>
            toolboxModel.setDefault(control, field, value)
        }/>;
    },

    toggle(control, field) {
        return <Toggle active={control.attributes.default} onchange={trueOrFalse => {
            toolboxModel.setDefault(control, field, trueOrFalse);
        }}/>;
    },

    length(control, field) {
        const metric = isMetric();
        let value = control.attributes.default;
        value = round(metric ? value : measure.metersToFeet(value));
        return <div class="length-control">
            <input type="number" step="any" class="editable-when-published number-input" value={value} oninput={e => {
                value = Number(e.target.value);
                value = metric
                    ? value
                    : measure.feetToMeters(value);
                toolboxModel.setDefault(control, field, value);
            }}/>
            <span class="number-metric">{metric ? 'meters' : 'feet'}</span>
        </div>;
    },

    area(control, field) {
        const metric = isMetric();
        let value = control.attributes.default;
        value = round(metric ? value : measure.squareMetersToSquareFeet(value));
        return <div class="area-control">
            <input type="number" step="any" class="editable-when-published number-input" value={value} oninput={e => {
                value = Number(e.target.value);
                value = metric
                    ? value
                    : measure.squareFeetToSquareMeters(value);
                toolboxModel.setDefault(control, field, value);
            }}/>
            <span class="number-metric">{metric ? 'meters²' : 'feet²'}</span>
        </div>;
    },

    volume(control, field) {
        const metric = isMetric();
        let value = control.attributes.default;
        value = round(metric ? value : measure.cubicMetersToCubicYards(value));
        return <div class="area-control">
            <input type="number" step="any" class="editable-when-published number-input" value={value} oninput={e => {
                value = Number(e.target.value);
                value = metric
                    ? value
                    : measure.cubicYardsToCubicMeters(value);
                toolboxModel.setDefault(control, field, value);
            }}/>
            <span class="number-metric">{metric ? 'meters³' : 'yards³'}</span>
        </div>;
    },

    number(control, field) {
        const value = control.attributes.default;
        return <div class="length-control">
            <input type="number" step="any" class="editable-when-published number-input" value={value} oninput={e => {
                toolboxModel.setDefault(control, field, Number(e.target.value));
            }}/>
        </div>;
    },

    date(control, field) {
        return <DatePicker
            date={control.attributes.default && new Date(control.attributes.default)}
            min={control.attributes.min && new Date(control.attributes.min)}
            max={control.attributes.max && new Date(control.attributes.max)}
            onchange={newDate => {
                toolboxModel.setDefault(control, field, newDate ? newDate.getTime() : undefined);
            }} />;
    },

    multiselect(control, field) {
        return <Multiselect defaultOptions={control.attributes.default} onchange={options => {
            if (options.length) {
                toolboxModel.setDefault(control, field, options);
            } else {
                toolboxModel.setDefault(control, field);
            }
        }} options={field.type.items.values} />;
    },

    multitext(control, field) {
        return <MultiText values={control.attributes.default} onchange={values => {
            if (values.length) {
                toolboxModel.setDefault(control, field, values);
            } else {
                toolboxModel.setDefault(control, field);
            }
        }}/>;
    },

    asset(control) {
        return <AssetControl control={control}/>;
    },

    url(control, field) {
        return <URLControl control={control} field={field}/>;
    }

};

const controlTypeNameToId = constants.controlTypeNameToId;

export default {
    [controlTypeNameToId.asset]: formControls.asset,
    [controlTypeNameToId.name]: formControls.name,
    [controlTypeNameToId.file]: formControls.file,
    [controlTypeNameToId.text]: formControls.text,
    [controlTypeNameToId.paragraph]: formControls.paragraph,
    [controlTypeNameToId.coordinates]: formControls.coordinates,
    [controlTypeNameToId.dropdown]: formControls.dropdown,
    [controlTypeNameToId.radio]: formControls.radio,
    [controlTypeNameToId.toggle]: formControls.toggle,
    [controlTypeNameToId.plan]: formControls.plan,
    [controlTypeNameToId.survey]: formControls.survey,
    [controlTypeNameToId.length]: formControls.length,
    [controlTypeNameToId.area]: formControls.area,
    [controlTypeNameToId.volume]: formControls.volume,
    [controlTypeNameToId.number]: formControls.number,
    [controlTypeNameToId.date]: formControls.date,
    [controlTypeNameToId.multiselect]: formControls.multiselect,
    [controlTypeNameToId.multitext]: formControls.multitext,
    [controlTypeNameToId.color]: null,
    [controlTypeNameToId.comments]: null,
    [controlTypeNameToId.dateTime]: null,
    [controlTypeNameToId.URL]: formControls.url,
    [controlTypeNameToId.user]: null,
    [controlTypeNameToId.multiuser]: null
};
