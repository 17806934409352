import sortableModel from 'models/sortable-model';

const isReordering = (toolId) => {
    if (toolId) {
        return sortableModel.isReorderingProperties(toolId);
    }
    return sortableModel.isReordering;
};

const SortingDropZone = {
    view: ({attrs: {index, toolGroupId, cssWrapper, toolId}}) => isReordering(toolId)
        ? <div onclick={(e) => sortableModel.isReorderingItem(index) ? null : sortableModel.handleClickDropZone(e, index)} 
            class={`${sortableModel.isADroppableZone(index, toolGroupId) ? 'drop-zone' : 'placeholder-zone'} ${cssWrapper || ''}`}></div> 
        : ''
};

export default SortingDropZone;
