import appModel from 'models/app-model';

function modal(view, attrs) {

    appModel.modal = {
        view: () => <div class="icon-close modal" onclick={() => modal.close(attrs ? attrs.onClose : null)}>
            <div class={`modal-content ${attrs && attrs.cssClass ? attrs.cssClass : ''}`} onclick={e => e.stopPropagation()}>
                {m(view, attrs)}
            </div>
        </div>
    };

    m.redraw();

}

modal.close = (onClose) => {
    appModel.modal = null;
    if (onClose) {
        onClose();
    }
    m.redraw();
};

export default modal;
