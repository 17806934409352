import list from 'util/data/list';

export default {

    account: account => {
        if (account) {
            account.users = list(account.users);
            account.projectIds = list(account.projectIds);
        }
        return account;
    },

    project: project => {
        if (project) {
            project.users = list(project.users);
            project.sites = list(project.sites);
        }
        return project;
    }

};
