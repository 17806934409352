import modal from 'views/modal/modal';
import userModel from 'models/user-model';
import ErrorBox from 'views/error-box/error-box';

const RemoveUserFromProjectDialog = {    
    onremove: () => delete userModel.removeFromProjectError,
    view: ({attrs: {project}}) => {
        const displayName = userModel.user.givenName && userModel.user.familyName ? `${userModel.user.givenName} ${userModel.user.familyName}` : userModel.user.emailAddress;

        return <div class="create-account">
            <ErrorBox error={userModel.removeFromProjectError}/>
            <h3 class="account-modal-header">
                Remove {displayName} from {project.name}? Neither {displayName} or {project.name} will be deleted.
            </h3>
            <div class="remove-btn-wrap">
                <button class={`btn btn-red delete-btn ${userModel.removeLoading ? 'delete-processing spinner spinning' : ''}`} onclick={() => userModel.removeProjectFromUser(project.projectId)}>
                    <span class="btn-text">Remove</span>
                </button>
                <button class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </button>
            </div>
        </div>;
    }
};

export default RemoveUserFromProjectDialog;
