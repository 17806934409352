import userModel from 'models/user-model';
import modal from 'views/modal/modal';
import ErrorBox from 'views/error-box/error-box';
import {getAppVersion} from 'util/data/get-app-version';
import list from 'util/data/list';
import constants from '../../util/data/constants';

const UpdateUserRoleDialog = {
    onremove: () => delete userModel.modifyUserAccountError,

    oninit: ({state}) => {
        state.accountsList = userModel.accounts;
        state.processing = {};
        state.valuesToUpdate = {};

        userModel.accounts.forEach((account) => {
            state.valuesToUpdate[account.accountId] = list(account.users).find(user => user.userId === userModel.user.userId).role;
        });
    },

    userActionButton: (account, state) => {
        if (state.processing[account.accountId]) {
            return <div class="update-role-modal-button btn disabled"><div class="update-role-modal-button-spinner spinning"/></div>;
        }
        return <div class="btn" onclick={() => userModel.updateUserAccountRole(account.accountId, state.valuesToUpdate[account.accountId], state.processing)}>Update</div>;
    },

    view: ({state}) => {

        const {givenName, familyName, emailAddress} = userModel.user;
        const name = givenName ? `${givenName} ${familyName}` : `${emailAddress}`;
        const options = Object.values(constants.accountUserRoles).filter(role => role.key !== 'superadmin');
        return <div class="add-user-to-account">
            <ErrorBox error={userModel.modifyUserAccountError}/>
            <h3>{`Update ${name}'s Roles`}</h3>
            <div class="role-change-search">
                <label>Search: </label>
                <input type="text" oninput={(e) => {
                    const query = e.target.value;
                    state.accountsList = userModel.userAccountSearch(query);
                }}/>
            </div>
            <table class="add-user-table">
                <thead>
                    <td>Account Name</td>
                    <td>Account ID</td>
                    <td>Available Seats</td>
                    <td>Version</td>
                    <td>User Role</td>
                    <td/>
                </thead>
                <tbody>
                    {state.accountsList.length === 0 ?
                        <div class="empty-row">
                            <div class="user-empty-state">
                                No Results found
                            </div>
                        </div>
                        :
                        state.accountsList.map(account => {
                            return <tr>
                                <td>{account.name}</td>
                                <td>{account.accountId}</td>
                                <td>{account.availableSeats}</td>
                                <td>{getAppVersion(account.attributes)}</td>
                                <td>
                                    <div class="account-user-select select-wrapper">
                                        <select
                                            value={state.valuesToUpdate[account.accountId]}
                                            onchange={(e) => {
                                                state.valuesToUpdate[account.accountId] = e.target.value;
                                            }}>
                                            {options.map((role) =>
                                                <option value={role.key}>{role.display || ''}</option>)}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    {UpdateUserRoleDialog.userActionButton(account, state)}
                                </td>
                            </tr>;
                        })}
                    <div class="empty-row"></div>
                </tbody>
            </table>
            <div class="add-user-btns">
                <div class="btn" onclick={() => modal.close()}>
                    Done
                </div>
            </div>
        </div>;
    }
};

export default UpdateUserRoleDialog;
