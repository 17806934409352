const Radio = {
    oninit: ({attrs: {options, defaultOption, defaultIndex}, state}) => {
        if (defaultOption || defaultIndex) {
            state.selected = defaultIndex || options.indexOf(defaultOption);
        } else {
            state.selected = null;
        }
    },

    view: ({attrs: {options, onchange}, state}) => {
        return <div class={`radio ${options.length > 3 ? 'over3' : ''}`}>
            {options.map((option, i) => {
                return <div key={option.key || option} class={'radio-opt' + (i === state.selected ? ' active' : '')} onclick={() => {
                    state.selected = i;
                    if (onchange) {
                        onchange(option, i);
                    }
                }}><span class="radio-circle"></span> <span class="radio-opt-label">{option}</span></div>;
            })
            }
        </div>;
    }
};

export default Radio;
