const ErrorBox = {
    view: ({attrs: {error}}) => {
        return !!error && <div class="add-remove-error-box">
            <label>{error.message}</label>
            <div class="add-remove-error-details error">
                {error.details}
            </div>
        </div>; 
    }
};

export default ErrorBox;
