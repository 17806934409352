import userModel from 'models/user-model';
import list from 'util/data/list';
import {getAppVersion} from 'util/data/get-app-version';
import UpdateAccountUserRoleDialog from 'views/user/update-account-user-role-dialog';
import modal from 'views/modal/modal';
import removeAccountFromUserDialog from 'views/user/remove-account-from-user-dialog';
import constants from 'util/data/constants';

const UserAccounts = {
    oninit: () => { 
        userModel.fetchUser()
            .then(() => userModel.fetchAccounts());
    },
    view: () => {  
        const accounts = userModel.accounts;
        return <table>
            <thead>
                <td>Account Name</td>
                <td>Account ID</td>
                <td>Version</td>
                <td>User Role{userModel.accounts.length === 0 ? 
                    ''
                    : 
                    <button class="role-edit edit-button" onclick={() => modal(UpdateAccountUserRoleDialog, {cssClass: 'add-user-modal'})}> <i class= "icon-pencil"></i></button>
                }
                </td>
                <td></td>
            </thead>
            <tbody>
                {userModel.accountLoading && accounts.length === 0 ? 
                    <div class ="empty-row">
                        <div class="spinner spinning teal user-accounts"></div> 
                    </div>
                    :
                    accounts.map(account => {
                        const currentUserId = userModel.user.userId;
                        const accountUsers = list(account.users);
                        const selectedUser = accountUsers.find(user => user.userId === currentUserId);
                        return <tr key={account.accountId || account.createdDateTime}>
                            <td>{account.accountId
                                ? <a href={`#view=account&accountId=${account.accountId}`}>{account.name}</a>
                                : account.name}
                            </td>
                            <td>{account.accountId || '-'}</td>
                            <td>{getAppVersion(account.attributes)}</td>
                            <td>{constants.accountUserRoles[account.role] && constants.accountUserRoles[account.role].display || constants.accountUserRoles[selectedUser.role] && constants.accountUserRoles[selectedUser.role].display}</td>
                            <td>{accounts.length === 1 ?
                                null
                                :
                                <button class="remove-user-from-account-button" onclick={() => modal(removeAccountFromUserDialog, {account})}><i class="icon-trash"></i></button>}</td>
                        </tr>; 
                    })}
            </tbody>
        </table>;
    }
};

export default UserAccounts;
