import React from 'react';

const UnearthIcon = () => {
    return(
        <img className={'unearth-icon'} src={'/images/unearth-logo-mini.png'} />  
    )
};
    
const UnearthIconElement = (props) => React.createElement(UnearthIcon, props, null);

export default UnearthIconElement;
