import React from 'react';
import RowElement from 'views/react/Row';
import constants from 'util/data/constants';

const ProjectRow = ({project}) => {
    const {projectId, name, assetCount, accountId, accountName, accountAttributes, createdDateTime} = project;
    const projectRowCellValues = [
        <strong><a href={`#view=project&projectId=${projectId}`}>{name}</a>
            <button className="launch-button" onClick={() => window.open(`${constants.launchUrl}#projectId=${projectId}`, '_blank')}>Go</button></strong>,
        projectId,
        assetCount || 0,
        <strong><a href={`#view=account&accountId=${accountId}`}>{accountName}</a>
            <button className="launch-button" onClick={() => window.open(`${constants.launchUrl}#projectId=${project.accountAttributes.metaProjectId}`, '_blank')}>Go</button></strong>,
        accountAttributes.appVersion === 1
            ? accountAttributes.theme === 'unearth-sewer'
                ? 'Sewer'
                : 'Construction'
            : 'OPV2',
        new Date(createdDateTime).toLocaleDateString()
    ];

    return (
        <RowElement cellValues={projectRowCellValues} />
    );
};

const ProjectRowElement = (props) => React.createElement(ProjectRow, props, null);

export default ProjectRowElement;
