const constants = JSON.parse(document.getElementById('constants').innerHTML);

constants.controlTypeIdToName = {
    'kHP5E7ytbCXDobUEpGUJ': 'area',
    'KWvNr3goaEC8ZTq6Pcvx': 'asset',
    'JClHKXspGsXCulXTAyiI': 'links',
    '7xgQOT7XONk9QoD_ETaj': 'comments',
    'BLIOUucM-vfNsdWoy1HL': 'coordinates',
    's2ZgKG5YJ-h0K7kS49YN': 'date',
    'ltEqAmuRhCLTpW-n0n4b': 'dropdown',
    'RpThORwku_8GADvB7qKE': 'embed',
    'InwvONjdUiDHp9MCn8Nu': 'file',
    'pa2idyDpPoN_oDLcD91k': 'length',
    'QFRFEjSlK9HCERyHgVLO': 'multiselect',
    'XHrOauni2ZhI0FB1GzL8': 'multitext',
    'fJjTURmD5bU7VoDX2n7t': 'multiuser',
    'cHOO50gwqyqTIiy24lxX': 'name',
    'aITN7NyIRXyGHXKvwMAv': 'number',
    '50joA2aJxXFI4gR8mvx7': 'paragraph',
    '8Y0hi3g6q6IZT9focY9B': 'plan',
    '1UOw3bdiOONotKjYrSj_': 'project',
    'upr8Czy0FSyzOhO-bN4R': 'radio',
    'Nvhz7WVx2nYMO7Uzm7Dw': 'request',
    'hW0itVu6pbBIXF16G4SA': 'survey',
    '0K3uA1hBYt5PWb4-pGqb': 'text',
    'OR2gCMYvD9vQTu1nFBM1': 'toggle',
    'EbAuM-i0vYuBEtSGVKcR': 'user',
    'owM1UO03S9ju7B_DntXK': 'volume',
    'ewmvVR6NZFCDcYY4x1YT': 'place',
    'g33Bu7ePDnd4nGK05gei': 'imodel',
    '_0hPpJEtT0HyDxpX_6WB': 'URL'
};

constants.controlTypeNameToId = {
    'area': 'kHP5E7ytbCXDobUEpGUJ',
    'asset': 'KWvNr3goaEC8ZTq6Pcvx',
    'links': 'JClHKXspGsXCulXTAyiI',
    'comments': '7xgQOT7XONk9QoD_ETaj',
    'coordinates': 'BLIOUucM-vfNsdWoy1HL',
    'date': 's2ZgKG5YJ-h0K7kS49YN',
    'dropdown': 'ltEqAmuRhCLTpW-n0n4b',
    'embed': 'RpThORwku_8GADvB7qKE',
    'file': 'InwvONjdUiDHp9MCn8Nu',
    'length': 'pa2idyDpPoN_oDLcD91k',
    'multiselect': 'QFRFEjSlK9HCERyHgVLO',
    'multitext': 'XHrOauni2ZhI0FB1GzL8',
    'multiuser': 'fJjTURmD5bU7VoDX2n7t',
    'name': 'cHOO50gwqyqTIiy24lxX',
    'number': 'aITN7NyIRXyGHXKvwMAv',
    'paragraph': '50joA2aJxXFI4gR8mvx7',
    'plan': '8Y0hi3g6q6IZT9focY9B',
    'project': '1UOw3bdiOONotKjYrSj_',
    'radio': 'upr8Czy0FSyzOhO-bN4R',
    'request': 'Nvhz7WVx2nYMO7Uzm7Dw',
    'survey': 'hW0itVu6pbBIXF16G4SA',
    'text': '0K3uA1hBYt5PWb4-pGqb',
    'toggle': 'OR2gCMYvD9vQTu1nFBM1',
    'user': 'EbAuM-i0vYuBEtSGVKcR',
    'volume': 'owM1UO03S9ju7B_DntXK',
    'place': 'ewmvVR6NZFCDcYY4x1YT',
    'imodel': 'g33Bu7ePDnd4nGK05gei',
    'URL': '_0hPpJEtT0HyDxpX_6WB'
};

constants.predefinedEmbeds = {
    solid: {
        name: 'Solid',
        mediaId: '4b5qx3aaaR2nkf7vAROm'
    },
    dashed: {
        name: 'Dashed',
        mediaId: 'K4-m7_y16-en5_rJfaTd'
    }
};

constants.unearthAccountId = {
    local: '8FWmW56kY4WWVk3jNOex',
    development: '8FWmW56kY4WWVk3jNOex',
    staging: '2lGpX9cg_w5GcZBMe816',
    production: '3FeZj7ZrhCI6xROqBkdG'
}[constants.awsProfile];

constants.accountUserRoles = {
    owner: {key: 'owner', ranking: 7, display: 'Owner'},
    admin: {key: 'admin', ranking: 6, display: 'Administrator'},
    manager: {key: 'manager', ranking: 5, display: 'Manager'},
    general: {key: 'general', ranking: 4, display: 'General User'},
    limited: {key: 'limited', ranking: 3, display: 'Limited User'},
    viewer: {key: 'viewer', ranking: 1, display: 'Viewer'},
    superadmin: {key: 'superadmin', ranking: 8, display: 'Super Admin'}
};

constants.webAppUrl = {
    local: 'http://localhost:8084',
    development: 'https://app-dev.unearthlabs.com',
    staging: 'https://app-staging.unearthlabs.com',
    production: 'https://app.unearthlabs.com'
}[constants.awsProfile];

constants.mediaServerUrl = {
    local: 'https://static.unearthlabs.com/',
    development: 'https://static.unearthlabs.com/',
    staging: 'https://static.unearthlabs.com/',
    production: 'https://static.unearthlabs.com/'
}[constants.awsProfile];

constants.launchUrl = {
    local: 'https://app-dev.unearthlabs.com/',
    development: 'https://app-dev.unearthlabs.com/',
    staging: 'https://app-staging.unearthlabs.com/',
    production: 'https://app.unearthlabs.com/'
}[constants.awsProfile];

constants.promapperLaunchUrl = {
    local: 'https://app-dev.unearthlabs.com/embed-js/',
    development: 'https://app-dev.unearthlabs.com/embed-js/',
    staging: 'https://app-staging.unearthlabs.com/embed-js/',
    production: 'https://app.unearthlabs.com/embed-js/'
}[constants.awsProfile];

export const COLUMN_ORDER_ICON_ENUM = {
    0: '-',
    1: '▼',
    2: '▲'  
};

export default constants;
