import toolboxModel from 'models/toolbox-model';
import uploadModel from 'models/upload-model';
import modal from 'views/modal';
import ErrorLog from 'views/error-log';

const STATUS_PATTERN = /( |^)status( |$)/i;

// Given a property key and a property value,
// guess what the corresponding control type should be.
function valueToControlType(key, value, hasName) {
    const EXTERNAL_ID = /^[A-Za-z0-9_-]{10,}$/;
    // These regular expressions match any string that contains one
    // of the captured words surrounded by spaces, the start of 
    // the string, or the end of the string.
    const NAME = /(( |^)id( |$)|( |^)name( |$)|( |^)address( |$))/;
    const LENGTH = /(( |^)length( |$)|( |^)distance( |$))/;
    const AREA = /( |^)area( |$)/;
    const VOLUME = /( |^)volume( |$)/;
    const DATE = /( |^)date( |$)/;
    const PLAN = /(( |^)plan( |$)|( |^)layer( |$))/;
    const SURVEY = /( |^)survey( |$)/;
    // If no truthy value was found, use the control label
    // to choose a control type
    if (value === null || value === undefined) {
        // We only want one name control per form
        if (!hasName && key.match(NAME)) {
            return 'name';
        }
        if (key.match(LENGTH)) {
            return 'length';
        }
        if (key.match(AREA)) {
            return 'area';
        }
        if (key.match(VOLUME)) {
            return 'volume';
        }
        if (key.match(DATE)) {
            return 'date';
        }
        if (key.match(PLAN)) {
            return 'plan';
        }
        if (key.match(SURVEY)) {
            return 'survey';
        }
        if (key === '_projectid') {
            return 'project';
        }
        return 'text';
    }
    // Otherwise, use the type of the value to determine the control type,
    // disambiguating using the control label when necessary.
    if (typeof value === 'string') {
        if (value.match(EXTERNAL_ID)) {
            if (key.match(PLAN)) {
                return 'plan';
            }
            if (key.match(SURVEY)) {
                return 'survey';
            }
            if (key === '_projectid') {
                return 'project';
            }
            return 'user';
        }
        if (value.length > 35 || value.indexOf('\n') !== -1) {
            return 'paragraph';
        }
        if (!hasName && key.match(NAME)) {
            return 'name';
        }
        return 'text';
    }
    if (typeof value === 'number') {
        if (key.match(LENGTH)) {
            return 'length';
        }
        if (key.match(AREA)) {
            return 'area';
        }
        if (key.match(VOLUME)) {
            return 'volume';
        }
        if (value % 1 === 0 && key.match(DATE)) {
            return 'date';
        }
        return 'number';
    }
    if (Array.isArray(value)) {
        if (!value.length) {
            return 'multitext';
        }
        if (typeof value[0] === 'string') {
            if (value[0].match(EXTERNAL_ID)) {
                return 'file';
            }
            return 'multitext';
        }
        if (typeof value[0] === 'number' && value.length === 2) {
            return 'coordinates';
        }
    }
    if (typeof value === 'boolean') {
        return 'toggle';
    }
}

// Turns a string of CSV data into a 2D array
function parseCSV(text, delimiter) {
    const pattern = new RegExp(`(\\${delimiter}|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^\\${delimiter}\\r\\n]*))`, 'gi');
    const arrData = [[]];
    let arrMatches = pattern.exec(text);
    while (arrMatches) {
        if (arrMatches[1].length && arrMatches[1] !== ',') {
            arrData.push([]);
        }
        arrData[arrData.length - 1].push(arrMatches[2] ? 
            arrMatches[2].replace(new RegExp( '""', 'g' ), '"') :
            arrMatches[3]);
        arrMatches = pattern.exec(text);
    }
    return arrData;
}

// Determines the delimiter for a CSV
// input format is an array of strings
function getCSVDelimiter(rows) {
    const delimiters = {
        ',': 0,
        '|': 0,
        '\t': 0,
        ';': 0,
        ':': 0
    };
    // Find the candidate character that occurs the most times
    // in the first 5 rows of data AND occurs at least once per row
    for (let i = 0; i < 5 && i < rows.length; i++) {
        const row = rows[i];
        Object.keys(delimiters).forEach(delimiter => {
            const count = row.split(delimiter).length;
            // The delimiter will appear at least once per row
            if (count === 0) {
                delete delimiters[delimiter];
            } else {
                delimiters[delimiter] += count;
            }
        });
    }
    const sortedDelimiters = Object.keys(delimiters).sort((a, b) => delimiters[b] - delimiters[a]);
    return sortedDelimiters[0] || ',';
}

// Converts a CSV string into an object describing default controls
// for that dataset. Output format is:
// {
//     [label]: {
//         type: "controlTypeName",
//         values: ["field values", "if any"]
//     }   
// }
function csvToControls(text) {
    const delimiter = getCSVDelimiter(text.split('\n'));
    const csv = parseCSV(text, delimiter);
    const controls = {};
    const keys = csv.shift();
    // If we identify a status column, then we need to keep track of all the
    // status values so we can use them as dropdown options.
    const statusIndex = keys.findIndex(key => key.match(STATUS_PATTERN));
    csv.forEach(row => {
        row.forEach((value, i) => {
            const key = keys[i];
            // If this is a status value, add it to the list of
            // possible values for the status dropdown.
            if (statusIndex === i && typeof value === 'string') {
                controls[key] = controls[key] || {};
                controls[key].type = 'dropdown';
                controls[key].values = controls[key].values || {};
                controls[key].values[value] = true;
            } else if (!controls[key] && value !== undefined && value !== null) {
                // handle numeric values
                const num = Number(value);
                if (num.toString() === value) {
                    value = num;
                // handle array values
                } else if (value[0] === '[' && value[value.length - 1] === ']') {
                    try {
                        value = JSON.parse(value);
                    } catch (e) {
                        // noop
                    }
                // handle boolean values
                } else if (value === 'true' || value === 'false') {
                    value = value === 'true';
                }
                controls[key] = value;
            }
        });
    });
    let hasName;
    Object.keys(controls).forEach((key, i) => {
        if (statusIndex === i) {
            // Now that the status values have been aggregated,
            // convert them from an object to an array.
            controls[key].values = Object.keys(controls[key].values);
        } else {
            const value = controls[key];
            const type = valueToControlType(key.toLowerCase(), value, hasName);
            if (type === 'name') {
                hasName = true;
            }
            controls[key] = {
                type
            };
        }
    });
    return controls;
}

// Converts a dataset into an object describing default controls
// for that dataset. Output format is:
// {
//     [label]: {
//         type: "controlTypeName",
//         values: ["field values", "if any"]
//     }   
// }
function jsonToControls(data) {
    let items = data;
    // Handle either an array of features, a single feature of type FeatureCollection,
    // or a JSON array of objects (not GeoJSON).
    if (!Array.isArray(data)) {
        if (Array.isArray(data.features)) {
            items = data.features;
        } else {
            toolFileError('JSON must either be an array of features or a valid GeometryCollection');
        }
    }
    const isGeoJSON = items[0] && items[0].type === 'Feature';
    const controls = {};
    items.forEach(properties => {
        if (isGeoJSON) {
            properties = properties.properties;
        }
        Object.keys(properties).forEach(key => {
            const value = properties[key];
            // If we identify a status property, then we need to keep track of all the
            // status values so we can use them as dropdown options.
            if (typeof value === 'string' && key.match(STATUS_PATTERN)) {
                controls[key] = controls[key] || {};
                controls[key].type = 'dropdown';
                controls[key].values = controls[key].values || {};
                controls[key].values[value] = true;
            } else {
                controls[key] = controls[key] || value;
            }
        });
    });
    let hasName;
    Object.keys(controls).forEach(key => {
        if (key.match(STATUS_PATTERN)) {
            // Now that the status values have been aggregated,
            // convert them from an object to an array.
            controls[key].values = Object.keys(controls[key].values);
        } else {
            const value = controls[key];
            const type = valueToControlType(key.toLowerCase(), value, hasName);
            if (type === 'name') {
                hasName = true;
            }
            controls[key] = {
                type
            };
        }
    });
    return controls;
}

function toolFileError(error) {
    modal(ErrorLog, {
        errors: [error || 'This file could not be processed.'],
        onClose: () => modal.close()
    });
}

// Given some control configuration for a dataset, create a
// new tool, add the controls to the tool, and add the tool to the toolGroup.
function toolFromControls({name, controls, toolGroup, geomType}) {
    const tool = toolboxModel.blankTool(geomType, name);
    Object.keys(controls).forEach(label => {
        const {type, values} = controls[label];
        toolboxModel.addControl(tool, label, type, values);
    });
    toolGroup.tools.push(tool);
    m.redraw();
}

// Pulls the geometry type (if any) from the first feature in a dataset.
function getGeomType(data) {
    const features = Array.isArray(data) ? data : data.features;
    const feature = features && features[0];
    if (feature) {
        const type = feature.geometry && feature.geometry.type;
        if (type) {
            if (type === 'GeometryCollection') {
                return;
            }
            return type.replace('Multi', '');
        }
    }
}

const processFile = (file, toolGroup) => e => {
    const nameParts = file.name.split('.');
    const name = nameParts[0];
    const extention = nameParts.pop().toUpperCase();
    let data = e.target.result;
    let geomType;
    const isCSV = extention === 'CSV';
    if (!isCSV) {
        try {
            data = JSON.parse(data);
        } catch (err) {
            return toolFileError();
        }
        geomType = getGeomType(data);
    }
    const getControls = extention === 'CSV' ? csvToControls : jsonToControls;
    const controls = getControls(data);
    toolFromControls({
        name,
        controls,
        toolGroup,
        geomType
    });
};

// Allows the user to select a CSV or JSON file,
// autogenerates a tool for the dataset,
// and adds it to the provided toolGroup.
function toolFromFile(toolGroup) {
    return uploadModel.pickFiles({
        accept: ['.json', '.geojson', '.csv']
    }).then(([file]) => {
        const reader = new FileReader();
        reader.onload = processFile(file, toolGroup);
        reader.readAsText(file);
    });
}

export default toolFromFile;
