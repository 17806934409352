import accountsModel from 'models/accounts-model';
import MreactComponent from 'util/dom/mreact-component';
import Button from 'views/react/Button';
import AccountRowElement from 'views/react/AccountRow';
import AccountTableHeaderElement from 'views/react/AccountTableHeader';

const Accounts = {
    oninit: () => accountsModel.fetch(),
    view: () =>
        <div class="accounts">
            <div class="page-title">
                <input type="text" oninput={accountsModel.search} placeholder="search" />
            </div>
            <div class="header-buttons users-header">
                <MreactComponent element={Button({
                    handleButtonClick: () => {
                        accountsModel.openCreateAccount();
                    },
                    buttonText: 'Create Account',
                    buttonSize: 'small',
                    buttonStyle: 'accounts',
                    buttonColor: 'default'
                })} />  
            </div>
            <table>
                <thead>
                    <MreactComponent element={AccountTableHeaderElement({})} tagName="tr"/>  
                </thead>
                <tbody>
                    {accountsModel.accounts.map((account) => {
                        account.attributes = account.attributes || {};
                        if (account.hidden) {
                            return null;
                        }
                        return <MreactComponent
                            element={AccountRowElement({account})}
                            tagName="tr"
                            key={account.accountId}
                        />;
                    })}
                </tbody>
            </table> 
        </div>
};

export default Accounts;
