import constants from 'util/data/constants';
import draftsModel from 'models/drafts-model';
import QuickDraftsDisclaimer from 'views/drafts/quick-drafts-disclaimer';

const Drafts = {
    oninit: () => draftsModel.retrieveDrafts(),
    view: () =>
        <div className="quick-drafts"> 
            <QuickDraftsDisclaimer titleText="Quick Drafts"/>
            <table>
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Type</td>
                    <td>Last Saved</td>
                    <td>Delete</td>
                </thead>
                <tbody>
                    {draftsModel.loading ?
                        <div class ="empty-row">
                            <div class="no-users">
                                <div class="spinner spinning teal account-users"></div>
                            </div>
                        </div>
                        :
                        Object.values(draftsModel.toolboxes).length > 0 ? Object.values(draftsModel.toolboxes).map(toolbox => {
                            const isMeta = toolbox.group.groups.find(g =>
                                g.tools.find(t =>
                                    t.featureTypes && t.featureTypes.find(ft =>
                                        ft.attributes && ft.attributes.interface === 'project'
                                    )
                                )
                            );
                            return <tr key={toolbox.toolboxId}>
                                <td>
                                    <a class="toolbox-name" href={`#view=toolbox&accountId=${toolbox.account.accountId}&toolboxId=${toolbox.toolboxId}`}>
                                        <img src={constants.mediaServerUrl + toolbox.attributes.icon.mediaId}/>
                                        {toolbox.name || 'Untitled'}
                                    </a>
                                </td>
                                <td>{toolbox.toolboxId}</td>
                                <td>{(isMeta ? <div class="meta-toolbox">Meta</div> : 'Normal')}</td>
                                <td>{new Date(toolbox.savedDateTime).toLocaleString()}</td>
                                <td class="toolbox-button-wrap">
                                    <div class="btn btn-small btn-red btn-secondary" onclick={() => draftsModel.deleteDraft(toolbox.toolboxId)}>Delete</div>
                                </td>
                            </tr>;
                        })
                            : <div>No drafts found.</div>
                    }
                </tbody>
            </table>
        </div>
};

export default Drafts;
