import accountsModel from 'models/accounts-model';
import modal from 'views/modal/modal';
import validate from 'util/data/validate';
import ErrorBox from 'views/error-box/error-box';

// Removing options https://procoretech.atlassian.net/browse/SD-73
const Gas = '2p88gH1nW8cN4Wlb8KYX';
// const AEC = 'YMxl4hgY4cg1HN3VFuMz' 
// const DroneServices = 'SU1OORTfgTxTPOecpN';
// const SewerStorm = 'yH5Wsry9VvqFimf8UBjt';

const CreateAccountDialog = {
    oninit: ({state}) => {
        state.errors = {
            givenName: null,
            familyName: null,
            emailAddress: null,
            phoneNumber: null,
            accountName: null
        };
        state.toolboxId = Gas; 
    },

    view: ({state}) => {
        const {errors} = state;
        const isDisabled = Object.keys(errors).length !== 0;
        return <div class="create-account">
            <h2>Create Account</h2>
            <ErrorBox error={accountsModel.createAccountError}/>
            <fieldset>
                <label>First Name</label>
                {errors.givenName && <label class="error">{errors.givenName}</label>}
                <input type="text" oninput={(e) => {
                    state.givenName = e.target.value;
                    validate.errorCheck.givenName(state);
                }} onblur={() => validate.errorCheck.givenName(state)}/>
            </fieldset>
            <fieldset>
                <label>Last name</label>
                {errors.familyName && <label class="error">{errors.familyName}</label>}
                <input type="text"oninput={(e) => {
                    state.familyName = e.target.value;
                    validate.errorCheck.familyName(state);
                }} onblur={() => validate.errorCheck.familyName(state)}/>
            </fieldset>
            <fieldset>
                <label>Email address</label>
                {errors.emailAddress && <label class="error">{errors.emailAddress}</label>}
                <input type="text" oninput={(e) => {
                    state.emailAddress = e.target.value;
                    validate.errorCheck.emailAddress(state);
                }} onblur={() => validate.errorCheck.emailAddress(state, true)}/>
            </fieldset>
            <fieldset>
                <label>Phone number</label>
                {errors.phoneNumber && <label class="error">{errors.phoneNumber}</label>}
                <input type="text" oninput={(e) => {
                    state.phoneNumber = e.target.value;
                    validate.errorCheck.phoneNumber(state);
                }} onblur={ () => validate.errorCheck.phoneNumber(state, true)}/>
            </fieldset>
            <fieldset>
                <label>Account name</label>
                {errors.accountName && <label class="error">{errors.accountName}</label>}
                <input type="text" oninput={(e) => {
                    state.accountName = e.target.value;
                    validate.errorCheck.accountName(state);
                }} onblur={ () => validate.errorCheck.accountName(state)}/>
            </fieldset>
            <div class="create-account-btn-wrap">
                <div class={`btn ${isDisabled || accountsModel.loading ? 'disabled' : ''}`} onclick={() => {
                    const errorChecks = Object.keys(state.errors);
                    for (let i = 0; i < errorChecks.length; i++) {
                        const errorCheckKey = errorChecks[i];
                        const errorCheckFn = validate.errorCheck[errorChecks[i]];
                        errorCheckFn(state);
                        if (state.errors[errorCheckKey]) {
                            return;
                        }
                    }
                    accountsModel.createUser(state);
                }}>
                    {accountsModel.loading ? <><div class="spinner spinning"/><span>Processing</span></> : 'Create Account'}
                </div>
                <div class="btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                </div>
            </div>
        </div>;
    }
};
export default CreateAccountDialog;
