import constants from 'util/data/constants';

function toolboxPreview(toolbox) {

    let errorLog;

    function createErrorLog() {

        if (!errorLog) {

            errorLog = document.createElement('div');

            errorLog.onclick = e => e.stopPropagation();

            errorLog.classList.add('error-log');

            modal.appendChild(errorLog);

            const closeButton = document.createElement('div');

            closeButton.classList.add('btn', 'btn-small', 'right');

            closeButton.textContent = 'Close';

            closeButton.onclick = () => {
                errorLog.remove();
                errorLog = null;
            };

            errorLog.innerHTML = '<div class="left"></div>';

            errorLog.appendChild(closeButton);

        }

    }

    const postMessage = e => {

        if (e.data === 'sendToolbox') {

            e.source.postMessage(toolbox, '*');

        } else {

            createErrorLog();

            errorLog.firstElementChild.innerHTML += `<p>${e.data}</p>`;

        }

    };

    window.addEventListener('message', postMessage, false);

    const [projectId, siteId] = {
        development: ['U8iAc0vK6sBoBV6pyqOA', 'KHucFK7lUKaeZLCNgCcl'],
        staging: ['aSMxd3DVclRskOX3BA0I', 'riIPz6JO36tJqJuJ6ua2'],
        production: ['q03yqTEKeuEfE6sysImX', 'x26tYdBgJI0HYcXjNqHu']
    }[constants.awsProfile];

    let hostname;

    if (constants.release) {

        hostname = {
            development: 'https://app-dev.unearthlabs.com',
            staging: 'https://app-staging.unearthlabs.com',
            production: 'https://app.unearthlabs.com'
        }[constants.awsProfile];

    } else {

        hostname = `${location.protocol}//${location.hostname}:8000`;

    }

    window.mockToolbox = toolbox;

    const modal = document.createElement('div'),
        iframe = document.createElement('iframe');

    modal.classList.add('icon-close', 'modal');

    modal.onclick = () => {
        window.removeEventListener('message', postMessage);
        if (errorLog) {
            errorLog.remove();
            errorLog = null;
        }
        modal.remove();
    };

    modal.classList.add('toolbox-preview');

    iframe.src = `${hostname}/#projectId=${projectId}&siteId=${siteId}&preview=true&clientId=${localStorage.getItem('clientId')}`;

    modal.appendChild(iframe);

    document.body.appendChild(modal);

}

export default toolboxPreview;
