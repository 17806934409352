import React, { useState } from 'react';
import accountModel from '../../../models/account-model';
import { COLUMN_ORDER_ICON_ENUM } from 'util/data/constants';

const ToolboxProjectTableHeader = () => {
    const [addDateOrder, setAddDateOrder] = useState(1);
    const [addDateOrderIcon, setAddDateOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[1]);
    const [toolboxNameOrder, setToolboxNameOrder] = useState(0);
    const [toolboxNameOrderIcon, setToolboxNameOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[0]);

    const handleToolboxNameOrderClick = () => {
        if (toolboxNameOrder === 0) {
            setToolboxNameOrder(1);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            accountModel.sortToolboxProject('name', 0); 
        } else if (toolboxNameOrder === 1) {
            setToolboxNameOrder(2);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            accountModel.sortToolboxProject('name', 1);
        } else if (toolboxNameOrder === 2) {
            setToolboxNameOrder(0);
            setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            accountModel.sortToolboxProject('name', 2);
        }
        setAddDateOrder(0);
        setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);  
    };
    
    const handleAddDateOrderClick = () => {
        if (addDateOrder === 1) {
            setAddDateOrder(2);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            accountModel.sortToolboxProject('createdDateTime', 1);
        } else if (addDateOrder === 2) {
            setAddDateOrder(0);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            accountModel.sortToolboxProject('createdDateTime', 2);
        } else if (addDateOrder === 0) {
            setAddDateOrder(1);
            setAddDateOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            accountModel.sortToolboxProject('createdDateTime', 0);
        }
        setToolboxNameOrder(0);
        setToolboxNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);      
    };

    return (
        <> 
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Name
                    </div>
                    <button className="order-button" onClick={handleToolboxNameOrderClick}>
                        {toolboxNameOrderIcon}
                    </button>
                </div> 
            </td>
            <td>ID</td>
            <td>Users</td>
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Added
                    </div>

                    <button className="order-button" onClick={handleAddDateOrderClick}>
                        {addDateOrderIcon}
                    </button>
                </div>
            </td> 
        </>
    );
};
 
const ToolboxProjectTableHeaderElement = (props) => React.createElement(ToolboxProjectTableHeader, props, null);

export default ToolboxProjectTableHeaderElement;
