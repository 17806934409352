import modal from 'views/modal/modal';
import userModel from 'models/user-model';

const ResetUserPasswordDialog = {    
    oninit: ({state}) => {
        state.isclicked = false;
    },
    onremove: ({state}) => {
        delete userModel.resetPasswordSuccess;
        state.isclicked = false;
    },
    view: ({attrs: {user}, state}) => {

        const displayName = user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.emailAddress;

        return !state.isclicked ? 
            <div class="create-account">
                <h3 class="account-modal-header">
                            Would you like to send a password reset email to {displayName}?
                </h3>
                <div class="create-account-btn-wrap">     
                    <button class="btn account-submit-button"
                        onclick={() => {
                            state.isclicked = true;
                            userModel.resetPassword(); 
                        }}> 
                        Send 
                    </button>
                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                            Cancel 
                    </div>
                </div>
            </div>
            :
            <div class="create-account">
                <h3 class="account-modal-header">
                            Success! A reset password email was sent to {displayName}.
                </h3>
                <div class="create-account-btn-wrap">     
                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                    Done
                    </div>
                </div>
            </div>;
    }
};


export default ResetUserPasswordDialog;
