import permissionsModel from 'models/permissions-model';
import constants from 'util/data/constants';

const accessTypeToText = {
    CREATE: 'Add New',
    READ: 'View',
    EDIT: 'Edit',
    DELETE: 'Delete'
};

const ToolPermissions = {

    oninit: ({attrs: {toolId, toolAttributes}}) => {
        permissionsModel.initToolAttributes(toolId, toolAttributes);
    },

    view: ({attrs: {toolId, toolAttributes}}) => {
        return <div className="data-type-section data-type-permissions">
            <div class="section-header">Permissions</div>
            <div class="role-cards">
                {Object.keys(permissionsModel.defaultPermissions).map(role =>
                    <><div class="card role-card">
                        <i className="icon-permission"></i>
                        <div className="role-name">{constants.accountUserRoles[role].display}</div>
                        <div className={`segmented-control segmented-control-large permissions-setting ${permissionsModel.usingPlatformDefault[toolId][role] ? 'platform-default-active' : 'type-override-active'}`}>
                            <div className={`segmented-control-btn platform-default${permissionsModel.usingPlatformDefault[toolId][role] ? ' active' : ''}`} onclick={() => permissionsModel.resetPermissions(toolId, role, toolAttributes)}>Platform Default</div>
                            <div className={`segmented-control-btn type-override ${permissionsModel.usingPlatformDefault[toolId][role] ? '' : ' active'}`} onclick={() => permissionsModel.showCustomPermissions(toolId, role, toolAttributes)}>Type Override</div>
                        </div>
                        <div class="permissions-rows">
                            {Object.keys(permissionsModel.defaultPermissions[role]).map(access =>
                                <><div className={`permissions-row ${permissionsModel.isPermissionShown(toolAttributes, role, access) ? '' : 'not-shown'}`}>
                                    {permissionsModel.isPermissionShown(toolAttributes, role, access) &&
                                        <><span className="permissions-row-label">{accessTypeToText[access]}</span>
                                            {permissionsModel.isPermissionDisabled(role, access, toolAttributes) ? <span class="disabled-message">{permissionsModel.permissionDisabledMessage(access)}</span> : <div className={`segmented-control permissions-setting segmented-control-small ${!permissionsModel.isPermissionCheckShown(role, access) || !permissionsModel.isPermissionOffShown(role, access) ? 'not-segmented' : ''}`} >
                                                {permissionsModel.isPermissionCheckShown(role, access) &&
                                        <span className={`segmented-control-btn permission-always ${permissionsModel.isOptionChecked(role, access, toolAttributes) ? 'active' : ''}`} onclick={() => permissionsModel.toggleRolePermission(toolId, role, access, toolAttributes)} ><i className="icon-permission-yes"/></span>
                                                }
                                                {permissionsModel.isPermissionOffShown(role, access) &&
                                        <span class={`segmented-control-btn permission-never ${permissionsModel.isOptionChecked(role, access, toolAttributes) ? '' : 'active'}`}
                                            onclick={() => permissionsModel.toggleRolePermission(toolId, role, access, toolAttributes)}><i className="icon-permission-no"/></span>
                                                }
                                            </div>}
                                        </>
                                    }

                                </div>
                                </>
                            )}
                        </div>
                        {permissionsModel.isRoleNoView(role, toolAttributes) &&
                            <> <div className="not-shown-tip">Add New, Edit, and Delete are not supported when View Permission = none.</div>
                            </>
                        }
                        <div class="not-supported-tip">Add New, Edit, and Delete are not supported for the Viewer Role.</div>
                    </div></>
                )}
            </div>
            <div class="only-theirs-footnote">*only theirs: the value of a UserControl = CurrentUser or (when Create is true) Added by = CurrentUser</div>    
        </div>;
    }
};

export default ToolPermissions;

