import React, {useState} from 'react';
import projectsModel from 'models/projects-model';
import {COLUMN_ORDER_ICON_ENUM} from 'util/data/constants';

const ProjectTableHeader = () => {
    const [dateOrder, setDateOrder] = useState(1);
    const [dateOrderIcon, setDateOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[1]);
    const [projectNameOrder, setProjectNameOrder] = useState(0);
    const [projectNameOrderIcon, setProjectNameOrderIcon] = useState(COLUMN_ORDER_ICON_ENUM[0]);

    const handleProjectNameOrderClick = () => {
        if (projectNameOrder === 0) {
            setProjectNameOrder(1);
            setProjectNameOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            projectsModel.sortProjects('name', 0); 
        } else if (projectNameOrder === 1) {
            setProjectNameOrder(2);
            setProjectNameOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            projectsModel.sortProjects('name', 1);
        } else if (projectNameOrder === 2) {
            setProjectNameOrder(0);
            setProjectNameOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            projectsModel.sortProjects('name', 2);
        }
        setDateOrder(0);
        setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);    
    };
    
    const handleDateOrderClick = () => {
        if (dateOrder === 1) {
            setDateOrder(2);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[2]);
            projectsModel.sortProjects('createdDateTime', 1);
        } else if (dateOrder === 2) {
            setDateOrder(0);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[0]);
            projectsModel.sortProjects('createdDateTime', 2);
        } else if (dateOrder === 0) {
            setDateOrder(1);
            setDateOrderIcon(COLUMN_ORDER_ICON_ENUM[1]);
            projectsModel.sortProjects('createdDateTime', 0);
        }
    };

    return (
        <> 
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Name
                    </div>
                    <button className="order-button" onClick={handleProjectNameOrderClick}>
                        {projectNameOrderIcon}
                    </button>
                </div> 
            </td>
            <td>Project ID</td>
            <td>Assets</td>
            <td>Account (GB)</td>
            <td>Version</td>
            <td>
                <div className="flex-box">
                    <div className="cell-label">
                        Created
                    </div>

                    <button className="order-button" onClick={handleDateOrderClick}>
                        {dateOrderIcon}
                    </button>
                </div>
            </td>    
        </>
    );
};
 
const ProjectTableHeaderElement = (props) => React.createElement(ProjectTableHeader, props, null);

export default ProjectTableHeaderElement;
