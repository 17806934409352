import deepCloneObject from 'util/data/deep-clone-object';
import featureControls from 'views/feature-controls';
import toolboxModel from 'models/toolbox-model';
import constants from 'util/data/constants';

const styleConfig = {
    'fill-opacity': {
        layerType: 'fill',
        styleType: 'paint',
        defaultValue: 1,
        featureProp: '_fillOpacity'
    },
    'fill-color': {
        layerType: 'fill',
        styleType: 'paint',
        defaultValue: '#70bfbf',
        featureProp: '_fillColor'
    },
    'line-color': {
        layerType: 'line',
        styleType: 'paint',
        defaultValue: '#70bfbf',
        featureProp: '_lineColor'
    },
    'line-opacity': {
        layerType: 'line',
        styleType: 'paint',
        defaultValue: 1,
        featureProp: '_lineOpacity'
    },
    'line-dasharray': {
        layerType: 'line',
        styleType: 'paint',
        defaultValue: undefined,
        isStatic: true,
        featureProp: '_lineDasharray',
        setProperty: (newValue, tool) => {
            // line-dasharray has a special setProperty handler, because
            // mapbox does not support data-driven styling for this property.
            // In order to give the user a way to choose whether a line should be
            // dashed or not, we create two copies of the style - one that is dashed,
            // and one that isn't, and we hide/show those two layers using a filter
            // expression that reads the _lineDasharray flag from the feature properties.
            const featureStyle = tool.featureStyles.find(s => s.style.type === 'line'),
                featureType = tool.featureTypes[0];
            let extraStyleIndex;
            function findExtraStyle() {
                extraStyleIndex = tool.featureStyles.findIndex(fs => fs !== featureStyle && fs.style.type === 'line');
            }
            findExtraStyle();
            // Reset by removing any existing duplicate line style layers.
            while (extraStyleIndex !== -1) {
                tool.featureStyles.splice(extraStyleIndex, 1);
                findExtraStyle();
            }
            if (featureType.properties.hasOwnProperty('_lineDasharray')) {
                featureStyle.name = tool.name + ' Dashed';
                featureType.properties._lineDasharray = !!newValue;
                featureStyle.style.filter = ['==', '_lineDasharray', true];
                featureStyle.style.paint['line-dasharray'] = [2, 1];
                const solidStyle = deepCloneObject(featureStyle);
                solidStyle.featureStyleId = UE.randomId();
                solidStyle.style.filter = ['!=', '_lineDasharray', true];
                delete solidStyle.style.paint['line-dasharray'];
                solidStyle.name = tool.name;
                tool.featureStyles.push(solidStyle);
            } else {
                featureStyle.name = tool.name;
                if (newValue) {
                    featureStyle.style.paint['line-dasharray'] = [2, 1];
                } else {
                    delete featureStyle.style.paint['line-dasharray'];
                    delete featureStyle.style.filter;
                }
            }
        }
    },
    'line-width': {
        layerType: 'line',
        styleType: 'paint',
        defaultValue: 1.2,
        featureProp: '_lineWidthPx',
        geoFeatureProp: '_lineWidthMeters'
    },
    'text-field': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: 'text',
        featureProp: '_textField'
    },
    'icon-image': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: undefined,
        featureProp: '_iconImage'
    },
    'icon-size': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: 32,
        featureProp: '_iconSizePx',
        geoFeatureProp: '_iconSizeMeters',
        postSetProperty: (tool) => {
            const featureStyle = tool.featureStyles.find(s => s.style.layout && s.style.layout['icon-size']),
                style = featureStyle.style;
            if (toolboxModel.isGeoSizedExpression(style.layout['icon-size'])) {
                style.layout['icon-pitch-alignment'] = 'map';
                style.layout['icon-anchor'] = 'center';
            } else {
                style.layout['icon-pitch-alignment'] = 'viewport';
                style.layout['icon-anchor'] = 'bottom';
            }
        }
    },
    'icon-rotate': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: 0,
        featureProp: '_rotation'
    },
    'text-color': {
        layerType: 'symbol',
        styleType: 'paint',
        defaultValue: '#ffffff',
        featureProp: '_textColor'
    },
    'text-size': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: 20,
        featureProp: '_textSizePx',
        geoFeatureProp: '_textSizeMeters'
    },
    'text-halo-color': {
        layerType: 'symbol',
        styleType: 'paint',
        defaultValue: '#000000',
        featureProp: '_textHaloColor'
    },
    'text-halo-width': {
        layerType: 'symbol',
        styleType: 'paint',
        defaultValue: 1,
        featureProp: '_textHaloWidthPx',
        geoFeatureProp: '_textHaloWidthMeters'
    },
    'text-rotate': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: 0,
        featureProp: '_rotation'
    },
    'text-offset': {
        layerType: 'symbol',
        styleType: 'layout',
        defaultValue: [0, 0],
        featureProp: '_textOffset'
    }
};

const isTextStyle = {
    'text-field': true,
    'text-size': true,
    'text-color': true,
    'text-halo-color': true,
    'text-halo-width': true,
    'text-rotate': true,
    'text-offset': true
};

class StyleModel {

    constructor(tool, styleKey) {
        this.tool = tool;
        this.styleKey = styleKey;
        this.featureType = tool.featureTypes[0];
        this.styleData = styleConfig[styleKey];
        this.featureProp = this.styleData.featureProp; // the internal feature property key for this style, i.e. _iconSizePx or _textField
        this.geoFeatureProp = this.styleData.geoFeatureProp; // the geographically-sized variation of the featureProp, i.e. _iconSizeMeters
        this.isIconSize = styleKey === 'icon-size';
        this.defaultValue = this.styleData.defaultValue;
    }

    // Given a style expression, unwraps any geosizing or icon sizing
    // math to return the fundamental expression value
    getPropValue(expression, isIconSize) {
        if (expression) {
            expression = toolboxModel.unGeoSizeExpression(expression, isIconSize);
            return isIconSize && expression[0] === '/' ? expression[1] : expression;
        }
        return expression;
    }

    // A "styled control" is a control whose value is referenced from within a feature style.
    // In order for mapbox to be able to read the value, it must be present on the feature
    // properties (not just the asset properties). To facilitate this, client apps are responsible
    // for ensuring that any controls listed in featureType.attributes.styledControls are always
    // in sync between the asset properties and the feature properties.
    addStyledControl(featureType, controlLabel) {
        if (controlLabel) {
            featureType.attributes.styledControls = featureType.attributes.styledControls || [];
            if (!featureType.attributes.styledControls.includes(controlLabel)) {
                featureType.attributes.styledControls.push(controlLabel);
            }
        }
    }

    // Returns a tally of the number of style rules
    // that are linked to the given control
    countLinkedStyles(controlLabel, tool) {
        let linkedStyleCount = 0;
        tool.featureStyles.forEach(featureStyle => {
            ['layout', 'paint'].forEach(styleType => {
                Object.keys(featureStyle.style[styleType]).forEach(key => {
                    if (toolboxModel.getMatchedControl(key, featureStyle.style[styleType][key]) === controlLabel) {
                        linkedStyleCount++;
                    }
                });
            });
        });
        return linkedStyleCount;
    }

    // The toolbox editor attempts to use the toolbox configuration itself as the source of truth for the UI state.
    // This means that every change the user makes is written directly to the toolbox itself (not stored in a state object)
    // And after every change, the data that the UI depends on is read back from the toolbox itself.

    // This approach makes it significantly easier to handle situations where one part of the toolbox needs to be updated
    // because another part of the toolbox was changed. No matter what the user just did, we assume that every aspect of the 
    // style configuration should be re-verfied and updated if necessary.

    // To that end, on every redraw we call styleModel.getState(), which contains all of the logic necessary to parse any possible
    // style expression AND to modify that style as necessary if something it depends on has changed.
    getState() {

        const {
            styleKey,       // i.e. fill-opacity, line-width, etc
            tool,           // the tool record
            featureType,    // the featureType record from the tool
            featureProp,    // the internal feature property key for this style, i.e. _iconSizePx or _textField
            geoFeatureProp, // the geographically-sized variation of the featureProp, i.e. _iconSizeMeters
            styleData,      // the relevant configuration blob from the styleConfig object above
            isIconSize,     // convenience boolean (styleKey === 'icon-size')
            defaultValue    // the default value for this style property
        } = this;

        // Most tools have a single style. If there are multiple, it's probably because this tool has a text label.
        const styles = tool.featureStyles.map(f => f.style),
            // If this tool does have a label style, save a reference to it
            labelStyle = styles.length > 1 && styles.find(s => s.layout && s.layout.hasOwnProperty('text-field')),
            hasMultipleStyles = styles.length > 1 && !labelStyle;

        // We can skip everything else if this style is not actually going to be rendered
        if (isTextStyle[styleKey] && featureType.attributes.interface !== 'text' && !labelStyle) {
            return {
                isHidden: true
            };
        }

        // After the user changes the value of any style option in the UI,
        // the setProperty function is responsible for applying that change
        // to the toolbox configuration.
        const setProperty = (newValue, shouldBeGeoSized) => {
            newValue = this.getPropValue(newValue, isIconSize);
            // If we're editing a text style and a label feature style exists,
            // assume we should operate on the label's feature style
            if (isTextStyle[styleKey] && labelStyle) {
                style = labelStyle;
            }
            // Use a custom style property setter, if present
            if (styleData.setProperty) {
                styleData.setProperty(newValue, tool);
            // If a style property appears in featureType.properties, it means
            // that end users should be able to edit that property on individual
            // features (so it won't be the same for all features).
            // To support this, we want to store the default value of the property
            // in featureType.properties, and then update the style configuration
            // to read the value from the feature properties.
            } else if (featureType.properties.hasOwnProperty(prop)) {
                if (shouldBeGeoSized) {
                    featureType.properties[geoFeatureProp] = newValue;
                    delete featureType.properties[featureProp];
                    style[styleType][styleKey] = toolboxModel.geoSizeExpression(['get', geoFeatureProp], isIconSize);
                } else {
                    featureType.properties[featureProp] = newValue;
                    delete featureType.properties[geoFeatureProp];
                    style[styleType][styleKey] = toolboxModel.unGeoSizeExpression(['get', featureProp], isIconSize);
                }
            // Otherwise, we're dealing with a normal style property that will be the
            // same for all features of this feature type, and we should apply the new
            // value to the style configuration.
            } else {
                if (shouldBeGeoSized) {
                    style[styleType][styleKey] = toolboxModel.geoSizeExpression(newValue, isIconSize);
                } else {
                    style[styleType][styleKey] = toolboxModel.unGeoSizeExpression(newValue, isIconSize);
                }
                // If we had a tool with multiple featureStyles excluding text label featureStyles,
                // this would apply the change to each of the featureStyles. I'm not sure we have
                // any current use cases that depend on this.
                if (hasMultipleStyles) {
                    styles.forEach(_style => {
                        if (_style !== labelStyle && _style.type === styleData.layerType) {
                            _style[styleType] = _style[styleType] || {};
                            _style[styleType][styleKey] = style[styleType][styleKey];
                        }
                    });
                }
            }
            // Some styles have extra work to do after the style value has changed.
            if (styleData.postSetProperty) {
                styleData.postSetProperty(tool);
            }
            return newValue;
        };

        // The setControl function is used to create a dynamic style expression
        // that reads the value of a control. If the controlLabel argument is
        // falsy, it will just clean up any relationship between the control and
        // the style that existed before.
        const setControl = controlLabel => {
            // If this style has a linked control and no other styles were also
            // linked to this control, then remove the link.
            if (matchedControl && this.countLinkedStyles(matchedControl, tool) === 1) {
                toolboxModel.removeControl('styled', featureType, matchedControl);
            }
            this.addStyledControl(featureType, controlLabel);
            if (controlLabel) {
                // If a style reads a control value, then it can't be directly edited by end users,
                // and therefore should not appear in the featureType properties.
                delete featureType.properties[prop];
                const newField = tool.assetForm.assetType.fields.find(f => f.name === controlLabel);
                // If the field expects an enum, set up a match expression to create a style
                // that will vary based on which enum value is selected.
                if (newField.type.values || newField.type.items && newField.type.items.values) {
                    setProperty(['match', ['get', controlLabel], undefined], this.isGeoSized);
                // Otherwise, just use a getter expression to read the control's value.
                } else {
                    setProperty(['get', controlLabel], this.isGeoSized);
                }
            } else {
                setProperty(defaultValue, this.isGeoSized);
            }
        };

        const styleType = styleData.styleType,
            editableControls = this.tool.assetForm.controls.filter(control =>
                !toolboxModel.isCommentsOrLinks[control.controlTypeId] && control.controlTypeId !== constants.controlTypeNameToId.project);

        let style;

        // If the tool has multiple styles, figure out which one to operate on.
        // We assume that any style with a text-field is a label, and any other
        // style can be treated as the "main" style for the feature.
        if (labelStyle && isTextStyle[styleKey]) {
            style = labelStyle;
        } else {
            style = styles.length === 1
                ? styles[0]
                : styles.find(s => s.type === styleData.layerType && !(s.layout && s.layout.hasOwnProperty('text-field')));
        }

        style.paint = style.paint || {};
        style.layout = style.layout || {};

        let value = style[styleType][styleKey];

        this.isGeoSized = toolboxModel.isGeoSizedExpression(value);

        // unGeoSizedValue holds the expression for this style property, but "unwrapped" so that
        // it doesn't contain the logic for altering the value based on latitude
        let unGeoSizedValue = toolboxModel.unGeoSizeExpression(value, isIconSize);

        // matchedControl is the label of the control that this style reads from, if any.
        let matchedControl = toolboxModel.getMatchedControl(styleKey, unGeoSizedValue),
            // field is the record from assetType.fields that corresponds to the matchedControl
            field,
            // if field is an enum, its values are referenced here:
            values = [];

        if (matchedControl) {
            field = tool.assetForm.assetType.fields.find(f => f.name === matchedControl);
            values = field && (field.type.values || field.type.items && field.type.items.values);
        }

        // If this style property's value has been removed, reset it to its default
        if (value === undefined && defaultValue !== undefined) {
            value = defaultValue;
            unGeoSizedValue = toolboxModel.unGeoSizeExpression(value, isIconSize);
            setProperty(defaultValue, false);
        }

        // If we found a matchedControl but no corresponding field, reset to default.
        // Similarly, if the style is a match expression but the field is not an enum,
        // also reset to default.
        if (matchedControl) {
            if (!field || value && value[0] === 'match' && (!values || !values.length)) {
                this.isGeoSized = false;
                matchedControl = '';
                value = setProperty(defaultValue, false);
                unGeoSizedValue = toolboxModel.unGeoSizeExpression(value, isIconSize);
            }
        }

        // If the style rule we're working with is a match expression,
        // verify that each stop in the expression matches a value
        // in the field type, and vice versa
        if (unGeoSizedValue && unGeoSizedValue[0] === 'match') {
            let matchValue = defaultValue;
            if (matchValue === undefined) {
                // Per the mapbox style spec, the last item in a match expression
                // array is the default value for the style rule
                matchValue = unGeoSizedValue[value.length - 1];
            }
            const newUnGeoSizedValue = ['match', unGeoSizedValue[1]];
            values.forEach((stop, i) => {
                // If the expression is ["match", ["get", "name"], "Harry": "green", "Hermione": "red", "blue"]
                // Then there are two stops: ("Harry": "green") and ("Hermione": "red")
                // the stopIndex for Harry is 2, because that name is the 3rd item in the expression
                // the stopValueIndex for Harry is 3, because the value for the Harry stop ("green") is the 4th item
                // the stopIndex for Hermione is 4, because that name is the 5th item in the expression
                // the stopValueIndex for Hermione is 5, because the value for the Hermione stop ("red") is the 6th item
                // "blue" is the default value, for when the name is neither Harry nor Hermione
                const oldStopIndex = unGeoSizedValue.indexOf(stop);
                const stopIndex = i * 2 + 2;
                const stopValueIndex = stopIndex + 1;
                newUnGeoSizedValue[stopIndex] = stop;
                // if this stop didn't exist before...
                if (oldStopIndex === -1) {
                    // if there was a previous value for the stop at this index, use it
                    if (unGeoSizedValue[stopValueIndex] !== undefined) {
                        newUnGeoSizedValue[stopValueIndex] = unGeoSizedValue[stopValueIndex];
                    // otherwise, use the default stop value
                    } else {
                        newUnGeoSizedValue[stopValueIndex] = matchValue;
                    }
                // if this stop did exist before, retain its value
                } else {
                    newUnGeoSizedValue[stopValueIndex] = unGeoSizedValue[oldStopIndex + 1];
                }
            });
            // set the fallback value (the last item in the expression array)
            const fallbackIndex = values.length * 2 + 2;
            // If a fallback has not been set...
            if (unGeoSizedValue[fallbackIndex] === undefined
                // ...OR a default value has been provided...
                || tool.assetForm.controls.find(c => c.label === matchedControl).default) {
                // ...then set the match expression's fallback to the first stop value
                newUnGeoSizedValue[fallbackIndex] = newUnGeoSizedValue[3];
            } else {
                newUnGeoSizedValue[fallbackIndex] = unGeoSizedValue[fallbackIndex];
            }
            this.isGeoSized = this.isGeoSized || toolboxModel.isGeoSizedExpression(newUnGeoSizedValue[3]);
            // sync the new match expression to the old one
            newUnGeoSizedValue.forEach((item, i) => {
                unGeoSizedValue[i] = item;
            });
            // If a value was removed, we need to remove the corresponding match expression stops
            while (newUnGeoSizedValue.length < unGeoSizedValue.length) {
                unGeoSizedValue.splice(unGeoSizedValue.length - 2, 1);
            }
        }

        // prop is just a shortcut to either featureProp or geoFeatureProp,
        // whichever we should be using based on whether the user has chosen
        // to use pixel-sizing or geo-sizing for this style rule.
        // As a reminder, featureProp is the internal style property name
        const prop = this.isGeoSized ? geoFeatureProp : featureProp,
            featureControl = featureControls[prop],
            featureProperties = {
                [prop]: featureType.properties.hasOwnProperty(prop)
                    ? featureType.properties[prop]
                    : this.getPropValue(value, isIconSize)
            };

        // Whew, okay! This is everything we need to render a style UI:
        const state = {
            featureControl,
            featureType,
            editableControls,
            value,
            featureProperties,
            setProperty,
            prop,
            matchedControl,
            isIconSize,
            setControl,
            values,
            unGeoSizedValue,
            isGeoSized: this.isGeoSized,
            isStatic: styleData.isStatic
        };

        return state;

    }

}

export default StyleModel;

export {styleConfig};

