import constants from 'util/data/constants';
import Upload from 'views/upload';
import uploadModel from 'models/upload-model';

const Icon = {
    oninit: ({attrs: {icon, isToolgroup = false}, state}) => {
        state.iconWithMedia = icon && icon.mediaId ? icon : undefined;
        state.mediaId = state.iconWithMedia && state.iconWithMedia.mediaId;
        state.imageSource = state.mediaId ? `${constants.mediaServerUrl + state.mediaId}` : undefined;
        state.isToolgroup = isToolgroup;
        state.done = (_mediaId) => {
            if (Array.isArray(_mediaId)) {
                icon.mediaId = state.mediaId = _mediaId[0];
            } else {
                icon.mediaId = state.mediaId = _mediaId;
            }
            state.imageSource = `${constants.mediaServerUrl + _mediaId}`;
            m.redraw();
        };
        state.retryCount = 0;
    },
    handleError: state => {
        if (state.retryCount < 10) { // Give up after 10, arbitrary limit 
            const timestamp = new Date().getTime();
            state.imageSource += `?t=${timestamp}`;
            state.retryCount += 1;
            m.redraw(); 
        }
    },
    validate: state => e => {
        state.tooLarge = e.target.naturalWidth > 256;
        state.aspectRatio = e.target.naturalWidth / e.target.naturalHeight;
        m.redraw();
    },
    view: ({state, attrs: {mediaId}}) => {
        return <div class="icon-container">
            {mediaId
                ? <img class="icon-thumbnail" src={constants.mediaServerUrl + mediaId} onerror={() => Icon.handleError(state)} onload={Icon.validate(state)} />
                : <span class="icon-placeholder">{state.isToolgroup ? 'category icon (optional)' : 'icon required'}</span>}
            <Upload accept="image/*" mediaId={state.iconWithMedia && state.iconWithMedia.mediaId} done={state.done} />
            {state.tooLarge && <p class="icon-too-large"><div class="btn btn-small btn-error" onclick={() => uploadModel.resizeImage(state.mediaId, state.done, state.aspectRatio)}>Icon is too large. Click to fix.</div></p>}

        </div>;
    }
};

export default Icon;
