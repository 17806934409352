import router from 'uav-router';
import prune from 'util/data/prune';
import modal from 'views/modal';
import projectsModel from 'models/projects-model';
import accountModel from 'models/account-model';
import list from 'util/data/list';

class ProjectModel {

    constructor() {
        this.reset();
        this.paginateOpts = {
            type: 'project',
            dataModel: this
        };
    }

    reset() {
        this.project = {
            users: []
        };
        this.toolboxes = [];
    }

    fetchToolboxes() {
        UE.rpc([['listProjectToolboxes', {
            projectId: router.params.projectId
        }]]).then(([toolboxes]) => {
            this.toolboxes = toolboxes;
            m.redraw();
        });
    }

    fetch() {
        this.reset();
        return UE.rpc([['getProject', {
            projectId: router.params.projectId,
            include: ['users']
        }]]).then(([project]) => {
            this.project = prune.project(project);
            m.redraw();
        });
    }

    updateProject(payload) {
        const {name} = this.project;
        this.loading = true;
        UE.rpc([['modifyProject', {   
            projectId: this.project.projectId,
            name: payload.name || name
        }]]).then(([updatedProjectInfo]) => {
            this.project.name = updatedProjectInfo.name;
        }).catch(() => {
            this.modifyProjectError = {
                message: 'Error',
                details: 'There was a problem processing your request. Please try again.'
            };
        }).finally(() => {
            this.loading = false;
            m.redraw();
            modal.close();
        });
    }

    deleteProject(projectId) {
        this.loading = true;
        UE.rpc([['deleteProject', {
            projectId
        }]])
            .then(([selectedProject]) => { 
                const selectedProjectId = selectedProject.projectId;
                if (selectedProjectId === this.project.projectId) {
                    const projects = projectsModel.projects;
                    if (projects) {
                        const index = projects.findIndex((project) => project.projectId === projectId);
                        if (index !== -1) {
                            projects.splice(index, 1);
                        }
                    }
                }
                modal.close();
                router.set({view: 'account', accountId: selectedProject.accountId});
            }).catch(() => {
                this.deleteProjectError = {
                    message: 'Error',
                    details: 'There was a problem processing your request. Please try again.'
                };
            }).finally(() => {
                this.loading = false;
                m.redraw();
            });
    }

    addUserToProject(userId, state) {
        state.processing[userId] = true;
        UE.rpc([['addProjectUser', {
            projectId: this.project.projectId, 
            userId,
            role: this.project.role || 'general'
        }]]).then(([projectRecord]) => {
            const currentUser = projectRecord.users.items.find((user) => user.userId === userId);
            this.project.users.unshift(currentUser);
            state.userMap[userId] = currentUser;
        }).catch(() => {
            this.addExistingUserError = {
                message: 'Error',
                details: 'There was a problem adding this user. Please try again.'
            };
        }).finally(() => {
            delete state.processing[userId];
            m.redraw();
        });
    }

    addExistingUser(user, state) {
        const userId = user.userId;
        const matchingUserInAccountModel = accountModel.account.users.find((accountUser) => accountUser.userId === userId);
        if (matchingUserInAccountModel === undefined) {
            accountModel.addUserToAccountIfNotOnProject(this.project.accountId, userId, state).then(() => {
                this.addUserToProject(userId, state);
            });
        } else {
            return this.addUserToProject(userId, state);
        }
    }

    removeProjectUser(userId) {
        this.loading = true;
        return UE.rpc([['removeProjectUser', {
            projectId: this.project.projectId,
            userId
        }]]).then(([modifiedProject]) => {
            const projectId = modifiedProject.projectId;
            if (projectId === this.project.projectId) {
                const users = this.project.users;
                const index = users.findIndex((user) => user.userId === userId);
                if (index !== -1) {
                    users.splice(index, 1);
                }
            }
            modal.close();
        }).catch((e) => {
            console.error(e);
            this.removeProjectUserError = {
                message: 'Error',
                details: 'There was a problem removing this user. Please try again.'
            };
        }).finally(() => {
            this.loading = false;
            m.redraw();
        });
    }

    addNewUser(state) { 
        const projectAccountId = this.project.accountId;
        const projectId = this.project.projectId;
        return accountModel.addUserToAccountBeforeProject(state, projectAccountId).then(([accountUser]) => {
            return UE.rpc([['addProjectUser', {
                projectId,
                userId: accountUser.userId,
                role: 'general'
            }]]).then(([project]) => {
                modal.close();
                const projectUser = list(project.users).find(user => user.userId === accountUser.userId);
                this.project.users.unshift(projectUser);
                m.redraw();
            });
        });
    }
}

export default new ProjectModel();
