import collapsibleModel from 'models/collapsible-model';
import toolboxModel from 'models/toolbox-model';
import deepCloneObject from 'util/data/deep-clone-object';

class SortableModel {

    constructor() {
        this.isReordering = false;
        this.movingIndex = undefined;
        this.movingXid = undefined;
        this.movingItemInToolGroupId = undefined;
        this.reorderingToolId = undefined;
        this.isReorderingToolId = false;
    }

    toggleReorderingMode() {
        this.clearAll();
        this.isReordering = !this.isReordering;
        m.redraw();
    }

    togglePropertiesReorderingMode(toolId, group) {
        this.clearAll();
        this.isReordering = false;
        this.reorderingToolId = toolId;
        this.isReorderingToolId = !this.isReorderingToolId;
        this.group = group;
        m.redraw();
    }

    // Dont show drop zone of current location (before or after item)
    isDropZoneOfCurrentItem(index) {
        return this.movingIndex === index || this.movingIndex - 1 === index;
    }


    // Dont show drop zone of current location (before or after item)
    isReorderingItem(xid) {
        return this.movingXid === xid;
    }

    isReorderingProperties(toolId) {
        return this.isReorderingToolId && this.reorderingToolId === toolId;
    }

    isADroppableZone(index, toolGroupId = null) {
        // No item has been selected, dont show any drop zones
        if (!this.movingXid) {
            return false;
        }
        if (toolGroupId) {
            // Only show drop zones within tool group when moving a tool
            if (this.movingItemInToolGroupId !== toolGroupId) {
                return false;
            }
        } else if (this.movingItemInToolGroupId) {
            // We're moving a tool but this zone is for a tool group
            return false;
        }
        // Dont show drop zone of current location (before or after)
        return !this.isDropZoneOfCurrentItem(index);
    }

    handleClick(e, index, xid, toolGroupId = undefined) {
        if (this.movingXid === xid) {
            // quit, they reclicked the same item again
            return this.clearAll();
        }

        e.stopPropagation();
        e.preventDefault();

        // they clicked a different element, clear out & select new element 
        this.clearAll();
        
        this.movingIndex = index;
        this.movingXid = xid;
        this.movingItemInToolGroupId = toolGroupId;
        
        if (this.movingItemInToolGroupId) {
            collapsibleModel.expandItem(this.movingItemInToolGroupId); // Make sure category is expanded first
        }
        m.redraw();
    }

    handleClickDropZone(e, index) {

        if (this.movingXid === null || this.movingXid === undefined) {
            return;
        }

        let collection, allGroups, hiddenGroups, hiddenTools, toolGroup;

        if (this.isReorderingToolId) {
            // Reordering properties
            collection = this.group;
        } else {
            // Reordering anchor menu
            hiddenGroups = toolboxModel.toolbox.group.groups.filter(g => g.attributes && g.attributes.hidden);
            allGroups = toolboxModel.toolbox.group.groups.filter(g => !g.attributes || !g.attributes.hidden);
            collection = deepCloneObject(allGroups);
            if (this.movingItemInToolGroupId) {
                // Reordering within a tool group
                toolGroup = allGroups.find(tg => tg.toolGroupId === this.movingItemInToolGroupId);
                hiddenTools = toolGroup.tools.filter(t => t.attributes && t.attributes.hidden);
                collection = toolGroup.tools.filter(t => !t.attributes || !t.attributes.hidden);
            }
        }


        const newIndex = this.movingIndex < index ? index : index + 1;
        const [value] = collection.splice(this.movingIndex, 1);
        collection.splice(newIndex, 0, value);

        // Put back hidden items if there were any removed
        if (allGroups) {
            if (toolGroup) {
                toolGroup.tools = collection;
                if (hiddenTools) {
                    collection.push(...hiddenTools);
                }
            } else {
                if (hiddenGroups) {
                    collection.push(...hiddenGroups);
                }
                toolboxModel.toolbox.group.groups = collection;
            }
        }


        this.clearAll();

        e.preventDefault();
        e.stopPropagation();
        m.redraw();

    }
   
    clearAll() {
        this.movingIndex = undefined;
        this.movingXid = undefined;
        this.movingItemInToolGroupId = undefined;
        m.redraw();
    }

    moveItem(list, itemIndex, toIndex) {
        const [value] = list.splice(itemIndex, 1);
        list.splice(toIndex, 0, value);
        m.redraw();
    }
}

export default new SortableModel();
