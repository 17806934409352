let permanentCallbacks = [];
let temporaryCallbacks = [];

const reset = () => {

    permanentCallbacks = [];

    temporaryCallbacks = [];

};

const clear = () => {

    temporaryCallbacks = [];

};

const click = e => {

    permanentCallbacks.forEach(fn => fn(e));

    temporaryCallbacks.forEach(fn => fn(e));

    clear();

};

window.addEventListener('click', click);

const always = callback => {

    requestAnimationFrame(() => permanentCallbacks.push(callback));

};

const once = callback => {

    requestAnimationFrame(() => temporaryCallbacks.push(callback));

};

export default {
    always,
    once,
    clear,
    reset
};
