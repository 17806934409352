import userModel from 'models/user-model';
import router from 'uav-router';
import paginate from 'util/data/paginate';
import modal from 'views/modal';
import ToolboxPicker from 'views/toolbox-picker';
import toolboxModel from 'models/toolbox-model';
import prune from 'util/data/prune';
import list from 'util/data/list';
import {addRumAccountContexts} from 'util/tracking/datadog';
import api from 'util/api/api';
import ToolboxPickerModel from 'models/toolbox-picker-model';

class AccountModel {

    constructor() {

        this.reset();
        this.paginateOpts = {
            type: 'account',
            dataModel: this
        };
        this.lastFetchedId = undefined;
    }

    reset() {
        this.account = {
            users: []
        };
        this.projects = [];
        this.isInitialized = false;
        this.toolboxPicker = new ToolboxPickerModel();
    }

    get toolboxes() {
        if (this.toolboxPicker) {
            return this.toolboxPicker.toolboxes;
        }
        return [];
    }
 
    // Debug code for finding shared asset types:

    // const assetTypeToolboxes = {};
    // bases.forEach(toolbox => {
    //     toolbox.group.groups.forEach(group => group.tools.forEach(tool => {
    //         const assetType = tool.assetForm.assetType;
    //         assetTypeToolboxes[assetType.assetTypeId] = assetTypeToolboxes[assetType.assetTypeId] || [];
    //         assetTypeToolboxes[assetType.assetTypeId].push({
    //             assetType: assetType.name,
    //             toolbox: toolbox.name
    //         });
    //     }));
    // });
    // let count = 0;
    // Object.keys(assetTypeToolboxes).forEach(assetTypeId => {
    //     if (assetTypeToolboxes[assetTypeId].length > 1) {
    //         count++;
    //         console.log(assetTypeId);
    //     }
    // });
    // console.log(count);

    //     const toolboxes = [...bases, ...variants];
    //     this.toolboxes = toolboxes.reverse();

    //     m.redraw();
    // });
    // }

    fetchProjects(order) {
        paginate({
            type: 'project',
            filter: {
                accountId: router.params.accountId,
                isVisible: true,
                order: order ? order : 'createdDateTime desc'
            },
            include: 'users',
            data: this.projects
        });
    }

    fetch(accountId) {
        this.loading = true;
        this.reset();
        UE.rpc([['getAccount', {
            accountId: accountId || router.params.accountId,
            include: ['users']
        }]]).then(([account]) => {
            this.account = prune.account(account);
            this.isInitialized = true;
            this.loading = false;
            addRumAccountContexts(account);
            m.redraw();
        });
    }

    provisionToolbox() {
        modal(ToolboxPicker, {
            isBase: true,
            select: 'toolbox',
            onSelect: _toolbox => {
                UE.rpc([['getToolbox', {toolboxId: _toolbox.toolboxId || _toolbox.id}]]).then(([toolbox]) => {
                    const baseToolboxId = toolbox.base ? toolbox.base.toolboxId : toolbox.toolboxId;                    
                    return UE.rpc([['provisionToolbox', {
                        targetAccountId: this.account.accountId,
                        baseToolboxId
                    }]]).then(() => this.toolboxPicker.fetchToolboxes(true))
                        .then(() => modal.close());
                });
                modal({
                    view: () => <div style="text-align: center;">Provisioning your toolkit... <i class="spinner spinning gray" /></div>
                });
            }
        });
    }

    cloneToolbox(toolbox) {
        const toolboxId = toolbox.id || toolbox.toolboxId;
        const proceed = () => {
            modal.close();
            UE.rpc([['listToolboxes', {toolboxId, limit: 1}]]).then(([[_toolbox]]) => {
                toolboxModel.pendingToolbox = toolboxModel.cloneToolbox(_toolbox);
                router.merge({
                    view: 'toolbox',
                    accountId: toolboxModel.getAccountId()
                });
            });
        };

        modal({
            view: () =>
                <div class="clone-dialog">
                    <p>Cloning "{toolbox.name}" will create a new base toolbox, but will not add that toolbox to any accounts. </p>
                    <p>To add a toolbox to an account, navigate to the account's page and click "Provision a toolbox for this account".</p>
                    <div class="btn btn-secondary" onclick={() => modal.close()}>Cancel</div>
                    <div class="btn" onclick={proceed}>Continue</div>
                </div>
        });
        
    }

    deleteToolbox(toolbox) {
        const proceed = () => {
            modal.close();
            this.toolboxPicker.deleteToolbox(toolbox);
        };

        modal({
            view: () =>
                <div class="clone-dialog">
                    <p>You are about to delete the toolbox: {toolbox.name}. This will completely break all projects that use this toolbox.</p>
                    <p>Are you sure you want to delete {toolbox.name}?</p>
                    <div class="btn btn-secondary" onclick={() => modal.close()}>Cancel</div>
                    <div class="btn btn-red btn-secondary" onclick={proceed}>Delete</div>
                </div>
        });
    }

    addUserToAccount(userId, state) {
        state.processing[userId] = true;
        m.redraw();
        UE.rpc([['addAccountUser', {
            accountId: this.account.accountId,
            userId
        }
        ]]).then(([accountUser]) => {
            const accountId = accountUser.accountId;
            delete state.processing[userId];
            m.redraw();
            if (accountId === this.account.accountId) {
                this.account.users.push(accountUser);
                state.userMap[accountUser.userId] = accountUser;
            }
        }).catch(() => {
            delete state.processing[userId];
            m.redraw();
        });
    }

    addUserToAccountIfNotOnProject(projectAccountId, userId, state, projectId) {
        state.processing[projectId || userId] = true;
        m.redraw();
        return UE.rpc([['addAccountUser', {
            accountId: projectAccountId,
            userId
        }]]).then(([accountUser]) => {

            const accountId = accountUser.accountId;
            const accountUserId = accountUser.userId;
            delete state.processing[userId];
            delete state.processing[projectId];
            m.redraw();
            //project details view logic
            if (accountId === this.account.accountId) {
                this.account.users.push(accountUser);
                state.userMap[accountUser.userId] = accountUser;
            }
            //user details view logic
            if (accountUserId === userModel.user.userId) {
                UE.rpc([['getAccount', {
                    accountId,
                    include: ['users']
                }]]).then(([account]) => {
                    account.users = list(account.users);
                    userModel.accounts.push(account);
                });
            }
        }).catch(() => {
            delete state.processing[projectId || userId];
            m.redraw();
        });
    }

    showError() {
        this.modifyAccountError = {
            message: 'Error',
            details: 'There was a problem processing your request. Please try again.'
        };
    }

    addNewUser(state) {
        const {
            givenName,
            familyName,
            phoneNumber,
            emailAddress,
            company,
            role,
            addProjects
        } = state;

        delete state.createUserError;
        const {accountId} = this.account;
        state.loading = true;
        return UE.rpc([['createUser',
            {userBody: {
                givenName,
                familyName,
                phoneNumber,
                emailAddress,
                company,
                accounts: [{id: accountId, addProjects}]
            }}
        ]]).then(([accountUser]) => {
            if (accountUser.givenName !== givenName ||
                accountUser.familyName !== familyName ||
                accountUser.preferences !== undefined
            ) {
                state.createUserError = {
                    message: 'A user with this information already exists.',
                    details: 'Please try again.'
                };
                m.redraw();
                throw new Error('User Exists');
            }
            return UE.rpc([['modifyAccountUser', {
                accountId,
                userId: accountUser.userId,
                role: role
            }]]);
        }).then(([user]) => {
            modal.close();
            this.account.users.unshift(user);
            m.redraw();
        }).catch(() => {
            state.loading = false;
            if (!state.createUserError) {
                state.createUserError = {
                    message: 'Failed to add new user to account.',
                    details: 'Please try again.'
                };
            }
            m.redraw();
        });
    }

    addUserToAccountBeforeProject(state, projectAccountId) {
        const {
            givenName,
            familyName,
            phoneNumber,
            emailAddress,
            role,
            addProjects
        } = state;

        delete state.createUserError;

        state.loading = true;
        return UE.rpc([['createUser',
            {userBody: {
                givenName,
                familyName,
                phoneNumber,
                emailAddress,
                role,
                accounts: [{id: projectAccountId, addProjects}]
            }}

        ]]).then(([projectUser]) => {
            const accountId = projectUser.accountIds.items[0];
            if (projectUser.givenName !== givenName ||
                projectUser.familyName !== familyName  ||
                projectUser.preferences !== undefined
            ) {
                state.createUserError = {
                    message: 'A user with this information already exists.',
                    details: 'Please try again.'
                };
                m.redraw();
                throw new Error('User Exists');
            }
            return UE.rpc([['modifyAccountUser', {
                accountId,
                userId: projectUser.userId,
                role: role
            }]]);
        }).catch(() => {
            state.loading = false;
            if (!state.createUserError) {
                state.createUserError = {
                    message: 'Failed to add new user to account.',
                    details: 'Please try again.'
                };
            }
            m.redraw();
        });
    }

    updateBrandName(payload) {
        const {accountId} = this.account;
        this.loading = true;
        return api.modifyAccountBrandName(accountId, payload.brandName).then((response) => {
            if (response.errors) {
                response.errors.map(err => console.error('error:', err));
                this.loading = false;
                this.showError();
                return m.redraw();
            }
            this.loading = false;
            this.account.brandName = payload.brandName;
            modal.close();
            m.redraw();
        }).catch(() => {
            this.loading = false;
            this.showError();
            m.redraw();
        });
    }

    updateAccountStatus(payload) {
        const {accountId} = this.account;
        this.loading = true;
        return api.modifyAccountStatus(accountId, payload.status).then(() => {
            this.loading = false;
            this.account.status = payload.status;
            modal.close();
            m.redraw();
        }).catch(() => {
            this.loading = false;
            this.showError();
            m.redraw();
        });
    }

    updateAccount(payload) {
        const {accountId, name, status} = this.account;
        this.loading = true;
        UE.rpc([['modifyAccount', {
            accountId,
            name: payload.name || name,
            status: payload.status || status
        }]]).then(([accountPayload]) => {
            this.loading = false;
            this.account.name = accountPayload.name;
            this.account.status = accountPayload.status;
            modal.close();
            m.redraw();
        }).catch(() => {
            this.loading = false;
            this.showError();
            m.redraw();
        });
    }

    updateAccountUser(userId, role, processing) {
        processing[userId] = true;
        m.redraw();
        UE.rpc([['modifyAccountUser', {
            accountId: this.account.accountId,
            userId,
            role
        }]]).then(() => {
            processing[userId] = false;
            const updatedUser = this.account.users.find(user => userId === user.userId);
            updatedUser.role = role;
            m.redraw();
        }).catch(() => {
            processing[userId] = false;
            this.showError;
            m.redraw();
        });
    }

    refreshChargebeeDetails() {
        this.processing = true;
        UE.rpc([['refreshAccountInfo', {
            accountId: this.account.accountId
        }]]).then(([account]) => {
            this.account.seats = account.seats;
            this.account.storageQuotaGB = account.storageQuotaGB;
        }).catch(() => {
            this.chargeBeeError = {
                message: 'Error',
                details: 'There was a problem processing your request. Please try again.'
            };
        }).finally(() => {
            this.processing = false;
            m.redraw();
        });
    }

    accountUserSearch(query) {
        query = query.toLowerCase().trim();
        if (query === '') {
            return this.account.users;
        }
        const splitTerms = query.split(/\s+/g);
        const doesMatch = (str, term) => str && str.toLowerCase().startsWith(term);
        const userRelevance = [];
        this.account.users.forEach(user => {
            const relevance = splitTerms.filter(term =>
                doesMatch(user.givenName, term)
                || doesMatch(user.familyName, term)
                || doesMatch(user.emailAddress, term)
                || doesMatch(user.userId, term)
            ).length;
            if (relevance) {
                userRelevance[relevance] = userRelevance[relevance] || [];
                userRelevance[relevance].push(user);
            }
        });
        return userRelevance.reverse().flat();
    }

    removeUserFromAccount(userId) {
        m.redraw();
        this.loading = true;
        UE.rpc([['removeAccountUser', {
            accountId: this.account.accountId,
            userId
        }]]).then(([modifiedAccount]) => {
            const accountId = modifiedAccount.accountId;
            if (accountId === this.account.accountId) {
                const users = this.account.users;
                const index = users.findIndex((user) => user.userId === userId);
                if (index !== -1) {
                    users.splice(index, 1);
                }
            }
            modal.close();
        }).catch(() => {
            this.removeUserError = {
                message: 'Error',
                details: 'There was a problem removing this user. Please try again.'
            };
        }).finally(() => {
            this.loading = false;
            m.redraw();
        });
    }
   
    sortToolboxProject(column, order) {
        this.projects = [];
        if (column === 'name' && order === 1) { 
            this.fetchProjects('name asc');
        } else if (column === 'name' && order === 0) {
            this.fetchProjects('name desc');
        } else if (column === 'name' && order === 2) {
            this.fetchProjects('createdDateTime desc');
        } else if (column === 'createdDateTime' && order === 1) {
            this.fetchProjects('createdDateTime asc');
        } else if (column === 'createdDateTime' && order === 2) {
            this.fetchProjects('createdDateTime desc');        
        } else if (column === 'createdDateTime' && order === 0) {
            this.fetchProjects('createdDateTime desc');
        }

        m.redraw();
    }
    
}

export default new AccountModel();
