import {authManager} from '@procore/sdt-core-js';

(() => {

    let requests = {},
        changeHandlers = {};

    function on(changeType, recordType) {

        let test,
            handler;

        function abort() {
            const handlers = changeHandlers[changeType] && changeHandlers[changeType][recordType];
            if (handlers) {
                handlers.splice(handlers.indexOf(handler), 1);
            }
        }

        changeHandlers[changeType] = changeHandlers[changeType] || {};
        changeHandlers[changeType][recordType] = changeHandlers[changeType][recordType] || [];
        changeHandlers[changeType][recordType].push(change => {

            if (!test || test(change) && handler) {
                handler(change);
            }

        });

        const methods = {
            abort,
            if: _test => {
                test = _test;
                return methods;
            },
            then: _handler => {
                handler = _handler;
                return methods;
            }
        };

        return methods;

    }

    function once(changeType, recordType) {
        const methods = on(changeType, recordType);
        return {
            abort: methods.abort,
            if: methods.if,
            then: handler => {
                methods.then(change => {
                    handler(change);
                    methods.abort();
                });
            }
        };
    }

    const digits = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '-', '_'];

    function randomId() {

        const id = [];

        for (let i = 0; i < 20; i++) {

            id.push(digits[Math.floor(Math.random() * digits.length)]);

        }

        return id.join('');

    }

    const socket = {

        send: msg => new Promise((resolve, reject) => {

            msg.msgId = randomId();

            msg.sessionId = socket.sessionId;

            requests[msg.msgId] = {
                resolve,
                reject
            };

            socket.ws.send(JSON.stringify(msg));

        }),

        connect(token) {

            const wsDomain = {
                local: 'ws://localhost:8083',
                development: 'wss://ws-dev.unearthlabs.com:443',
                staging: 'wss://ws-staging.unearthlabs.com:443',
                production: 'wss://ws.unearthlabs.com:443'
            }[UE.environment];

            socket.ws = new WebSocket(wsDomain);

            socket.ws.addEventListener('message', e => {

                const data = JSON.parse(e.data);

                if (!data.payload || data.payload.error || data.success === false || data.payload.errorCount) {

                    if (data.requestId && requests[data.requestId]) {

                        requests[data.requestId].reject(data);

                        delete requests[data.requestId];

                    } else if (socket.sessionId) {

                        if (UE.onError) {

                            UE.onError(data);

                        }

                    } else if (UE.onDisconnect) {

                        UE.onDisconnect(true);

                    }

                } else if (data.type === 'pong') {

                    // noop

                } else if (data.payload.changes) {

                    data.payload.changes.forEach(change => {

                        const handlers = changeHandlers[change.changeType] && changeHandlers[change.changeType][change.recordType];

                        if (handlers) {

                            handlers.forEach(handle => handle(change));

                        }

                    });

                } else if (!socket.sessionId) {

                    socket.sessionId = data.payload.sessionId;

                    localStorage.setItem('env', UE.environment);

                    clearInterval(socket.interval);

                    socket.interval = setInterval(() => {

                        if (socket.ws.readyState === WebSocket.OPEN) {

                            socket.send({
                                type: 'ping',
                                sessionId: socket.sessionId
                            });

                        } else {

                            socket.connect();

                        }

                    }, 20000);

                    if (UE.onConnect) {
                        UE.onConnect(authManager.userId);
                        
                    }

                } else if (data.requestId && requests[data.requestId]) {

                    const results = data.payload.results || data.payload.body && data.payload.body.results;

                    requests[data.requestId].resolve(results);

                    delete requests[data.requestId];

                }

            });

            socket.ws.addEventListener('open', async () => {

                if (token) {

                    socket.send({
                        type: 'reconnect',
                        token
                    });

                } else {

                    delete socket.sessionId;
                    await authManager.reconnect();
                
                    socket.send({
                        type: 'connect',
                        token: authManager.getToken()
                    });

                }

            });

        }

    };

    function clearRequests() {
        requests = {};
        changeHandlers = {};
    }

    window.UE = {
        on,
        once,
        randomId,
        socket,
        clearRequests
    };

    UE.rpc = rpc => socket.send({
        type: 'request',
        payload: {
            version: '3.9',
            platform: 'web',
            rpc
        }
    });

    UE.logout = () => {
        authManager.userId = null;
        clearRequests();

        clearInterval(socket.interval);

        if (socket.ws) {

            socket.ws.close();

        }

        socket.ws = null;
        socket.sessionId = null;
        localStorage.removeItem('env');

        authManager.signOut();

        if (UE.onDisconnect) {

            UE.onDisconnect();

        }

    };

    UE.login = () => {
        const token =  authManager.getToken();
        if (token) {
            socket.connect(token);
        } else {
            if (UE.onDisconnect) {
                UE.onDisconnect(true);
            }
        }
    };

    UE.init = (environment = 'development') => {

        UE.environment = environment;

        if (localStorage.getItem('ue_refresh') && environment === localStorage.getItem('env')) {

            socket.connect();

        } else {

            UE.logout();

        }

    };

})();
