import toolboxModel from 'models/toolbox-model';
import SortableOption from 'views/multiselect/sortable-option';
import constants from 'util/data/constants';
import ExpressionEditor from 'views/expression-editor/expression-editor';
import formControls from 'views/form-controls';
import AddButton from 'views/add-button';
import SharedControlTag from 'views/toolbox/shared-control-tag';

// Special case where we will enable linking to a calculated field 
// Typically we don't allow linking to calculated fields because 
// it may result in an infinite loop of re-evaluating the expression
// This is a special exception we're making for project names, which 
// will not reevaluate bc the project name is not a property on this asset.
// UE-6976
export const isNameControlForMetaAsset = ({control, tool, featureType}) => {
    if (!featureType && tool) {
        featureType = tool.featureTypes && tool.featureTypes[0] && tool.featureTypes[0];
    }
    const isProjectTool = featureType.attributes && featureType.attributes.interface === 'project';
    const isNameControl = control.controlTypeId === constants.controlTypeNameToId.name;
    return toolboxModel.isMetaProject() && isProjectTool && isNameControl;
};

const Control = {
    view: ({attrs: {control, field, tool}}) => {
        const featureType = tool.featureTypes && tool.featureTypes[0],
            linkedControls = featureType && featureType.attributes.linkedControls,
            values = field.type && (field.type.values || field.type.items && field.type.items.values),
            linkDescription = featureType && toolboxModel.getLinkDescription(featureType, control);
        const availableControls = toolboxModel.getAvailableControls(tool.assetForm.controls, control.controlTypeId);
        const isEvalPresent = !!control.attributes.eval;
        const showLinkedControls = !isEvalPresent || isNameControlForMetaAsset({control, tool});
        return <div>
            {control.controlTypeId === constants.controlTypeNameToId.project
                ? null
                : <fieldset class="property-hidden-toggle">
                    <input class="editable-when-published" type="checkbox" onclick={e => {
                        if (e.target.checked) {
                            control.attributes.hidden = true;
                        } else {
                            delete control.attributes.hidden;
                        }
                    }} checked={control.attributes.hidden} />
                    <label class="editable-when-published">Hide this Property</label>
                </fieldset>}

            <fieldset class="has-left-aligned-label">
                <label class="left-aligned-label">Property Type</label>
                <div class="select-wrapper">
                    <select
                        onchange={e => toolboxModel.changeControlType({
                            control,
                            field,
                            tool,
                            controlTypeId: e.target.value
                        })}
                        value={control.controlTypeId}>
                        {availableControls.map(controlTypeId =>
                            !toolboxModel.isCommentsOrLinks[controlTypeId]
                            && !toolboxModel.isEmbedControl(controlTypeId)
                            && <option value={controlTypeId}>{toolboxModel.getControlDisplayName(controlTypeId)}</option>
                        )}
                    </select>
                </div>
            </fieldset>
            {!!values && <fieldset class="option-set">
                <label class="editable-when-published">Options</label>
                <div>
                    {values.map((value, i) =>
                        <SortableOption
                            index={i}
                            collection={values}
                            display={<input type="text" value={value} className="editable-when-published" oninput={e => {
                                const newValue = e.target.value;
                                if (control.attributes.default === value) {
                                    toolboxModel.setDefault(control, field, newValue);
                                }
                                values[i] = newValue;
                                // Update multiselect default options
                                if (Array.isArray(control.attributes.default)) {
                                    const newDefaults = control.attributes.default.filter(val => values.find(v => v === val));
                                    toolboxModel.setDefault(control, field, newDefaults);
                                }
                            }} />}
                            remove={() => {
                                if (control.attributes.default === value) {
                                    toolboxModel.setDefault(control, field);
                                } values.splice(i, 1);
                            }}
                        />
                    )}
                </div>
                <AddButton label="Add Option" onclick={() => values.push('Option ' + (values.length + 1))} />
            </fieldset>}
            {!!formControls[control.controlTypeId] && control.controlTypeId !== constants.controlTypeNameToId.date
                && <fieldset class={linkedControls && linkedControls.indexOf(control.label) !== -1 || isEvalPresent ? 'disabled' : ''}>
                    <label class="editable-when-published">Default Value</label>
                    {formControls[control.controlTypeId](control, field)}
                </fieldset>}
            {control.controlTypeId === constants.controlTypeNameToId.date
                && <fieldset class={linkedControls && linkedControls.indexOf(control.label) !== -1 || isEvalPresent || control.attributes.defaultToToday ? 'disabled' : ''}>
                    <label class="editable-when-published">Default Value</label>
                    {formControls[control.controlTypeId](control, field)}
                </fieldset>}
            {control.controlTypeId === constants.controlTypeNameToId.file
                && <>
                    <fieldset>
                        <label>File type:</label>
                        <div class="select-wrapper">
                            <select onchange={e => toolboxModel.setFileType(control, e.target.value)} value={control.attributes.accept && control.attributes.accept[0]}>
                                <option value={null}>Any</option>
                                <option value="image/*">Image</option>
                                <option value="video/*">Video</option>
                                <option value="audio/*">Audio</option>
                            </select>
                        </div>
                    </fieldset>
                    <fieldset>
                        <label>Max files:</label>
                        <input type="number" min="1" max="1000" step="1" value={control.attributes.maxFiles} oninput={e => {
                            control.attributes.maxFiles = parseInt(e.target.value);
                        }} />
                    </fieldset>
                </>}
            {control.controlTypeId === constants.controlTypeNameToId.date
                && <>
                    <fieldset>
                        <input type="checkbox" className="left editable-when-published" onchange={e => {
                            if (e.target.checked) {
                                toolboxModel.setDefault(control, field, undefined);
                                control.attributes.defaultToToday = true;
                            } else {
                                delete control.attributes.defaultToToday;
                            }
                        }} checked={control.attributes.defaultToToday} />
                        <label className="editable-when-published">Default Date to Today</label>
                    </fieldset>
                    <fieldset class="calendar-date-picker">
                        <label class="editable-when-published">Minimum Date:</label>
                        <input class="editable-when-published" type="date" value={toolboxModel.formatDate(control.attributes.min)} onchange={e => {
                            if (e.target.value) {
                                const [year, month, day] = e.target.value.split('-').map(Number);
                                control.attributes.min = new Date(year, month - 1, day).getTime();
                            } else {
                                delete control.attributes.min;
                            }
                        }} />
                    </fieldset>
                    <fieldset class="calendar-date-picker">
                        <label class="editable-when-published">Maximum Date:</label>
                        <input class="editable-when-published" type="date" value={toolboxModel.formatDate(control.attributes.max)} onchange={e => {
                            if (e.target.value) {
                                const [year, month, day] = e.target.value.split('-').map(Number);
                                control.attributes.max = new Date(year, month - 1, day).getTime();
                            } else {
                                delete control.attributes.max;
                            }
                        }} />
                    </fieldset>
                </>}
            {control.controlTypeId === constants.controlTypeNameToId.request
                ? <>
                    <fieldset class="request-url">
                        <label class="editable-when-published">Request URL:</label>
                        <p class="request-url-tip">Provide a URL here, or select "Automated Field" below to dynamically generate a request URL.</p>
                        <input class="editable-when-published" type="text" value={control.attributes.url} oninput={e => {
                            control.attributes.url = e.target.value;
                            e.redraw = false;
                        }} />
                    </fieldset>
                    <fieldset class="request-url">
                        <label class="editable-when-published">Response Parser:</label>
                        <p class="request-url-tip">Provide an expression that, when passed a <code>response</code> argument, returns the desired value.</p>
                        <textarea class="editable-when-published" oninput={e => {
                            control.attributes.parse = e.target.value;
                        }}>{control.attributes.parse}</textarea>
                        {!toolboxModel.isValidJson(control.attributes.parse)
                            && <h6 class="error">Expressions must be valid JSON.</h6>}
                    </fieldset>
                </>
                : <>
                    <fieldset class="property-permissions">
                        <label class="property-permissions-label">Property-Level Permissions</label>
                        <div class={`select-wrapper${isEvalPresent ? ' disabled' : ''}`}>
                            <select class="editable-when-published left" value={field.restricted ? '2' : control.attributes.readOnly ? '3' : '1'} onchange={e => {
                                switch (e.target.value) {
                                case '1':
                                    delete control.attributes.readOnly;
                                    delete field.restricted;
                                    break;
                                case '2':
                                    delete control.attributes.readOnly;
                                    field.restricted = true;
                                    break;
                                case '3':
                                    control.attributes.readOnly = true;
                                    delete field.restricted;
                                    break;
                                }
                            }}>
                                <option value="1">Platform Default</option>
                                <option value="2">Editable by Owner/Admin only</option>
                                <option value="3">View Only for all Roles</option>
                            </select>
                            {tool.attributes.restrictions &&
                                <label>*you have role permissions applied that may override this setting*</label>}
                        </div>
                    </fieldset>

                    {!isEvalPresent &&
                        <fieldset class="margin24-top property-advanced-setting">
                            <input type="checkbox" class="left editable-when-published" checked={control.attributes.required} onchange={e => {
                                if (e.target.checked) {
                                    control.attributes.required = true;
                                } else {
                                    delete control.attributes.required;
                                }
                            }} />
                            <label class="editable-when-published">Required (Prompt when null)</label>
                        </fieldset>}
                    {featureType && linkDescription && showLinkedControls
                        ? <fieldset class={`property-advanced-setting ${!!toolboxModel.isMetaProject() && linkedControls && linkedControls.indexOf(control.label) !== -1 ? 'disabled' : ''}`}>
                            <label class="editable-when-published">
                                <input
                                    disabled={!!toolboxModel.isMetaProject() && linkedControls && linkedControls.indexOf(control.label) !== -1}
                                    type="checkbox"
                                    class="editable-when-published"
                                    checked={linkedControls && linkedControls.indexOf(control.label) !== -1}
                                    onchange={e => toolboxModel.setLinkedControls(featureType, control, field, e.target.checked)} />
                                <span class="checkbox-label-text">Link this input to the {linkDescription}</span>
                            </label>
                        </fieldset>
                        : ''}
                    <fieldset class="property-advanced-setting">
                        <input class="editable-when-published" type="checkbox" onclick={e => {
                            if (e.target.checked) {
                                control.attributes.resetOnClone = true;
                            } else {
                                delete control.attributes.resetOnClone;
                            }
                        }} checked={control.attributes.resetOnClone} />
                        <label class="editable-when-published">Reset to default when asset is duplicated</label>
                    </fieldset>
                </>
            }
            {!!toolboxModel.hasExpressionOption[control.controlTypeId]
                && <ExpressionEditor control={control} field={field} featureType={featureType} />}

            <SharedControlTag control={control} tool={tool} field={field} />
            {toolboxModel.hasPDFOption && toolboxModel.isMetaProject() && <fieldset class="property-advanced-setting">
                <input class="editable-when-published" type="checkbox" onclick={e => {
                    if (e.target.checked) {
                        control.attributes.isPDFControl = true;
                    } else {
                        delete control.attributes.isPDFControl;
                    }
                }} checked={control.attributes.isPDFControl} />
                <label class="editable-when-published">Show control in Map View PDF</label>
            </fieldset>}
            {toolboxModel.hasPDFOption && !toolboxModel.isMetaProject() && <fieldset class="property-advanced-setting">
                <input class="editable-when-published" type="checkbox" onclick={e => {
                    if (e.target.checked) {
                        control.attributes.includeInContentRecordPDF = true;
                    } else {
                        delete control.attributes.includeInContentRecordPDF;
                    }
                }} checked={control.attributes.includeInContentRecordPDF} />
                <label class="editable-when-published">Include control in Content Record PDF</label>
            </fieldset>}
        </div>;
    }
};

export default Control;
