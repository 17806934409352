import api from 'util/api/api';
import {authManager} from '@procore/sdt-core-js';
class AppModel {
    constructor() {
        this.statusMessage = '';
    }

    showWelcomeMessage() {
        if (!authManager || !authManager.credentials || !authManager.credentials.token) {
            return;
        }
    
        // API V2
        api.getUser(authManager.userId).then(response => {
            if (!response || !response.data || !response.data.users) {
                return;
            }
            
            const name = response.data.users[0].givenName;
            this.updateStatus(`${name ? 'Hi, ' + name : 'Hi there'}! 👋`);
        });
    }

    updateStatus(message) {
        this.statusMessage = message;
        m.redraw();
    }

}
export default new AppModel();
