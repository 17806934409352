function getDaysInMonth(date) {

    if (!date) {
        return 31;
    }

    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

}

function constrain(date, min, max) {
    if (date < min) {
        date = new Date(min);
    } else if (date > max) {
        date = new Date(max);
    }
    return date;
}

class DateModel {

    constructor(date, min, max, onchange) {
        this.date = date;
        if (!min) {
            min = new Date();
            min.setFullYear(min.getFullYear() - 20);
        }
        this.min = min;
        if (!max) {
            max = new Date();
            max.setFullYear(max.getFullYear() + 20);
        }
        this.max = max;
        this.onchange = onchange;
    }

    updateDay(e, skipCallback) {
        if (e.target.value) {
            const selectedDay = Number(e.target.value);
            this.date = this.date || new Date();
            let daysInMonth = getDaysInMonth(this.date);
            while (selectedDay > daysInMonth) {
                this.date.setMonth(this.date.getMonth() + 1);
                daysInMonth = getDaysInMonth(this.date);
            }
            this.date.setDate(selectedDay);
            this.date = constrain(this.date, this.min, this.max);
        } else {
            this.date = undefined;
        }
        return skipCallback || this.onchange(this.date);
    }

    updateMonth(e, skipCallback) {
        if (e.target.value) {
            const selectedMonth = Number(e.target.value);
            this.date = this.date || new Date();
            const daysInMonth = new Date(this.date.getFullYear(), selectedMonth + 1, 0).getDate();
            let selectedDay = this.date.getDate();
            selectedDay = Math.min(selectedDay, daysInMonth);
            this.date.setDate(selectedDay);
            this.date.setMonth(selectedMonth);
            this.date = constrain(this.date, this.min, this.max);
        } else {
            this.date = undefined;
        }
        return skipCallback || this.onchange(this.date);
    }

    updateYear(e, skipCallback) {
        if (e.target.value) {
            this.date = this.date || new Date();
            this.date.setFullYear(e.target.value);
            this.date = constrain(this.date, this.min, this.max);
        } else {
            this.date = undefined;
        }
        return skipCallback || this.onchange(this.date);
    }

}

export default DateModel;
