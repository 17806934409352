import userModel from 'models/user-model';
import prune from 'util/data/prune';

function paginate(opts) {

    const {type, query, limit = 20, data, onResults, filter} = opts;

    let cancelled,
        hasMoreResults,
        gettingNextPage;

    paginate.stop();

    const sendRequest = query ? () => UE.socket.send({
        type: 'search',
        payload: {
            type,
            query,
            offset: data.length,
            userId: userModel.user.userId,
            platform: 'web',
            version: '1.6',
            searchType: 'search',
            limit,
            filter
        }

    }) : () => UE.rpc([['list' + type[0].toUpperCase() + type.slice(1) + 's', Object.assign({
        limit,
        order: opts.order ? opts.opts : 'createdDateTime desc',
        offset: data.length
    }, filter || {})]])
        .then(responses => responses[0]);

    const getNextPage = () => {

        gettingNextPage = true;

        sendRequest().then(results => {

            if (!cancelled) {

                if (prune[type]) {

                    results = results.map(prune[type]);

                }

                data.push(...results);

                m.redraw();

                if (onResults) {

                    onResults(results);

                }

                hasMoreResults = results.length === limit;

                gettingNextPage = false;

                requestAnimationFrame(checkScrollPosition);
                
            }

        });

    };

    getNextPage();

    const body = document.body;

    function checkScrollPosition() {

        if (gettingNextPage || !hasMoreResults) {

            return;

        }

        if (window.scrollY + window.innerHeight > body.scrollHeight - 150) {

            getNextPage();

        }

    }

    window.addEventListener('scroll', checkScrollPosition, {
        passive: true
    });

    paginate.stop = () => {
        cancelled = true;
        window.removeEventListener('scroll', checkScrollPosition);
    };

    return paginate.stop;

}

paginate.stop = new Function();

export default paginate;
