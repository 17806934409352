// Names
export const GET_USER = 'GetUser';
export const MODIFY_ACCOUNT_STATUS = 'ModifyAccountStatus';
export const MODIFY_ACCOUNT_BRAND_NAME = 'ModifyAccountBrandName';
export const LIST_ACCOUNT_TOOLBOXES = 'ListAccountToolboxes';
export const SEARCH_ACCOUNT_TOOLBOXES = 'SearchAccountToolboxes';

// Queries

const functionalQueries = {
    [SEARCH_ACCOUNT_TOOLBOXES]: (lastToolkitId, accountId, query) => `query ${SEARCH_ACCOUNT_TOOLBOXES} {
      toolkits(
        count: 100, 
        ${lastToolkitId ? `after: [eq, "$.id", "${lastToolkitId}"],` : ''}
        order: [createdAtDesc],
        filter: [
                ${accountId
        ? `and
                [eq, "$.account.id", "${accountId}"]
                [or
                        [ilike, "$.id", "%${query}%"]
                        [ilike, "$.name", "%${query}%"]
                ]`
        : `or
                [ilike, "$.id", "%${query}%"]
                [ilike, "$.name", "%${query}%"]`
                }
        ]) {
              id
              name
              createdAt
              updatedAt
              attributes
              categories {
                  tools {
                    id
                    name
                    assetForm {
                      controls
                    }
                  }
                  id
                  name

              }
          }
      }`, 
  
    [LIST_ACCOUNT_TOOLBOXES]: (lastToolkitId, accountId, order) => `query ${LIST_ACCOUNT_TOOLBOXES} {
      toolkits(
        count: 100,
        ${lastToolkitId ? `after: [eq, "$.id", "${lastToolkitId}"]` : ''},
        ${order ? `order: [${order}]` : 'order: [createdAtDesc]'},
        ${accountId ? `filter: [eq, "$.account.id", "${accountId}"]` : ''}
      ) {
            id
            name
            createdAt
            updatedAt
            attributes
            categories {
                tools {
                  id
                  name
                  assetForm {
                    controls
                  }
                }
                id
                name
            }
        }
    }`
};

const queries = {
    [GET_USER]: `query ${GET_USER}($userId: ID) {
        users(id: $userId) {
            givenName
            familyName
        }
    }`,
    [MODIFY_ACCOUNT_STATUS]: `mutation ${MODIFY_ACCOUNT_STATUS}($accountId: String!, $newStatus: String!) {
        modifyAccountStatus(accountId: $accountId, newStatus: $newStatus) {
            id
            status
        }
    }`,
    [MODIFY_ACCOUNT_BRAND_NAME]: `mutation ${MODIFY_ACCOUNT_BRAND_NAME}($accountId: String!, $newBrandName: String!) {
      modifyAccountBrand(accountId: $accountId, newBrandName: $newBrandName) {
          id
          brandName
      }
  }`
};

// Final structure, query w/variables & name
export const query = (name, variables, params = []) => {
    return {
        'query': queries[name] || functionalQueries[name](...params),
        'variables': variables ? {...variables} : null,
        'operationName': name
    };
};
