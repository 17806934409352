
import userModel from 'models/user-model';
import projectModel from 'models/project-model';
import modal from 'views/modal/modal';
import ErrorBox from 'views/error-box/add-remove-error-box';
import paginateComponent from 'util/data/paginate-component';

const AddUserToProjectDialog = {
    onremove: () => delete userModel.addUserError,

    oninit: ({state}) => {
        state.processing = {};
        state.userRoles = {};
        state.projectsMap = {};
        userModel.projects.forEach(project => {
            state.projectsMap[project.projectId] = project;
        });
    },
    userActionButton: (project, state) => {
        if (state.processing[project.projectId]) {
            return <div class="btn disabled add-remove-processing"><div className="add-remove-spinner spinner spinning"/></div>;
        } else if (state.projectsMap[project.projectId]) {
            return <div class="project-modal-button btn disabled"><span>Added</span></div>;
        }
        return <div class="btn" onclick={() => userModel.addExistingUserFromUserView(project, project.accountId, state)}> Add</div>;
    },
    view: ({state}, dataModel) => {
        return <div class="add-user-to-account">
            <h3>Add User To Project</h3>
            <fieldset>
                <label>Search</label>
                <input type="text" oninput={(e) => {
                    dataModel.search(e);
                }}/>
            </fieldset>
            <table class="add-user-table">
                <thead>
                    <td>Project Name</td>
                    <td>Project ID</td>
                    <td>Account Name</td>
                    <td></td>
                </thead>
                <tbody>
                    {dataModel.data.length === 0 &&
                        userModel.loading ? 
                        <div class ="empty-row">
                            <div class="no-users">
                                <div class="spinner spinning teal add-user"></div>
                            </div> 
                        </div>
                        :
                        dataModel.data.map((project) => {
                            return <tr key={project.userId || project.createdDateTime}>
                                <td>{project.projectId
                                    ? <a href={`#view=project&projectId=${project.projectId}`} 
                                        onclick={() => modal.close()}>{project.name}</a>
                                    : project.name}
                                </td>
                                <td>{project.projectId}</td>
                                <td>{project.accountId
                                    ? <a href={`#view=account&accountId=${project.accountId}`} 
                                        onclick={() => modal.close()}>{project.accountName}</a>
                                    : project.name}
                                </td>
                                <td>
                                    <div class="add-remove-modal-button-container">
                                        {AddUserToProjectDialog.userActionButton(project, state)}
                                    </div>
                                </td>
                            </tr>;
                        })}
                    {<div class="empty-row">
                    </div>}
                </tbody>
            </table>
            <div class="add-user-btns">
                <ErrorBox error={userModel.addUserError} class="add-remove-error"/>
                <div class={'btn'} onclick={() => modal.close()}>
                        Done
                </div>
            </div>
        </div>;
    }
};
export default paginateComponent(AddUserToProjectDialog, projectModel.paginateOpts);
