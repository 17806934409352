
const ErrorBox = {
    view: ({attrs: {error}}) => {
        return <>{error ? <div class="error-box">
            <label>{error.message}</label>
            <div class="error-details error">
                {error.details}
            </div>
        </div> : ''}
        </>;
    }
};

export default ErrorBox;
