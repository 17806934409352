const Toggle = {

    oninit({attrs: {active}, state}) {
        state.active = !!active;
    },

    view: ({attrs: {onchange}, state}) => 
        <div class={`toggle ${state.active ? 'active' : ''}`} onclick={e => {
            e.stopPropagation();
            state.active = !state.active;
            onchange(state.active);
        }}>
            <div class="icon-check"></div>
        </div>
};

export default Toggle;
