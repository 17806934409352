import modal from 'views/modal/modal';
import accountModel from 'models/account-model';
import ErrorBox from 'views/error-box/error-box';
import validate from 'util/data/validate';

const UpdateNameDialog = {
    oninit: ({state}) => {
        state.errors = {
            name: null
        };
        state.name = accountModel.account.name;
    }, 
    onremove: () => delete accountModel.modifyAccountError,
    view: ({state}) => {
        const {errors} = state;
        return <div class="create-account">
            <ErrorBox error={accountModel.modifyAccountError}/>
            <h3 class="account-modal-header">Change Account Name</h3>
            <div>
                <fieldset>
                    {errors.name && <label class="error">{errors.name}</label>}
                    <input value={state.name} type="text" required
                        oninput={(e) => {
                            state.name = e.target.value;
                            validate.errorCheck.name(state);
                        }} onblur={() => validate.errorCheck.name(state)}
                    />
                </fieldset>
                <div class="create-account-btn-wrap">
                    <div class={`btn ${accountModel.loading ? 'disabled' : ''}`} onclick={() => {
                        const {
                            name
                        } = errors;
                        if (name) {
                            return;
                        }
                        accountModel.updateAccount(state);
                    }}>
                        {accountModel.loading ? <div class="update-name spinner spinning"/> : 'Update'}
                    </div>

                    <div class="account-modal-buttons btn btn-secondary" onclick={() => modal.close()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>;
    }

};

export default UpdateNameDialog;
