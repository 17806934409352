import constants from 'util/data/constants';
import isMetric from 'util/numbers/is-metric';
import measure from 'util/numbers/measure';
import round from 'util/numbers/round';
import ColorPicker from 'views/color-picker';
import Upload from 'views/upload';
import uploadModel from 'models/upload-model';

const units = isMetric() ? 'meters' : 'yards';

const Range = {

    oninit({state, attrs: {properties, propKey, step = 0.1, isLength, render}}) {

        const doConvert = isLength && units === 'yards';

        if (doConvert) {
            state.transform = value => measure.yardsToMeters(value);
        } else {
            state.transform = value => value;
        }

        state.default = properties[propKey];

        if (doConvert) {
            state.default = round(measure.metersToYards(state.default), 1 / Number(step));
        }

        render && render(properties[propKey]);
    },

    oncreate({state, dom}) {
        state.range = dom.firstElementChild;
        state.input = state.range.nextElementSibling;
        state.range.value = state.default;
        state.input.value = state.default;
    },

    view: ({state, attrs: {render, propKey, properties, min = 0, max = 1, step = 0.1}}) => {
        return <div class="control-group range-control">
            <input class="range-input" type="range" min={min} max={max} step={step} oninput={e => {
                const value = Number(e.target.value);
                properties[propKey] = state.transform(value);
                state.input.value = value;
                render && render(properties[propKey]);
            }}/>
            <input class="range-text" type="number" step={step} oninput={e => {
                const value = Math.max(Math.min(Number(e.target.value), max), min);
                properties[propKey] = state.transform(value);
                state.range.value = value;
                render && render(properties[propKey]);
            }}/>
        </div>;
    }
};

const BorderPicker = {
    oninit({attrs: {render, propKey, properties}}) {
        render && render(properties[propKey]);
    },
    view: ({attrs: {render, propKey, properties}}) => 
        <div class="line-style-set border-opts control-group">
            <div class={'pop-btn' + (properties[propKey] ? '' : ' active')}
                onclick={() => {
                    properties[propKey] = false;
                    render && render(properties[propKey]);
                }}>
                <span class="line-style line-small">
                    <label class="line-style-label">Solid</label>
                </span>
            </div>
            <div class={'pop-btn' + (properties[propKey] ? ' active' : '')}
                onclick={() => {
                    properties[propKey] = true;
                    render && render(properties[propKey]);
                }}>
                <span class="line-style line-small dashed">
                    <label class="line-style-label">Dashed</label>
                </span>
            </div>
        </div>
};

const Textarea = {
    oninit({attrs: {render, propKey, properties}}) {
        render && render(properties[propKey]);
    },
    view: ({attrs: {render, propKey, properties}}) =>
        <div class="control-group">
            <textarea oninput={e => {
                properties[propKey] = e.target.value;
                render && render(properties[propKey]);
            }} value={properties[propKey]} />
        </div>
};

const Icon = {
    validate: state => e => {
        state.tooLarge = e.target.naturalWidth > 256;
        state.aspectRatio = e.target.naturalWidth / e.target.naturalHeight;
        m.redraw();
    },
    oninit({attrs: {render, propKey, properties}}) {
        render && render(properties[propKey]);
    },
    view: ({attrs: {render, propKey, properties}, state}) => {
        const mediaId = properties[propKey];
        function done([_mediaId]) {
            properties[propKey] = _mediaId;
            state.tooLarge = false;
            render && render(_mediaId);
            m.redraw();
        }
        return <div class="control-group icon-control icon-container">
            {properties[propKey] 
                ? <img class="thumbnail icon-thumbnail" src={constants.mediaServerUrl + mediaId} onload={Icon.validate(state)} />
                : <span class="icon-placeholder">icon required</span>}
            <Upload accept="image/*" done={done} />
            {state.tooLarge && <p class="icon-too-large"><div class="btn btn-small btn-error" onclick={() => uploadModel.resizeImage(mediaId, done, state.aspectRatio)}>Icon is too large. Click to fix.</div></p>}
        </div>;
    }
};

const DPad = {
    oninit({state, attrs: {render, properties, propKey}}) {
        let interval;
        state.move = (index, change) => {
            properties[propKey] = properties[propKey] ? Array.from(properties[propKey]) : [0, 0];
            function move() {
                properties[propKey][index] = round(properties[propKey][index] + change, 10);
                render && render(properties[propKey]);
                m.redraw();
            }
            interval = setInterval(move, 125);
            move();
        };
        state.stop = () => clearInterval(interval);
    },
    view: ({state: {move, stop}, attrs: {propKey, properties}}) => {
        const title = properties[propKey] ? properties[propKey].join(', ') : '0, 0';
        return <div class="control-group d-pad" title={title}>
            <i class="icon-up" onmousedown={() => move(1, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-down" onmousedown={() => move(1, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-left" onmousedown={() => move(0, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-right" onmousedown={() => move(0, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-expand" />
        </div>;
    }
};

// const TextAlignment = {
//     view: ({attrs: {render, propKey, properties}}) => {
//         return <div class="control-group checkbox">
//             <input type="checkbox" checked={properties[propKey] === 'map'} onchange={e => {
//                 properties[propKey] = e.target.checked ? 'map' : 'viewport';
//                 render && render(properties[propKey]);
//             }} />
//             <label>Align text with line</label>
//         </div>;
//     }
// };

const featureControls = {
    _lineColor: {
        label: 'Line Color',
        ui: (properties, render) => <ColorPicker key="_lineColor" propKey="_lineColor" render={render} properties={properties} />
    },
    _lineOpacity: {
        label: 'Line Opacity',
        ui: (properties, render) => <Range key="_lineOpacity" propKey="_lineOpacity" render={render} properties={properties} />
    },
    _lineDasharray: {
        label: 'Line Style',
        ui: (properties, render) => <BorderPicker key="_lineDasharray" propKey="_lineDasharray" render={render} properties={properties}/>
    },
    _lineWidthPx: {
        label: 'Line Width (pixels)',
        ui: (properties, render) => <Range key="_lineWidthPx" propKey="_lineWidthPx" render={render} properties={properties} min="1" max="100" step="1" />
    },
    _lineWidthMeters: {
        label: `Line Width (${units})`,
        ui: (properties, render) => <Range key="_lineWidthMeters" propKey="_lineWidthMeters" render={render} properties={properties} min="0.1" max="100" step="0.1" isLength={true} />
    },
    _iconImage: {
        label: 'Map Image',
        ui: (properties, render) => <Icon key="_iconImage" propKey="_iconImage" render={render} properties={properties} />
    },
    _iconSizePx: {
        label: 'Width (pixels)',
        ui: (properties, render) => <Range key="_iconSizePx" propKey="_iconSizePx" render={render} properties={properties} min="1" max="100" step="1" />
    },
    _iconSizeMeters: {
        label: `Width (${units})`,
        ui: (properties, render) => <Range key="_iconSizeMeters" propKey="_iconSizeMeters" render={render} properties={properties} min="0.1" max="100" step="0.1" isLength={true}/>
    },
    _fillColor: {
        label: 'Fill Color',
        ui: (properties, render) => <ColorPicker key="_fillColor" propKey="_fillColor" render={render} properties={properties} />
    },
    _fillOpacity: {
        label: 'Fill Opacity',
        ui: (properties, render) => <Range key="_fillOpacity" propKey="_fillOpacity" render={render} properties={properties} />
    },
    _textColor: {
        label: 'Text Color',
        ui: (properties, render) => <ColorPicker key="_textColor" propKey="_textColor" render={render} properties={properties} />
    },
    _textSizePx: {
        label: 'Text Size (pixels)',
        ui: (properties, render) => <Range key="_textSizePx" propKey="_textSizePx" render={render} properties={properties} min="1" max="200" step="1" />
    },
    _textSizeMeters: {
        label: `Text Size (${units})`,
        ui: (properties, render) => <Range key="_textSizeMeters" propKey="_textSizeMeters" render={render} properties={properties} min="0.1" max="200" step="0.1" isLength={true} />
    },
    _textHaloColor: {
        label: 'Text Stroke Color',
        ui: (properties, render) => <ColorPicker key="_textHaloColor" propKey="_textHaloColor" render={render} properties={properties} />
    },
    _textHaloWidthPx: {
        label: 'Text Stroke Width (pixels)',
        ui: (properties, render) => <Range key="_textHaloWidthPx" propKey="_textHaloWidthPx" render={render} properties={properties} min="0" max="20" step="1" />
    },
    _textHaloWidthMeters: {
        label: `Text Stroke Width (${units})`,
        ui: (properties, render) => <Range key="_textHaloWidthMeters" propKey="_textHaloWidthMeters" render={render} properties={properties} min="0" max="20" step="0.1" isLength={true} />
    },
    _rotation: {
        label: 'Rotation°',
        ui: (properties, render) => <Range key="_rotation" propKey="_rotation" render={render} properties={properties} min="-360" max="360" step="1" />
    },
    _textField: {
        label: 'Text',
        ui: (properties, render) => <Textarea key="_textField" propKey="_textField" render={render} properties={properties} />
    },
    _textOffset: {
        label: 'Text Position',
        ui: (properties, render) => <DPad key="_textOffset" propKey="_textOffset" render={render} properties={properties} />
    }
    // _textAlignment: {
    //     label: 'Text Alignment',
    //     ui: (properties, render) => <TextAlignment key="_textAlignment" propKey="_textAlignment" render={render} properties={properties} />
    // }
};

export default featureControls;
