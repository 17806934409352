const validate = {
    // eslint-disable-next-line no-control-regex
    email: /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    phoneNumber: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    errorCheck: {
        givenName: (state) => {
            const errors = state.errors;
            if (state.givenName && state.givenName.length) {
                delete errors.givenName;
            } else {
                errors.givenName = 'Invalid first name. the first name must contain least 1 character.';
            }
        },
        familyName: (state) => {
            const errors = state.errors;
            if (state.familyName && state.familyName.length) {
                delete errors.familyName;
            } else {
                errors.familyName = 'Invalid last name. The last name must contain least 1 character.';
            }
        },
        name: (state) => {
            const errors = state.errors;
            if (state.name && state.name.length) {
                delete errors.name;
            } else {
                errors.name = 'Invalid account name. the name must contain least 1 character.';
            }
        },
        emailAddress: (state, blur) => {
            const errors = state.errors;
            const isFirstAttempt = errors.emailAddress === null;
            if (state.emailAddress && state.emailAddress.length && state.emailAddress.match(validate.email)) {
                delete errors.emailAddress;
            } else if (!isFirstAttempt || blur) {
                errors.emailAddress = 'Invalid email';
            }
        },
        phoneNumber: (state, blur) => {
            const errors = state.errors;
            const isFirstAttempt = errors.phoneNumber === null;
            if (state.phoneNumber && state.phoneNumber.length && state.phoneNumber.match(validate.phoneNumber)) {
                delete errors.phoneNumber;
            } else if (!isFirstAttempt || blur) {
                errors.phoneNumber = 'Invalid phone number';
            }
        },
        accountName: (state) => {
            const errors = state.errors;
            if (state.accountName && state.accountName.length) {
                delete errors.accountName;
            } else {
                errors.accountName = 'Invalid account name. the accountName name must contain least 1 character.';
            }
        }
    }
};

export default validate;
