import StyleModel from 'models/style-model';
import isMetric from 'util/numbers/is-metric';

const StyleProperty = {
    oninit({state, attrs: {tool, styleKey}}) {
        state.model = new StyleModel(tool, styleKey);
    },
    view: ({state: {model}}) => {
        const {
            featureControl,
            featureType,
            value,
            featureProperties,
            isGeoSized,
            setProperty,
            prop,
            matchedControl,
            isIconSize,
            editableControls,
            setControl,
            values,
            isHidden,
            isStatic,
            unGeoSizedValue
        } = model.getState();
        return isHidden ? null : <fieldset>
            <label class="bold-label">{featureControl.label}</label>
            {isStatic ? null : <div class="style-option has-left-aligned-label linked-property clear">
                <label class="left-aligned-label">Linked Property</label>
                <div class="select-wrapper">
                    <select value={matchedControl} onchange={e => setControl(e.target.value)}>
                        <option value="">None</option>
                        {editableControls.map(control =>
                            <option value={control.label}>{control.label}</option>)}
                    </select>
                </div>
            </div>}
            {model.geoFeatureProp
                ? <div class="style-option has-left-aligned-label clear">
                    <label class="left-aligned-label">Units</label>
                    <div class="select-wrapper">
                        <select value={isGeoSized ? 'meters' : 'pixels'} onchange={e => {
                            setProperty(value, e.target.value === 'meters');
                        }}>
                            <option value="pixels">Pixels</option>
                            <option value="meters">{isMetric() ? 'Meters' : 'Yards'}</option>
                        </select>
                    </div>
                </div>
                : null}
            <div class="style-option-settings clear">
                {matchedControl
                    ? values && values.length
                        ? <div>
                            <div class="stop-row clear">
                                <label class="stop-label">Blank</label>
                                <div class="stop-setting">{featureControl.ui({
                                    [prop]: unGeoSizedValue[unGeoSizedValue.length - 1]
                                }, newValue => {
                                    unGeoSizedValue[unGeoSizedValue.length - 1] = isIconSize && !isGeoSized ? ['/', newValue, ['get', '_sourceWidthPx']] : newValue;
                                    setProperty(value, isGeoSized);
                                })}</div>
                            </div>
                            {values.map(enumValue => {
                                let propIndex = unGeoSizedValue.indexOf(enumValue);
                                if (propIndex === -1) {
                                    propIndex = unGeoSizedValue.length - 1;
                                } else {
                                    propIndex += 1;
                                }
                                return <div class="stop-row clear">
                                    <label class="stop-label">{enumValue}</label>
                                    <div class="stop-setting">{featureControl.ui({
                                        [prop]: model.getPropValue(unGeoSizedValue[propIndex], isIconSize)
                                    }, newValue => {
                                        unGeoSizedValue[propIndex] = isIconSize && !isGeoSized ? ['/', newValue, ['get', '_sourceWidthPx']] : newValue;
                                        setProperty(value, isGeoSized);
                                    })}</div>
                                </div>;
                            })}
                        </div>
                        : null
                    : featureControl.ui(featureProperties, newValue => {
                        setProperty(newValue, isGeoSized);
                    })
                }
            </div>
            {matchedControl
                ? null
                : <div>
                    <input type="checkbox"
                        checked={featureType.properties.hasOwnProperty(prop)}
                        onchange={e => {
                            if (e.target.checked) {
                                featureType.properties[prop] = featureProperties[prop];
                            } else {
                                delete featureType.properties[prop];
                            }
                            setProperty(featureProperties[prop], isGeoSized);
                        }} />
                    <label>Make {featureControl.label} editable</label>
                </div>}
        </fieldset>;
    }
};

export default StyleProperty;
