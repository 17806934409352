import * as localforage from 'localforage';
import deepCloneObject from 'util/data/deep-clone-object';
import QuickDraftsDisclaimer from 'views/drafts/quick-drafts-disclaimer';
import modal from 'views/modal';
import toolboxModel from './toolbox-model';

localforage.config({
    name: 'ue-toolbox-cache'
});

class DraftsModel {

    constructor() {
        this.toolboxes = {};
        this.loading = true;
        this.isSavingDraft = true;
        this.handleKeyDown = this._handleKeyDown.bind(this);
        this.isListening = false;
    }

    async retrieveDrafts() {
        await localforage.keys().then(toolboxIds => {
            toolboxIds.forEach(async toolboxId => await this.retrieveDraft(toolboxId));
            this.loading = false;
            m.redraw();
        });
    }

    retrieveDraft(toolboxId) {
        return localforage.getItem(toolboxId).then((toolbox) => {
            this.toolboxes[toolbox.toolboxId] = deepCloneObject(toolbox);
            m.redraw();
            return toolbox;
        });
    }

    saveDraft(toolboxId, toolbox) {
        if (!toolboxId || !toolbox) {
            toolboxId = toolboxModel.toolbox.toolboxId;
            toolbox = toolboxModel.toolbox;
        }
        const lastSaved = toolbox.savedDateTime;
        toolbox.savedDateTime = Date.now();
        if (!lastSaved) {
            modal(QuickDraftsDisclaimer, {
                titleText: 'Heads up!', onClose: () => draftsModel.showSuccessMessage('stored')
            });
        }
        m.redraw();
        return localforage.setItem(toolboxId, toolbox).then(() => {
            if (lastSaved) {
                this.showSuccessMessage('updated');
            }
        });
    }

    showSuccessMessage(updatedOrStored = 'stored') {
        draftsModel.showSavedSuccessMessage = updatedOrStored;
        m.redraw();
        setTimeout(() => {
            draftsModel.showSavedSuccessMessage = false;
            m.redraw();
        }, 1500);
    }

    deleteDraft(toolboxId) {
        return localforage.removeItem(toolboxId).then(() => {
            delete this.toolboxes[toolboxId];
            m.redraw();
        });
    }

    _handleKeyDown(e) {
        if (e) {
            if (e.key === 's' && e.metaKey) {
                e.preventDefault();
                e.stopPropagation();
                this.saveDraft();
            }
        }
    }

    startListenForKeyDown() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    stopListenForKeyDown() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

}

const draftsModel = new DraftsModel();

export default draftsModel;

