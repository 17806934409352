import constants from 'util/data/constants';
import {datadogRum} from '@datadog/browser-rum';

export const initDatadog = () => {
    if (!constants.isDeployed) {
        return;
    }

    const env = {
        development: 'dev',
        staging: 'staging',
        production: 'prod'
    }[constants.awsProfile];

    datadogRum.init({
        applicationId: 'a883bf60-1f1f-49f0-bee9-f3a2e8f274b1',
        clientToken: 'pub62e4f433fb198720fba480562ef91ac1',
        site: 'datadoghq.com',
        service: 'AdminDashboard',
        env,
        version: constants.release,
        sampleRate: 100,
        trackInteractions: true
    });
};

export const addRumUserContext = (user) => {
    if (constants.isDeployed) {
        datadogRum.addRumGlobalContext('usr', {
            id: user.userId,
            name: `${user.givenName} ${user.familyName}`
        });
    }
};

export const addRumAccountContexts = (account) => {
    if (constants.isDeployed) {
        datadogRum.addRumGlobalContext('acct', {
            id: account.accountId,
            name: account.name
        });
    }
};
