const MultiText = {
    view({attrs: {values = [''], onchange}}) {
        const canDelete = values.length > 1 || values[0];
        return <div class="multitext">
            {values.map((value, i) => <div class="text-control">
                <input type="text" value={value} class="editable-when-published" oninput={e => {
                    values[i] = e.target.value;
                    if (onchange) {
                        if (values.length === 1 && values[0] === '') {
                            values = [];
                        }
                        onchange(values);
                    }
                }}/>
                {canDelete && <i class="icon-close" onclick={() => {
                    values.splice(i, 1);
                    if (onchange) {
                        onchange(values);
                    }
                }} />}
            </div>)}
            <div class="btn btn-secondary btn-pill btn-smallest editable-when-published" onclick={() => {
                values.push('');
                if (onchange) {
                    onchange(values);
                }
            }}>
                <i class="icon-plus" />
            </div>
        </div>;
    }
};

export default MultiText;
