import React from 'react';
import {getAppVersion} from 'util/data/get-app-version';
import RowElement from 'views/react/Row';
import constants from 'util/data/constants';

const AccountRow = ({account}) => {
    const {accountId, name, status, usageMB, storageQuotaGB, seats, attributes, projectIds, createdDateTime, planName} = account;
    const accountRowCellValues = [
        <strong><a href={`#view=account&accountId=${accountId}`}>{name}</a>
            <button className="launch-button" onClick={() => window.open(`${constants.launchUrl}#projectId=${account.attributes.metaProjectId}`, '_blank')}>Go</button></strong>,
        accountId,
        <div className={`status-pill ${status}-pill`} >{status}</div>,
        `${Math.round((usageMB || 0) / 1000)} / ${storageQuotaGB || 0}`,
        seats,
        getAppVersion(attributes),
        projectIds && projectIds.length || 0,
        new Date(createdDateTime).toLocaleDateString(),
        `${planName === 'promapper'}`
    ];

    return (
        <RowElement cellValues={accountRowCellValues} />
    );
};

const AccountRowElement = (props) => React.createElement(AccountRow, props, null);

export default AccountRowElement;
