import userModel from 'models/user-model';
import UpdateUserNameDialog from 'views/user/update-user-name-dialog';
import modal from 'views/modal';
import Tabs from 'views/tabs';
import UserAccounts from 'views/user/user-accounts';
import UserProjects from 'views/user/user-projects';
import ResetUserPasswordDialog from 'views/user/reset-user-password-dialog';
import UpdateUserStatusDialog from 'views/user/update-user-status-dialog';
import AddUserToAccountDialog from 'views/user/add-user-to-account-dialog';
import AddUserToProjectDialog from 'views/user/add-user-to-project-dialog';
import router from 'uav-router';

const User = {
    oninit: () => {
        userModel.reset();
    },

    view: () => {
        const user = userModel.user;
        return <div class="user">
            <div class="page-title">
                User Details
            </div>
            <div class="header-buttons user-header">
                {router.params.tab === 'Accounts' || !router.params.tab ?
                    userModel.accounts.length === 0 ? 
                        <div class = "btn btn-small user-header-button disabled" disabled>Add User to Account</div> 
                        :
                        <div class="btn btn-small user-header-button" onclick={() => modal(AddUserToAccountDialog, {cssClass: 'add-user-modal'})}>Add User to Account</div>
                    : 
                    null  
                }
                {router.params.tab === 'Projects' ?
                    userModel.projects.length === 0 ? 
                        <div class = "btn btn-small user-header-button disabled" disabled>Add User to Project</div> 
                        :
                        <div class="btn btn-small user-header-button" onclick={() => modal(AddUserToProjectDialog, {cssClass: 'add-user-modal'})}>Add User to Project</div>
                    :
                    null
                }
                {!!user.emailAddress && <div class="btn btn-small" onclick={() => modal(ResetUserPasswordDialog, {user})}> Send Reset Password Email</div>}
            </div>
            <table>
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Status</td>
                    <td>Company</td>
                    <td>Created</td>
                    <td>Last Activity</td>
                </thead>
                <tbody>
                    <td>{user.givenName ? `${user.givenName} ${user.familyName}` : ''}<button class= "edit-button" onclick={() => modal(UpdateUserNameDialog)}><i class="icon-pencil"></i></button></td>
                    <td>{user.userId}</td>
                    <td>{user.status}<button class="edit-button" onclick={() => modal(UpdateUserStatusDialog)}><i class="icon-pencil"></i></button></td>
                    <td>{user.company || '-'}</td>
                    <td>{new Date(user.createdDateTime).toLocaleString()}</td>
                    <td>{user.lastConnectedDateTime ? new Date(user.lastConnectedDateTime).toLocaleString() : '-'}</td>
                </tbody>
            </table>
            <Tabs tabs={[{
                name: 'Accounts',
                view: UserAccounts,
                selected: true
            }, {
                name: 'Projects',
                view: UserProjects
            }]} />
        </div>;
    }
};

export default User;
