import debounce from 'util/events/debounce';
import paginate from 'util/data/paginate';

function search(type, data, filter) {

    function paginatedSearch(e) {

        data.length = 0;

        let query;

        if (e) {

            query = e.target.value;

        }

        paginate({
            type,
            data,
            query, 
            filter
        });

    }

    paginatedSearch();

    return debounce(paginatedSearch);

}

export default search;
