import api from 'util/api/api';
import router from 'uav-router';
import constants from 'util/data/constants';

class ToolboxPickerModel {

    constructor(opts = {}) {
        this.reset();
        this.noAccountFilter = opts.noAccountFilter || false;
    }

    reset() {
        this.toolboxes = [];
        this.isSearchingToolboxes = false;
        this.isFetchingToolboxes = true;
        this.isFetchingPage = true;
        this.lastFetchedId = undefined;
        this.fetchedAll = false;
        this.toolboxSearchTerm = '';
    }

    fetchToolboxes(startFresh) {
        if (startFresh) {
            this.toolboxes = [];
        }
        this.isFetchingToolboxes = true;
        this.fetchedAll = false;
        this.getNextPage('');
    }

    searchToolboxes() {
        this.isSearchingToolboxes = true;
        this.fetchToolboxes(true);
    }

    listToolboxes() {
        this.isSearchingToolboxes = false;
        this.toolboxSearchTerm = '';
        m.redraw();
        this.fetchToolboxes(true);  
    }

    getNextPage(lastFetchedId = this.lastFetchedId) {
        this.isFetchingPage = true;
        const accountId = this.noAccountFilter || router.params.accountId === constants.unearthAccountId ? undefined : router.params.accountId;
        const queryTerm = this.isSearchingToolboxes ? this.toolboxSearchTerm : undefined;
        const fetchRequest = this.isSearchingToolboxes ? api.searchAccountToolboxes : api.listAccountToolboxes;
        return fetchRequest(lastFetchedId, accountId, queryTerm).then(response => this.handleResults(response && response.data && response.data.toolkits || []));
    }

    getOrder(order) {
        const fetchRequest = api.listAccountToolboxes;
        const accountId = this.noAccountFilter || router.params.accountId === constants.unearthAccountId ? undefined : router.params.accountId;
        return fetchRequest(null, accountId, order)
            .then(response => this.handleResults(response && response.data && response.data.toolkits || []));
    }

    handleResults(results) {
        this.lastFetchedId = results && results.length ? results[results.length - 1].id : undefined;
        this.toolboxes.push(...results);
        this.isFetchingToolboxes = false;
        this.isFetchingPage = false;
        if (this.lastFetchedId && results.length === 100) {
            return m.redraw();
        }
        this.lastFetchedId = undefined;
        this.fetchedAll = true;
        m.redraw();
    }

    fetchTool(toolboxId, toolId) {
        return UE.rpc([['getToolbox', {toolboxId}]]).then(([_toolbox]) => {
            let fetchedTool;
            Object.values(_toolbox.group.groups).forEach(toolGroup => {
                const tool = toolGroup.tools.find(_tool => _tool.toolId === toolId);
                if (tool) {
                    fetchedTool = tool;
                    return;
                }
            });
            return fetchedTool;
        });
    }

    fetchToolGroup(toolboxId, toolGroupId) {
        return UE.rpc([['getToolbox', {toolboxId}]]).then(([_toolbox]) => {
            const toolGroup = _toolbox.group.groups.find(group => group.toolGroupId === toolGroupId);
            return toolGroup;
        });
    }

    deleteToolbox(toolbox) {
        const toolboxId = toolbox.toolboxId || toolbox.id;
        UE.rpc([['deleteToolbox', {
            toolboxId
        }]]).then(() => {
            const index = this.toolboxes.findIndex((searchToolbox) => searchToolbox.toolboxId === toolboxId || searchToolbox.id === toolboxId);
            if (index !== -1) {
                this.toolboxes.splice(index, 1);
            }
            m.redraw();
        });
    }

    sortToolbox(column, order) { 
        this.toolboxes = []; 
        if (column === 'name' && order === 1) {
            this.getOrder('nameAsc');
        } else if (column === 'name' && order === 0) {
            this.getOrder('nameDesc');
        } else if (column === 'name' && order === 2) {
            this.getOrder('createdAtDesc');
        } else if (column === 'createdDateTime' && order === 1) {
            this.getOrder('createdAtAsc');   
        } else if (column === 'createdDateTime' && order === 2) {
            this.getOrder('createdAtDesc');
        } else if (column === 'createdDateTime' && order === 0) {
            this.getOrder('createdAtDesc');   
        } else if (column === 'updatedDateTime' && order === 1) {
            this.getOrder('updatedAtAsc');   
        } else if (column === 'updateedDateTime' && order === 0) {
            this.getOrder('updatedAtDesc');
        } else if (column === 'updatedDateTime' && order === 2) {
            this.getOrder('createdAtDesc');  
        }        
        m.redraw();
    }
}

export default ToolboxPickerModel;
