import Tabs from 'views/tabs';
import router from 'uav-router';
import Accounts from 'views/accounts';
import Projects from 'views/projects';
import Users from 'views/users';

const Home = {
    view: () => 
        <div class="home">
            <Tabs tabs={[{
                name: 'Accounts',
                view: Accounts,
                selected: router.params.view === 'accounts'
            }, {
                name: 'Projects',
                view: Projects,
                selected: router.params.view === 'projects'
            }, {
                name: 'Users',
                view: Users,
                selected: router.params.view === 'users'
            }]} />
        </div>
};

export default Home;
