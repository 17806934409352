import {isNameControlForMetaAsset} from 'views/toolbox/control';
class ExpressionEditorModel {

    constructor() {
        // see unearth/src/util/evaluate.js for mappings.
        this.argumentOptions = [
            'asset',
            'value',
            'control',
            'label',
            'account',
            'assetProperties',
            'project',
            'tools',
            'tool',
            'accountAuthorRole',
            'projectAuthorRole',
            'accountUserRole',
            'projectUserRole',
            'user',
            'author',
            'site',
            'feature',
            'featureProperties'
        ];
    }

    onSelectArg(options, control) {
        control.attributes.eval = control.attributes.eval || {};
        if (options.length) {
            control.attributes.eval.args = options;
        } else {
            delete control.attributes.eval.args;
        }
    }

    _removeControl(controlList, control) {
        if (controlList) {
            const index = controlList.findIndex((str) => str === control.label);
            if (index !== -1) {
                controlList.splice(index, 1);
            }
        }
    }

    toggleExpressionEval(control, field, featureType) {
        const attributes = control.attributes;
        if (attributes.eval) {
            delete attributes.eval;
        } else {
            attributes.eval = {};
            attributes.readOnly = true;
            delete field.restricted;
            delete attributes.default;
            delete attributes.required;
            // This is the one case we've made an exception for linking to AFFs, per UE-6976
            if (!isNameControlForMetaAsset({control, featureType})) {
                const linkedControls = featureType.attributes.linkedControls;
                this._removeControl(linkedControls, control);
            }
            const styledControls = featureType.attributes.styledControls;
            this._removeControl(styledControls, control);
        }
    }

    update(attribute, control, value) {
        control.attributes.eval = control.attributes.eval || {};
        control.attributes.eval[attribute] = value;
    }

    toggleDescription(control) {
        if (control.attributes.eval) {
            if (control.attributes.eval.description !== undefined) {
                delete control.attributes.eval.description;
                return;
            }
            control.attributes.eval.description = '';
        }
    }
}

export default new ExpressionEditorModel();
