const Multiselect = {

    oninit: ({attrs: {defaultOptions = []}, state}) => {
        state.selected = {};
        defaultOptions.forEach(option => {
            state.selected[option] = true;
        });
    },

    view: ({attrs: {options, onchange}, state}) => {
        let allIcon = 'off';
        const checkedCount = Object.keys(state.selected).length;
        if (checkedCount) {
            allIcon = checkedCount === options.length ? 'on' : 'mixed';
        }
        return <div class="multiselect">
            <i class={`icon-checkbox-${allIcon} multiselect-all`} onclick={() => {
                if (allIcon === 'on') {
                    state.selected = {};
                } else {
                    options.forEach(option => {
                        state.selected[option] = true;
                    });
                }
                if (onchange) {
                    onchange(Object.keys(state.selected));
                }
            }} />
            {options.map(option => {
                const onOrOff = state.selected[option] ? 'on' : 'off';
                return <div key={option} class={`multiselect-opt ${onOrOff}`} onclick={() => {
                    if (state.selected[option]) {
                        delete state.selected[option];
                    } else {
                        state.selected[option] = true;
                    }
                    if (onchange) {
                        onchange(Object.keys(state.selected));
                    }
                }}>
                    <i class={`icon-checkbox-${onOrOff}`} />
                    {option}
                </div>;
            })}
        </div>;
    }
};

export default Multiselect;
