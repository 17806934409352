import projectModel from 'models/project-model';
import userModel from 'models/user-model';
import paginateComponent from 'util/data/paginate-component';
import modal from 'views/modal/modal';
import ErrorBox from 'views/error-box/add-remove-error-box';

const AddExistingUserToProjectDialog = {

    oninit: ({state}) => {
        state.processing = {};
        state.userMap = {};
        projectModel.project.users.forEach((user) => {
            state.userMap[user.userId] = user;
        });
    },

    userActionButton: (user, state) => {
        if (state.processing[user.userId]) {
            return <div class="btn disabled add-remove-processing"><div class="add-remove-spinner spinner spinning"/></div>;
        } else if (state.userMap[user.userId]) {
            return <div class="project-modal-button btn disabled"><span>Added</span></div>;
        }
        return <div class="btn" onclick={() => projectModel.addExistingUser(user, state)}> Add</div>;
    },

    view: ({state}, dataModel) => {
        return <div class="add-user-to-account">
            <label>Add User To Project</label>
            <div>
                <fieldset>
                    <label>Search</label>
                    <input type="text" oninput={(e) => {
                        dataModel.search(e);
                    }}/>
                </fieldset>
            </div>
            <table class="add-user-table">
                <thead>
                    <td>Name</td>
                    <td>ID</td>
                    <td>Email</td>
                    <td/>
                </thead>
                <tbody>
                    {dataModel.data.length === 0 &&
                        dataModel.loading ? 
                        <div class ="empty-row">
                            <div class="spinner spinning teal"></div> 
                        </div>
                        :
                        dataModel.data.map((user) => <tr>
                                
                            <td>{user.givenName} {user.familyName}</td>
                            <td>{user.userId}</td>
                            <td>{user.emailAddress}</td>
                            <td>
                                <div class="add-remove-modal-button-container">
                                    {AddExistingUserToProjectDialog.userActionButton(user, state)}
                                </div>
                            </td>
                        </tr>
                        )}
                    {dataModel.loading && <div class="empty-row">
                    </div>}
                </tbody>
            </table>
            <div class="add-user-btns">
                <ErrorBox error={projectModel.addExistingUserError} class="add-remove-error"/>
                <div class={'btn'} onclick={() => modal.close()}>
                        Done
                </div>
            </div>
        </div>;
    }
};

export default paginateComponent(AddExistingUserToProjectDialog, userModel.paginateOpts);
