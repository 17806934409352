import projectsModel from 'models/projects-model';
import ProjectRowElement from 'views/react/ProjectRow';
import MreactComponent from 'util/dom/mreact-component';
import ProjectTableHeaderElement from 'views/react/ProjectTableHeader';

const Projects = {
    oninit: () => projectsModel.fetch(),
    view: () => 
        <div class="projects">
            <div class="page-title">
                <input type="text" oninput={projectsModel.search} placeholder="search" />
            </div>
            <table>
                <thead>
                    <MreactComponent element={ProjectTableHeaderElement({})} tagName="tr"/> 
                </thead>
                <tbody>
                    {projectsModel.loading ?
                        <div class ="empty-row">
                            <div class="no-users">
                                <div class="spinner spinning teal account-users"></div>
                            </div> 
                        </div>
                        :
                        projectsModel.projects.map(project => {
                            project.accountAttributes = project.accountAttributes || {};
                            if (project.hidden) {
                                return null;
                            }
                            return <MreactComponent
                                element={ProjectRowElement({project})}
                                tagName="tr"
                                key={project.projectId}
                            />;                   
                        })}
                </tbody>
            </table>
        </div>
};

export default Projects;
