import {m} from 'mithril';
import {createRoot} from 'react-dom/client';
// Here is a handy component that can be used for rendering react inside mithril :-) 
// See the call in app.js for an example of its usage

const MreactComponent = {
    oncreate: ({dom, attrs: {element}}) => {
        const root = createRoot(dom); // You can create as many "roots" as you want, and what's nice is that mithril gives us access to the dom it creates
        root.render(element); // So here, we can just simply render our vnode.
    },

    view: ({attrs: {tagName}}) => {
        return m(tagName || 'div');
    }
};

export default MreactComponent;
