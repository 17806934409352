import toolboxModel from 'models/toolbox-model';
import Tool from 'views/toolbox/tool';
import toolFromFile from 'util/data/tool-from-file';
import collapsibleModel from 'models/collapsible-model';
import Icon from 'views/toolbox/icon';
const noToolsInToolGroup = (toolGroup) => {
    const visibleTools =  toolGroup.tools.filter(t => !t.attributes || !t.attributes.hidden);
    return !toolGroup.tools || !visibleTools.length;
};

const ToolGroup = {
    oninit: ({attrs: {toolGroup}}) => {
        collapsibleModel.initToolGroup(toolGroup.toolGroupId, toolGroup.tools);
        if (!toolGroup.attributes.icon) {
            toolGroup.attributes.icon = {};
        }
    },

    view: ({attrs: {toolGroup}}) =>  {
        const disabledClass = toolboxModel.isProtectedToolgroup[toolGroup.name] ? 'locked-toolgroup disabled' : '';
        return <div id={toolGroup.toolGroupId} class={`category-wrap collapsible ${disabledClass} ${collapsibleModel.isCollapsed(toolGroup.toolGroupId) ? 'collapsed' : ''} ${toolGroup.updatedDateTime && !toolboxModel.isUnlocked ? 'published-group' : ''} clear`}>
            <div class="collapsible-name category-header">
                <label>Category</label>
                {noToolsInToolGroup(toolGroup) && <div class="tip-text">Hidden from toolbox because there are no visible tools in this category</div>}
                <input type="text" class={toolGroup.name === 'Files' ? '' : 'editable-when-published'} oninput={e => {
                    toolGroup.name = e.target.value;
                }} value={toolGroup.name} />
                <i class="icon-up collapsible-icon right" onclick={() => {
                    collapsibleModel.handleClick(toolGroup.toolGroupId);
                }}/>
                <i class="icon-trash right" onclick={() => toolboxModel.removeToolGroup(toolGroup.toolGroupId)} />
                <div className={'category-icon'}><Icon icon={toolGroup.attributes.icon} isToolgroup={true} mediaId={toolGroup.attributes.icon.mediaId}/></div>
            </div>
            {collapsibleModel.isCollapsed(toolGroup.toolGroupId) ? <></>
                : <div class="collapsible-items category-content">
                    <div class="category-settings">
                        <label class="category-settings-title">Advanced Category Options</label>
                        {toolboxModel.toolGroupFeatures.map(feature => <div class="style-shortcuts-setting">
                            <input type="checkbox" class="editable-when-published"
                                checked={toolGroup.attributes.featureFlags && toolGroup.attributes.featureFlags[feature.key]}
                                onclick={() => toolboxModel.toggleToolGroupFeatureFlag(feature.key, toolGroup)}
                                disabled={!!toolboxModel.featureFlagDisabledMessage(feature.label)} />
                            <label class="editable-when-published">Enable style shortcuts</label>
                        </div>)}
                    </div>
                    {toolGroup.tools.map((tool, i) => tool.name === 'Comment' ? '' : <Tool toolGroup={toolGroup} index={i} tool={tool} /> )}
                    <div class="add-tool-section">
                        <div class="add-tool-section-title"><label>Add New Tool</label></div>
                        <div class="add-button">
                            <div class="add-button-opt" onclick={() => toolboxModel.createTool(toolGroup)}><i class="icon-plus"/>Create New</div>
                        </div>
                        <div class="add-button">
                            <div class="add-button-opt" onclick={() => toolboxModel.clone('tool', toolGroup.tools)}><i class="icon-duplicate"/>Copy Existing</div>
                        </div>
                        <div class="add-button">
                            <div class="add-button-opt import-csv-tool-button" onclick={() => toolFromFile(toolGroup)}><i class="icon-upload"/>Import CSV or JSON</div>
                        </div>
                    </div>
                </div> }
        </div>;
    }
};


export default ToolGroup;
