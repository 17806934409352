import expressionEditorModel from 'models/expression-editor-model';
import Multiselect from 'views/multiselect/multiselect';
import toolboxModel from 'models/toolbox-model';

const ExpressionEditor = {
    view: ({attrs: {control, field, featureType}}) => {
        const evaluation = control.attributes.eval || {};
        const isValid = toolboxModel.isValidJson(evaluation.expression);
        const isCalculatedField = control.attributes.eval;
        const hasDescription = evaluation.description !== undefined;
        return (
            <div>
                <fieldset class={`expression-editor${isValid ? '' : ' invalid'}`}>
                    <input class="editable-when-published" type="checkbox" onclick={() => expressionEditorModel.toggleExpressionEval(control, field, featureType)} checked={isCalculatedField}/>
                    <label class="editable-when-published">Automated Field </label>
                    {isCalculatedField ? <div>
                        <label class="editable-when-published expression-editor-sublabel">Arguments:</label>
                        <Multiselect defaultOptions={evaluation.args} onchange={options => expressionEditorModel.onSelectArg(options, control)} options={expressionEditorModel.argumentOptions}/>
                        <input class="editable-when-published" type="checkbox" value={hasDescription} onclick={() => expressionEditorModel.toggleDescription(control)} checked={hasDescription}/>
                        <label class="editable-when-published">Description:</label>
                        {hasDescription && <textarea class="editable-when-published" oninput={e => expressionEditorModel.update('description', control, e.target.value)}>{evaluation.description}</textarea>}
                        <label class="editable-when-published expression-editor-sublabel">Expression:</label>
                        <textarea class="editable-when-published" oninput={e => expressionEditorModel.update('expression', control, e.target.value)}>{evaluation.expression}</textarea>
                        {!isValid && <h6 class="error">Automated fields must be valid JSON.</h6>}
                    </div> : null}
                </fieldset>
            </div>
        );
    }
};

export default ExpressionEditor;
