import search from 'util/data/search';
import router from 'uav-router';

class ProjectsModel {
    
    fetch() {

        router.url.merge({view: 'projects'});

        this.projects = [];

        this.search = search('project', this.projects, {isVisible: true});

    }

    sortProjects(column, order) {
        if (column === 'name' && order === 1) { 
            this.search = search('project', this.projects, {order: 'name asc'});    
        } else if (column === 'name' && order === 0) { 
            this.search = search('project', this.projects, {order: 'name desc'});   
        } else if (column === 'name' && order === 2) {
            this.search = search('project', this.projects, {order: 'createdDateTime desc'});          
        } else if (column === 'createdDateTime' && order === 1) {
            this.search = search('project', this.projects, {order: 'createdDateTime asc'});
        } else if (column === 'createdDateTime' && order === 2) {
            this.search = search('project', this.projects, {order: 'createdDateTime desc'}); 
        } else if (column === 'createdDateTime' && order === 0) {
            this.search = search('project', this.projects, {order: 'createdDateTime desc'});   
        }

        m.redraw();
    }

}

export default new ProjectsModel();
