import accountModel from 'models/account-model';
import MreactComponent from 'util/dom/mreact-component';
import ToolboxProjectTableHeaderElement from 'views/react/ToolboxProjectTableHeader';
import constants from 'util/data/constants';

const AccountProjects = {
    oninit: () => accountModel.fetchProjects(),
    view: () => 
        <table>
            <thead>
                <MreactComponent element={ToolboxProjectTableHeaderElement({})} tagName="tr"/>
            </thead>
            <tbody>
                {accountModel.projects.length === 0 &&
                    accountModel.loading ?
                    <div class ="empty-row">
                        <div class="no-users">
                            <div class="spinner spinning teal account-users"></div>
                        </div> 
                    </div>
                    :
                    accountModel.projects.map(project =>
                        <tr key={project.projectId}>
                            <td><a href={`#view=project&projectId=${project.projectId}`}>{project.name || 'N/A'}</a>
                                <button className="launch-button" onclick={() => window.open(accountModel.account.planName === 'promapper' ? `${constants.promapperLaunchUrl}#unearthProjectId=${project.projectId}` : `${constants.launchUrl}#projectId=${project.projectId}`, '_blank')}>Go</button></td>
                            <td>{project.projectId}</td>
                            <td>{project.users.length}</td>
                            <td>{new Date(project.createdDateTime).toLocaleDateString()}</td>
                        </tr>
                    )}
            </tbody>
        </table>
};

export default AccountProjects;
