const AllOnAllOff = {
    view: ({attrs: {
        labelOn,
        labelOff,
        onAllOn,
        onAllOff,
        allOn,
        allOff
    },
    children
    }) => <div className="bulk-selection-links">
        <span class={`select-all ${!allOn ? '' : 'disabled'}`}
            onclick={() => {
                if (onAllOn) {
                    onAllOn();
                }
            }}>{labelOn || 'Select All'}</span>&nbsp;|&nbsp;
        <span class={`select-all ${!allOff ? '' : 'disabled'}`}
            onclick={() => {
                if (onAllOff) {
                    onAllOff();
                }
            }}>{labelOff || 'Deselect All'}</span>
        {children}
    </div>
};

export default AllOnAllOff;
