import collapsibleModel from 'models/collapsible-model';

class AnchorMenuModel {

    constructor() {
        this.element = undefined;
    }
    

    scrollToTool(e, toolId, toolGroupId) {
        e.preventDefault();
        collapsibleModel.expandItem(toolGroupId, false); // Make sure category is expanded first
        const element = document.getElementById(toolId);
        if (!element) {
            return this.scrollToToolGroup(e, toolGroupId);
        }
        element.scrollIntoView({behavior: 'smooth', block: 'start'});
        m.redraw();
    }

    scrollToToolGroup(e, toolGroupId) {
        e.preventDefault();
        const element = document.getElementById(toolGroupId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
            m.redraw();
        } else {
            console.error('couldnt find element for anchor menu scrolling');
        }
    }

}


export default new AnchorMenuModel();

